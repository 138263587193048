import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import authFetch from '../../axios';

import {
  RootState,
  ModelType,
  fetchAPIData,
  fetchAPIVrData,
  Link,
} from '../../store';

import { interactiveURL } from '../../App';

// Create models slice, with models data from API
export const modelsSlice = createSlice({
  name: 'models',
  initialState: {
    list: [] as ModelType[],
    active: null as number | null,
    needsSave: false,
  },
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
    updateModelsVr: (state, action) => {
      const payload = action.payload as {
        view: number,
        links: { [viewId:number]: Link },
        defaultView: {
          id: number, name: string, layout: string, light: string, size: string,
        },
      };
      const { view, links, defaultView } = payload;
      const currentVR = state.list.find((m) => m.id === state.active)?.vr;
      if (currentVR) {
        if (links) {
          const { cubemaps, dupCubemapsIds } = currentVR;
          const updated = cubemaps.find((c) => c.id === view)!;
          // Update current view links
          updated.links = links;

          if (dupCubemapsIds.includes(updated.id)) {
            console.error('model specification is wrong');
            state.needsSave = true;
            return;
          }

          // Update links in matching views too
          cubemaps.filter(
            // Other cubemaps
            (c) => c.id !== updated.id &&
              // with same name
              c.name === updated.name &&
              // and not duplicated
              !dupCubemapsIds.includes(c.id)
          ).forEach((c) => {
            c.links = {
              ...Object.fromEntries(
                Object.entries(links).map(([id, link]) => {
                  // Current destination cubemap for this link
                  const origDest = cubemaps.find((d) => d.id === Number(id))!;
                  // Updated destination, this is the cubemap with same name as
                  // the original destination but for this cubemap layout/light/size
                  const newDest = cubemaps.find((d) => {
                    return d.name === origDest.name &&
                      d.layout === c.layout &&
                      d.light === c.light &&
                      d.size === c.size;
                  })!;
                  return [newDest.id, link];
                })
              )
            };
          });
        }
        if (defaultView) {
          currentVR.default_view = defaultView;
        }
      }
      state.needsSave = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAPIVrData.fulfilled, (state, action) => {
      state.list = [action.payload];
      state.active = action.payload.id;
    });
    // We don't use addMatcher with hasMapResponse here
    // to prevent overriding changes in models after saving
    // map. Map saves don't modify models, only VR saves do.
    builder.addCase(fetchAPIData.fulfilled, (state, action) => {
      state.list = action.payload.models;
    });
    builder.addCase(saveModelsVR.fulfilled, (state, action) => {
      state.list = action.payload;
      state.needsSave = false;
    });
  },
});
export const { setActive, updateModelsVr } = modelsSlice.actions;

export const modelsSelector = createSelector(
  (state: RootState) => state.models.list,
  (list: ModelType[]) =>
    [...list].sort((a, b) => {
      // Sort by name, but models with VR first
      if (a.vr.id && b.vr.id) return a.name.localeCompare(b.name);
      if (a.vr.id) return -1;
      if (b.vr.id) return 1;
      return a.name.localeCompare(b.name);
    }),
);

export const modelsWithVRSelector = createSelector(
  (state: RootState) => state.models.list,
  (list: ModelType[]) => list.filter((m) => m.vr.id),
);

export const modelsWithoutVRSelector = createSelector(
  (state: RootState) => state.models.list,
  (list: ModelType[]) => list.filter((m) => !m.vr.id),
);

export const activeModelSelector = createSelector(
  (state: RootState) => state.models,
  (models) => models.list.find((m) => m.id === models.active),
);

export const vrNeedsSaveSelector = (state: RootState) => state.models.needsSave;

// Save all VRs to platform via API call
export const saveModelsVR = createAsyncThunk(
  'models/saveModelsVR',
  async (_arg: void, { dispatch, getState }): Promise<ModelType[]> => {
  // Obtain vrsList and orderID from store
  const {
    undoable: {
      present: {
        api: { orderID },
      },
    },
    models: { list: modelsList },
  } = getState() as RootState;
  const vrsList = modelsList.map((m) => m.vr).filter((vr) => Object.keys(vr).length > 0);
  const url = `/api/orders/${orderID}/vrs`;
  // Send vrs to platform
  const response = await authFetch.put(url, { vrs: vrsList });
  return response.data;
});
export const vrUrlSelector = (state: RootState) => `${interactiveURL}/${state.map.order_id}/${state.models.active}`;
