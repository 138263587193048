import "./global.js";

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import {
  createBrowserRouter,
  RouterProvider,
  LoaderFunctionArgs,
} from 'react-router-dom';

// create shadow root, must be done before importing any stylesheets
import './createShadowRoot';
import './configureFontawesome';

import '@fortawesome/fontawesome-svg-core/styles.css';

import reportWebVitals from './reportWebVitals';

import store, {
  fetchOrderInfo,
  fetchAPIData,
  fetchAPIVrData,
  setActiveModel,
  setIsEmbedded,
} from './store';

import AppLoader from './AppLoader';

const WidgetElement = document.getElementById('visrez-interactive');
if (!WidgetElement) {
  throw new Error('Missing #visrez-interactive div element');
}

// Determine if we are embedded on a client page
const isEmbedded = !(
  window.location.hostname === 'localhost'
    || window.location.hostname.endsWith('visrez.test')
    || window.location.hostname.endsWith('interactive.visrez.com')
    || window.location.hostname.endsWith('interactive.testing.visrez.com')
);
const dataset = WidgetElement.dataset;

async function loadMapAPIData({ params }: LoaderFunctionArgs) {
  store.dispatch(setIsEmbedded(isEmbedded));
  const orderId = isEmbedded ? dataset.client : params.orderId;
  if (!orderId) {
    if (isEmbedded) {
      throw new Error('Missing #visrez-interactive data-client attribute');
    } else {
      throw new Error('Bad URL');
    }
  }
  const { payload: { splashImage }} = await store.dispatch(fetchOrderInfo({ orderId }));
  const mapId = isEmbedded ? dataset.map : params.mapId;
  store.dispatch(fetchAPIData({ orderId, mapId }));
  const layerId = isEmbedded ? dataset.layer : params.layerId;
  const spaceId = isEmbedded ? dataset.space : params.spaceId;
  const openSpace = isEmbedded ? dataset.openSpace : params.openSpace;
  const openCategory = isEmbedded ? dataset.category : params.categoryId;
  return { isVr: false, layerId, spaceId, splashImage, openSpace, openCategory };
}

async function loadVrAPIData({ params }: LoaderFunctionArgs) {
  store.dispatch(setIsEmbedded(isEmbedded));
  const orderId = isEmbedded ? dataset.client : params.orderId;
  if (!orderId) {
    if (isEmbedded) {
      throw new Error('Missing #visrez-interactive data-client attribute');
    } else {
      throw new Error('Bad URL');
    }
  }
  const vrId = isEmbedded ? dataset.vr : params.vrId;
  const viewId = isEmbedded ? dataset.view : params.viewId;
  const { payload: { splashImage }} = await store.dispatch(fetchOrderInfo({ orderId }));
  store.dispatch(fetchAPIVrData({ orderId, vrId: vrId! }));
  store.dispatch(setActiveModel(Number(vrId)));
  return { isVr: true, viewId, splashImage };
}

const router = createBrowserRouter(
  isEmbedded ? [
    // Routes for app embedded on client pages
    // We can't rely on path here, only on dataset attributes
    {
      path: '*',
      element: <AppLoader />,
      loader: (
        dataset.vr ? loadVrAPIData : loadMapAPIData
      ),
    },
  ] : [
    // When not embedded, use standard routing
    {
      path: '',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId/:vrId',
      element: <AppLoader />,
      loader: loadVrAPIData,
    },
    {
      path: '/:orderId/:vrId/view/:viewId',
      element: <AppLoader />,
      loader: loadVrAPIData,
    },
    {
      path: '/:orderId/map/:mapId',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId/map/:mapId/layer/:layerId',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId/map/:mapId/layer/:layerId/space/:spaceId/:openSpace?',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId/map/:mapId/cat/:categoryId',
      element: <AppLoader />,
      loader: loadMapAPIData,
    },
    {
      path: '/:orderId/map/:mapId/layer/:layerId/cat/:categoryId',
      element: <AppLoader />,
      loader: loadMapAPIData,
    }
  ]
);

// Create shadow root inside host
const el = process.env.REACT_APP_USE_SHADOW_ROOT === 'true'
  ? WidgetElement.shadowRoot!
  : WidgetElement!;
const root = createRoot(el);

root.render(
  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
