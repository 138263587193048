import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { faPencil, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';

import {
  useAppSelector,
  useAppDispatch,
  updateCategory,
  toggleCategory,
  isEditingSelector,
  SpaceType,
  CategoryType,
  categoriesSelector,
} from '../../../store';

import { Accordion } from '../../Accordion';

import { SpaceList } from '../SpaceList';
import { ChooseIcon } from './ChooseIcon';

import './CategoriesListItem.scss';

export interface CategoriesListItemProps {
  category: CategoryType;
  spaces: SpaceType[];
  index: number;
}

export function CategoriesListItem({ category, spaces, index }: CategoriesListItemProps) {
  const isEditing = useAppSelector(isEditingSelector);
  const dispatch = useAppDispatch();
  const categories = useAppSelector(categoriesSelector);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: category.id,
    data: {
      type: 'category',
      category,
    }
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const [renaming, setRenaming] = useState(false);
  const renameInputRef = useRef<HTMLInputElement | null>(null);
  const [message, setMessage] = useState('');
  const isValidCategory = (category:CategoryType, name:string) => {
    if (name === '') {
      setMessage("Category can't be blank");
      return false;
    }
    if (categories.find((c) => c.name === name && category.id !== c.id)) {
      setMessage(`${name} has already been taken`);
      return false;
    }
    setMessage('');
    return true;
  };
  const renameCategory = (category:CategoryType, name:string) => {
    if (isValidCategory(category, name)) {
      setRenaming(false);
      dispatch(updateCategory({...category, name}));
    }
  };

  const [choosingIcon, setChoosingIcon] = useState(false);

  if (isDragging) {
    return (
      <li
        ref={setNodeRef}
        className='category-placeholder'
        style={style}
      >
      </li>
    );
  }

  if (spaces.length === 0) return null;

  return (
    <li
      className={ cx('category', {
        'categories-drag-handle': isEditing,
      }) }
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      { choosingIcon && (
        <ChooseIcon
          category={ category }
          closeMenu={ () => setChoosingIcon(false) }
        />
      )}

      <Accordion
        open={category.opened}
        setOpen={() => dispatch(toggleCategory(category))}
        summary={
          <>
            <FontAwesomeIcon
              icon={['fas', category.icon as IconName]}
              onClick={ (e) => {
                if (!isEditing) return;
                e.stopPropagation();
                setChoosingIcon(!choosingIcon);
              }}
            />
            <span className={cx('category-name', { hidden: renaming })}>{category.name}</span>
            {renaming && (
              <input
                ref={renameInputRef}
                className={cx('rename-category', { error: message })}
                onChange={() => isValidCategory(category, renameInputRef.current!.value)}
                title={message}
                defaultValue={category.name}
                onClick={(e) => e.stopPropagation() }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    renameCategory(category, renameInputRef.current!.value);
                  }
                }}
              />
            )}
            {isEditing && (
              <FontAwesomeIcon
                icon={renaming ? faCheck : faPencil}
                role="button"
                onClickCapture={(e) => {
                  e.stopPropagation();
                  if (renaming) {
                    renameCategory(category, renameInputRef.current!.value);
                  } else {
                    setRenaming(true);
                  }
                }}
              />
            )}
          </>
        }
      >
        <SpaceList
          spaces={spaces}
          categoryId={category.id}
          categoryIndex={index}
        />
      </Accordion>
    </li>
  );
}
