import { useState } from 'react';
import './LinkContextMenu.scss';

export function LinkContextMenu({ id, name, positionX, positionY, onDelete, onRename }) {
  const [linkName, setLinkName] = useState(name);

  return (
    <div
      id="link-context-menu"
      style={{ top: positionY, left: positionX }}
    >
      <input
        type="text"
        value={linkName}
        onChange={(e) => setLinkName(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') onRename(id, linkName);
        }}
      />
      <button onClick={() => onRename(id, linkName)}>Rename</button>
      <button onClick={() => onDelete(id)}>Delete</button>
    </div>
  );
}
