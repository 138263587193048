import { createSlice, createSelector } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable';
import L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';

import {
  hasMapResponse,
  MapResponseAction,
  RootState,
  LayerType,
  ImageLayerType,
  ExternalLayerType,
  updateSpace,
} from '../../store';

// Transforms string coordinates to [lat, lng] array.
export function latLngFromDisplayValue(displayCoordinatesValue: string) {
  return displayCoordinatesValue.split(',').map(parseFloat);
}

export const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    list: [] as LayerType[],
    active: null as number | null,
  },
  reducers: {
    setActive: (state, action) => {
      if (state.list.find((i) => i.id === action.payload)) {
        state.active = action.payload;
      }
    },

    disable: (state, { payload }) => {
      // Prevent disabling all images
      if (state.list.filter((img) => !img.disabled).length > 1) {
        // Disable all linked layers when disabling the main one
        const linkedLayers = state.list.filter((img) => img.mainLayer === payload);
        linkedLayers.forEach((layer) => {
          state.list.find((img) => img.id === layer.id)!.disabled = true;
        });
        state.list.find((img) => img.id === payload)!.disabled = true;
      }
    },

    enable: (state, { payload }) => {
      const layer = state.list.find((img) => img.id === payload);
      if (!layer) return;
      layer.disabled = false;
      if (layer.mainLayer) {
        const mainLayer = state.list.find((img) => img.id === layer.mainLayer);
        if (mainLayer?.disabled) {
          mainLayer!.disabled = false;
        }
      }
    },

    rename: (state, { payload }) => {
      state.list.find((img) => img.id === payload.id)!.name = payload.name;
    },

    reorder: (state, { payload: { source, destination } }) => {
      const reorderedLayers = arrayMove(
        // Move disabled layers to the end of the array
        state.list.filter((l) => !l.disabled).concat(state.list.filter((l) => l.disabled)),
        source,
        destination
      );
      state.list = reorderedLayers.map((layer, index) => {
        return { ...layer, position_order: index };
      });
    },

    toggle: (state, { payload }) => {
      const layer = state.list.find((layer) => layer.id === payload.id);
      if (!layer) return;
      layer.opened = !payload.opened;
    },

    moveSpace: (state, action) => {
      const { id, x, y } = action.payload;
      const activeImg = state.list.find((img) => img.id === state.active);
      const spacePos = activeImg!.spaces.find((sPos) => sPos.space === id);
      spacePos!.position = { x, y };
    },

    removeSpace: (state, action) => {
      const id = action.payload;
      const activeImg = state.list.find((img) => img.id === state.active);
      activeImg!.spaces = activeImg!.spaces.filter((s) => s.space !== id);
    },

    moveLabel: (state, action) => {
      const { id, x, y } = action.payload;
      const activeImg = state.list.find((img) => img.id === state.active);
      const labelPos = activeImg!.labels.find((lPos) => lPos.label === id);
      labelPos!.position = { x, y };
    },

    removeLabel: (state, action) => {
      const id = action.payload;
      const activeImg = state.list.find((img) => img.id === state.active);
      activeImg!.labels = activeImg!.labels.filter((l) => l.label !== id);
    },

    // Add a new Space-position pointing to a Space
    // Also allows to change the pointed Space
    addSpace: (state, { payload }) => {
      const activeImg = state.list.find((img) => img.id === state.active)!;
      const { space, newSpace, lat: x, lng: y } = payload;
      const spacePos = activeImg.spaces.find((sPos) => sPos.space === space);
      if (spacePos && newSpace) {
        // Changing Space-position to point another Space
        spacePos.space = newSpace;
      } else {
        // Adding a Space-position for a new Space
        activeImg.spaces.push({ id: uuidv4(), space, position: { x, y }, layerId: activeImg.id });
      }
    },

    // Add a new Label-position pointing to a Label
    // Also allows to change the pointed Label
    addLabel: (state, { payload }) => {
      const activeImg = state.list.find((img) => img.id === state.active)!;
      const { label, newLabel, lat: x, lng: y } = payload;
      const labelPos = activeImg.labels.find((lPos) => lPos.label === label);
      if (labelPos && newLabel) {
        // Changing Label-position to point another Label
        labelPos.label = newLabel;
      } else {
        // Adding a Label-position for a new Label
        activeImg.labels.push({ id: uuidv4(), label, position: { x, y } });
      }
    },

    // Used for ExternalLayers, to save current view as default one
    saveCurrentView: (state, { payload }) => {
      const activeImg = state.list.find(
        (img) => img.id === state.active && img.type === 'External'
      ) as ExternalLayerType;
      if (!activeImg) return;
      activeImg.savedView = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSpace, (state, { payload }) => {
      const { id, coordinates } = payload;
      if (!coordinates) return;
      const [x, y] = latLngFromDisplayValue(coordinates);
      const activeImg = state.list.find((img) => img.id === state.active);
      const spacePos = activeImg!.spaces.find((sPos) => sPos.space === id);
      if (!spacePos) return;
      spacePos.position = { x, y };
    })
    builder.addMatcher(
      (action): action is MapResponseAction => hasMapResponse(action),
      (state, { payload }) => {
        state.list = [...payload.images]
        .sort((a, b) => {
          if (a.position_order === null) return 1;
          if (b.position_order === null) return -1;
          return a.position_order - b.position_order;
        });
        if (payload.firstItemOpen) {
          state.list[0] = { ...state.list[0], opened: true };
        }
        const active = state.list.find((img) => img.id === payload.default_image && !img.disabled)
          || state.list.find((img) => !img.disabled && !img.mainLayer);
          state.active = active ? active.id : state.list[0].id;
      }
    );
  },
});
export const {
  setActive,
  enable,
  disable,
  rename,
  toggle,
  reorder,
  moveSpace,
  removeSpace,
  moveLabel,
  removeLabel,
  addSpace,
  addLabel,
  saveCurrentView,
} = imagesSlice.actions;

export const imageBounds = (map: L.Map, image: ImageLayerType) =>
  new L.LatLngBounds(
    map.unproject([0, 0], map.getMaxZoom()),
    map.unproject([image.width, image.height], map.getMaxZoom()),
  );

// image bounds with extra margin to prevent cutting popups
export const imageBoundsBig = (map: L.Map, image: ImageLayerType) =>
  new L.LatLngBounds(
    map.unproject([-2000, -2000], map.getMaxZoom()),
    map.unproject([image.width + 2000, image.height + 2000], map.getMaxZoom()),
  );

export const externalView = (view:string) => {
  if (!view) {
    // World map, zoomed out and centered
    return [new L.LatLng(30, -25), 3];
  }
  const [lat,lng,zoom] = view.split(',').map(parseFloat);
  return [new L.LatLng(lat, lng), zoom];
};

export const activeImageSelector = createSelector(
  (state: RootState) => state.undoable.present.images.list,
  (state: RootState) => state.undoable.present.images.active,
  (images, active) => images.find((img) => img.id === active),
);

export const enabledImagesSelector = createSelector(
  (state: RootState) => state.undoable.present.images.list,
  (images) =>
    images
      .filter((img) => {
        return !img.disabled;
      })
);

export const disabledImagesSelector = createSelector(
  (state: RootState) => state.undoable.present.images.list,
  (images) => images.filter((img) => img.disabled),
);

// Space positions for current active layer
export const spacePositionsSelector = createSelector(activeImageSelector, (image) =>
  (image?.spaces || []).reduce(
    (res, space) => {
      res[space.space.toString()] = {
        x: space.position.x,
        y: space.position.y,
        layerId: space.layerId,
      };
      return res;
    },
    {} as { [spaceId: string]: { x: number; y: number; layerId: number; } },
  ),
);

// Get all Layers linked to active Layer
export const linkedLayersSelector = createSelector(
  enabledImagesSelector,
  activeImageSelector,
  (images, activeImage) => {
    if (!activeImage) return [];
    if (activeImage.type !== 'Image') return [activeImage];
    // Main layer, the one we show in view mode
    const mainLayer = activeImage.mainLayer
      ? images.find((img) => img.id === activeImage.mainLayer)!
      : activeImage;
    // Prevent crash when disabling main layer while dependent is the active
    if (!mainLayer) return [activeImage];
    // Dependent layers, same as main but with buildings cut
    const dependentLayers = images.filter((i) => i.mainLayer === mainLayer.id);
    return [ mainLayer, ...dependentLayers ].sort((a,b) => {
      if (a.position_order === null) return 1;
      if (b.position_order === null) return -1;
      return a.position_order - b.position_order;
    }) as ImageLayerType[];
  }
);

// Space positions for current active layer and its linked layers
// (main layer + dependent layers)
export const spacePositionsInAllLinkedLayersSelector = createSelector(
  enabledImagesSelector,
  activeImageSelector,
  linkedLayersSelector,
  (images, activeImage, linkedLayers) => {
    if (!activeImage) return {};
    // Find all spaces placed in mainLayer + dependentLayers
    const spaces = linkedLayers.map((layer) => layer.spaces).flat();
    return spaces.reduce(
      (res, space) => {
        if (
          // Add the space if it's not yet added
          !res[space.space.toString()]
            // if it's already added, override if it's from activeImage
            || space.layerId === activeImage.id
        ) {
          res[space.space.toString()] = {
            x: space.position.x,
            y: space.position.y,
            layerId: space.layerId,
          };
        }
        return res;
      },
      {} as { [spaceId: string]: { x: number; y: number; layerId: number; } },
    );
  }
);

export const labelPositionsSelector = createSelector(activeImageSelector, (image) =>
  (image?.labels || []).reduce(
    (res, label) => {
      res[label.label.toString()] = label.position;
      return res;
    },
    {} as { [labelId: string]: { x: number; y: number } },
  ),
);
