import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { interactiveURL } from '../../App';
import { orderIdSelector } from '../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faLink } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as VisrezLogo } from '../../media/logos/visrez-logo-text.svg';

import './Share.scss';

export function Share({ setShareIsOpen, vrID }) {
  const orderID = useSelector(orderIdSelector);
  const url = `${interactiveURL}/${orderID}/${vrID}`;
  const inputRef = useRef(null);
  const [message, setMessage] = useState('Select the URL and copy & paste to share');

  return (
    <div
      id="vr-share"
      onClick={() => setShareIsOpen(false)}
    >
      <div
        id="vr-share-box"
        onClick={(event) => event.stopPropagation()}
      >
        <a href="https://www.visrez.com/">
          <VisrezLogo title="Visrez Logo" />
        </a>
        <FontAwesomeIcon
          id="vr-share-box-close"
          icon={faCircleXmark}
          onClick={() => setShareIsOpen(false)}
        />
        <div id="vr-share-box-central">
          <div>
            <input
              ref={inputRef}
              type="text"
              readOnly="readonly"
              value={url}
            />
            <FontAwesomeIcon
              icon={faLink}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                  inputRef.current.select();
                }
                setMessage('Copied URL to clipboard');
                navigator.clipboard.writeText(url);
              }}
              title="Copy to clipboard"
            />
          </div>
        </div>
        <p>{message}</p>
      </div>
    </div>
  );
}
