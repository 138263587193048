import { useState, useRef, type ReactNode } from 'react';
import cx from 'classnames';

import './Accordion.scss';

const ANIMATION_DEFAULT_DURATION = 400;
const ANIMATION_DEFAULT_EASING = 'ease-out';

export interface AccordionProps {
  open: boolean;
  setOpen: () => void;
  children: ReactNode;
  summary: ReactNode;
  animationDuration?: number;
  animationEasing?: string;
}

export function Accordion({ open, setOpen, children, summary, animationDuration, animationEasing }: AccordionProps) {
  const detailsRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [isShrinking, setIsShrinking] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);

  const [, setAnimation] = useState<Animation | undefined>(undefined);

  const grow = () => {
    detailsRef.current!.style.height = `${detailsRef.current!.offsetHeight}px`;
    setOpen();

    requestAnimationFrame(() => {
      setIsExpanding(true);
      const startHeight = `${detailsRef.current!.offsetHeight}px`;
      const endHeight = `${summaryRef.current!.offsetHeight + contentRef.current!.offsetHeight}px`;

      setAnimation((animation) => {
        animation?.cancel();
        const newAnimation = detailsRef.current!.animate(
          { height: [startHeight, endHeight] },
          {
            duration: animationDuration || ANIMATION_DEFAULT_DURATION,
            easing: animationEasing || ANIMATION_DEFAULT_EASING,
          },
        );
        newAnimation.onfinish = () => onAnimationFinish();
        newAnimation.oncancel = () => setIsExpanding(false);
        return newAnimation;
      });
    });
  };

  const shrink = () => {
    setIsShrinking(true);

    const startHeight = `${detailsRef.current!.offsetHeight}px`;
    const endHeight = `${summaryRef.current!.offsetHeight}px`;

    setAnimation((animation) => {
      animation?.cancel();
      const newAnimation = detailsRef.current!.animate(
        { height: [startHeight, endHeight] },
        {
          duration: animationDuration || ANIMATION_DEFAULT_DURATION,
          easing: animationEasing || ANIMATION_DEFAULT_EASING,
        },
      );
      newAnimation.onfinish = () => onAnimationFinish();
      newAnimation.oncancel = () => setIsShrinking(false);
      return newAnimation;
    });
  };

  const onAnimationFinish = () => {
    setOpen();
    setAnimation(undefined);
    setIsShrinking(false);
    setIsExpanding(false);
    if (detailsRef.current) {
      detailsRef.current.style.height = '';
    }
  };

  return (
    <div
      className={cx('accordion', {'accordion-open': open})}
      ref={detailsRef}
      style={{ overflow: 'hidden' }}
    >
      <div
        className='accordion-summary'
        ref={summaryRef}
        onClick={(e) => {
          e.preventDefault();
          if (isShrinking || !open) {
            grow();
          } else if (isExpanding || open) {
            shrink();
          }
        }}
      >
        {summary}
      </div>
      <div
        className='accordion-content'
        ref={contentRef}
      >{children}</div>
    </div>
  );
}
