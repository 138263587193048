import { createSlice, createSelector } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';

import {
  hasMapResponse,
  MapResponseAction,
  SpaceType,
  CategoryType,
  RootState,
  activeImageSelector,
  allIcons,
} from '../../store';

const defaultCategoryIcon = 'square-chevron-down';

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState: {
    list: [] as SpaceType[],
    categories: [] as CategoryType[],
    active: null as string | null,
    livePreview: null as SpaceType | null,
  },
  reducers: {
    setActive: (state, { payload }) => {
      // Prevent livePreview overriding active space
      state.livePreview = null;
      state.active = payload;
    },

    update: (state, { payload }) => {
      // Create category if doesn't exist
      if (!payload.category_id) {
        // But first, try to find one with same name
        let cat = state.categories.find((c) => {
          return c.name === payload.category;
        });
        if (!cat) {
          cat = {
            id: uuidv4(),
            name: payload.category,
            opened: true,
            icon: defaultCategoryIcon,
          } as CategoryType;
          state.categories.push(cat);
        }
        payload.category_id = cat.id;
      }
      const space = state.list.find((s) => s.id === payload.id);
      if (space) {
        // Update existing space
        state.list = state.list.map((space) => (space.id === payload.id ? { ...space, ...payload } : space));
      } else {
        // Create new space
        state.list.push(payload);
      }
    },

    // TODO: not used, do we want to allow to remove a Space? (not a space position)
    remove: (state, { payload }) => {
      state.list = state.list.filter((space) => space.id !== payload);
    },

    reorder: (state, { payload }) => {
      state.list = payload.map((space:SpaceType, index:number) => {
        return { ...space, position_order: index }
      });
    },

    toggleCategory: (state, { payload }) => {
      const catIndex = state.categories.findIndex((cat) => cat.id === payload.id);
      if (catIndex >= 0) {
        state.categories[catIndex] = { ...payload, opened: !payload.opened };
      }
    },

    updateCategory: (state, { payload }) => {
      const catIndex = state.categories.findIndex((cat) => cat.id === payload.id);
      if (catIndex >= 0) {
        state.categories[catIndex] = payload;
      }
    },

    reorderCategories: (state, { payload: { source, destination } }) => {
      // Reorder this map categories, moving source to destination
      const reorderedCategories = arrayMove(
        state.categories,
        source,
        destination,
      );
      // Update categories position_order for new order
      state.categories = reorderedCategories.map((cat, index) => {
        return { ...cat, position_order: index };
      });
      // Update spaces position_order for the new categories order
      state.list = reorderedCategories
        .map((cat) => state.list.filter((s) => s.category_id === cat.id)).flat()
        .map((space, index) => ({ ...space, position_order: index }));
    },

    setLivePreview: (state, { payload }) => {
      state.livePreview = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is MapResponseAction => hasMapResponse(action),
      (state, { payload }) => {
        // spaces
        state.list = payload.spaces.map((space) => {
          // VR from models
          const model = payload.models.find((m) => m.id === space.model);
          if (model && model.vr.id) {
            return { ...space, vr_model: model.vr.id };
          }
          return space;
        }).map((space) => {
          // Set visual (model, uploaded or url)
          // from model
          if (space.visual_id) {
            const model = payload.models.find((m) => m.id === space.model);
            if (model) {
              const visual = model.visuals.find((v) => v.id === space.visual_id);
              if (visual && visual.thumbnail) {
                return { ...space, visual: visual.thumbnail };
              }
            }
          }
          // from uploaded visuals
          if (space.visual_uploaded) {
            return { ...space, visual: space.visual_uploaded.thumb };
          }
          // or from url
          return { ...space, visual: space.visual_url };
        }).map((space) => {
          // Sort carousel images
          return {
            ...space,
            carousel_images: ([...space.carousel_images]).sort((a, b) => {
              return a.position_order - b.position_order
            }),
          };
        }).sort((a, b) => a.position_order - b.position_order);

        // categories
        state.categories = [...payload.categories].sort((a, b) => {
          return a.position_order - b.position_order;
        }).map((category) => {
          return {
            ...category,
            icon: allIcons.includes(category.icon) ? category.icon : defaultCategoryIcon,
          }
        });
      }
    );
  },
});
export const {
  setActive,
  update,
  remove,
  reorder,
  toggleCategory,
  updateCategory,
  reorderCategories,
  setLivePreview,
} = spacesSlice.actions;

export const spacesSelector = (state: RootState) => state.undoable.present.spaces.list;
export const categoriesSelector = (state: RootState) => state.undoable.present.spaces.categories;

export const activeSpaceSelector = createSelector(
  (state: RootState) => state.undoable.present.spaces,
  (spaces) => {
    if (spaces.livePreview) return spaces.livePreview;
    return spaces.list.find((s) => s.id === spaces.active);
  },
);

// All spaces for the currently opened categories
export const visibleSpacesSelector = createSelector(
  (state: RootState) => state.undoable.present.spaces.list,
  categoriesSelector,
  (spaces, categories) => {
    const openedCategories = categories.filter((c) => c.opened);
    const categoryIds = openedCategories.map((c) => c.id);
    return spaces.filter((space) => {
      return categoryIds.includes(space.category_id);
    });
  },
);

// Spaces not placed in active image
export const notPlacedSpacesSelector = createSelector(
  spacesSelector,
  activeImageSelector,
  (spaces, activeImage) => {
    if (!activeImage) return [];
    return spaces.filter((s) => {
      return !activeImage.spaces.map((sPos) => sPos.space).includes(s.id);
    });
  }
);

// Spaces placed in active image
export const placedSpacesSelector = createSelector(
  spacesSelector,
  activeImageSelector,
  (spaces, activeImage) => {
    if (!activeImage || !activeImage.opened) return [];
    return spaces.filter((s) => {
      return activeImage.spaces.map((sPos) => sPos.space).includes(s.id);
    });
  }
);

// Spaces placed on all Layers, grouped by layerId
export const allPlacedSpacesSelector = createSelector(
  (state: RootState) => state.undoable.present.images.list,
  spacesSelector,
  (layers, spaces) =>  {
    return layers.reduce(
      (res, layer) => {
        res[layer.id] = spaces.filter((s) => {
          return layer.spaces.map((sPos) => sPos.space).includes(s.id);
        });
        return res;
      },
      {} as { [ layerId: number ]: SpaceType[] }
    );
  }
);
