import axios from 'axios';
import Cookies from 'js-cookie';

import { apiBaseURL } from './App';

const jwt = (() => {
  if (process.env.NODE_ENV === 'development') {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('jwt')) {
      return `Bearer ${urlParams.get('jwt')}`;
    } else if (Cookies.get('_jwt')) {
      return `Bearer ${Cookies.get('_jwt')}`;
    } else {
      return `Bearer ${process.env.REACT_APP_PLATFORM_JWT}`;
    }
  } else {
    return `Bearer ${Cookies.get('_jwt')}`;
  }
})();

const authFetch = axios.create({
  baseURL: apiBaseURL,
  headers: {
    Accept: 'application/json',
    Authorization: jwt,
  },
});

export default authFetch;
