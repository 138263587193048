import { useLoaderData } from "react-router-dom";
import { useRef, useContext, useState, useEffect } from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import { LeafletContext } from '../../../App';

import {
  setActiveSpace,
  activeImageSelector,
  spacePositionsSelector,
  moveSpace,
  useAppDispatch,
  useAppSelector,
  visibleSpacesSelector,
  isEditingSelector,
  SpaceType,
  setActiveModel,
  setEditedSpace,
  mapSelector,
  placedSpacesSelector,
  editedSpacePositionSelector,
  setEditedSpacePosition,
  editedSpaceIdSelector,
} from '../../../store';

import './Spaces.scss';

export interface SpaceProps {
  openModal: Function;
}

export function Spaces({ openModal }: SpaceProps) {
  const { spaceId, openSpace } = useLoaderData() as { spaceId:string, openSpace:string };
  const { activeMarkerRef, mapRef } = useContext(LeafletContext);
  const dispatch = useAppDispatch();

  const isEditing = useAppSelector(isEditingSelector);
  const activeImage = useAppSelector(activeImageSelector);
  const iMap = useAppSelector(mapSelector);

  const markers = iMap.markers;
  const scaleFactor = 1.3;
  const spaceIconWidth = Number(iMap.markersSize.split('x')[0]);
  const spaceIconHeight = Number(iMap.markersSize.split('x')[1]);

  // All spaces placed in current image, regardless its categories
  const placedSpaces = useAppSelector(placedSpacesSelector);
  // All spaces placed in current image, with its category opened in sidebar
  const spaces = useAppSelector(visibleSpacesSelector);
  const spacePositions = useAppSelector(spacePositionsSelector);
  const editedSpacePosition = useAppSelector(editedSpacePositionSelector);
  const editedSpaceId = useAppSelector(editedSpaceIdSelector);

  const setActive = (space: SpaceType) => {
    dispatch(setActiveSpace(space.id));
    dispatch(setActiveModel(space.vr_model));
  };

  const [hoveredMarker, setHoveredMarker] = useState('');

  const icon = L.icon({
    iconSize: [spaceIconWidth, spaceIconHeight],
    iconAnchor: [spaceIconWidth / 2, spaceIconHeight],
    popupAnchor: [0, spaceIconHeight * -0.70],
    iconUrl: markers.base64 ? markers.base64 : markers.url,
  });

  const iconBig = L.icon({
    iconSize: [spaceIconWidth * scaleFactor, spaceIconHeight * scaleFactor],
    iconAnchor: [(spaceIconWidth * scaleFactor) / 2, spaceIconHeight * scaleFactor],
    popupAnchor: [0, spaceIconHeight * -0.70],
    iconUrl: markers.base64 ? markers.base64 : markers.url,
  });

  const isDragging = useRef(false);

  // Opens popup on load, if spaceId is provided in url
  useEffect(() => {
    if (spaceId && activeMarkerRef.current[spaceId]) {
      if (openSpace === 'open') {
        const space = spaces.find((s) => s.id === spaceId);
        if (space) {
          setActive(space);
          openModal();
        }
      }
      setTimeout(() => {
        activeMarkerRef.current[spaceId].openPopup();
        const position = spacePositions[spaceId];
        mapRef.current!.flyTo([position.x, position.y]);
      }, 200);
    }
    // Run on first load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(iMap.groupBy === 'categories' ? spaces : placedSpaces).map(
        (space) =>
          spacePositions[space.id] && spacePositions[space.id].layerId === activeImage?.id && (
            <Marker
              icon={hoveredMarker === space.id ? iconBig : icon}
              title={space.name}
              key={space.id}
              ref={(ref) => (activeMarkerRef.current[space.id] = ref!)}
              position={editedSpaceId === space.id && editedSpacePosition !== null ? editedSpacePosition : [spacePositions[space.id].x, spacePositions[space.id].y]}
              draggable={isEditing}
              autoPan={true}
              eventHandlers={{
                // Hide popup if editing
                click: (e) => isEditing && e.target.closePopup(),
                // Clicked on marker on edit mode
                mouseup: (e) => {
                  if (!isEditing || isDragging.current) return;
                  dispatch(setEditedSpace({
                    id: space.id,
                    position: {
                      lat: +spacePositions[space.id].x,
                      lng: +spacePositions[space.id].y,
                      layerId: spacePositions[space.id].layerId,
                    },
                  }));
                },
                // Zoom marker on mouse over but not while dragging
                // since it causes problems when draggin over another marker
                mouseover: (e) => !isDragging.current && setHoveredMarker(space.id),
                mouseout: (e) => !isDragging.current && setHoveredMarker(''),
                // Prevent opening edit form when dragging
                drag: (e) => (isDragging.current = true),
                // Update marker position after dragging
                dragend: (e) => {
                  const { lat: x, lng: y } = e.target._latlng;
                  dispatch(moveSpace({ id: space.id, x, y }));
                  if (editedSpaceId === space.id) {
                    dispatch(setEditedSpace({
                      id: space.id,
                      position: {
                        lat: +x,
                        lng: +y,
                        layerId: spacePositions[space.id].layerId,
                      },
                    }));
                  }
                  isDragging.current = false;
                },
                popupopen: (e) => {
                  dispatch(setActiveSpace(space.id));
                  e.popup.options.autoPan = true;
                  setTimeout(() => {
                    // Disable autoPan after Popup is centered
                    // fixes moving Map when hovering markers
                    e.popup.options.autoPan = false;
                  }, 500);
                },
                popupclose: (e) => {
                  dispatch(setActiveSpace(null));
                  e.popup.options.autoPan = true;
                },
              }}
            >
              <Popup autoPan={true}>
                <div className="explore-card">
                  {space.visual || space.visual_uploaded?.base64 ? (
                    <div className="explore-card__media">
                      {!!(space.vr_iframe || space.vr_model || space.video || space.carousel_images?.length) ? (
                        <button
                          className="tour-icon"
                          onClick={() => {
                            setActive(space);
                            openModal();
                          }}
                        >
                          View
                        </button>
                      ) : (
                        <span></span>
                      )}
                      <img
                        src={space.visual || space.visual_uploaded.base64}
                        alt={space.name}
                        onError={(i) => ((i.target as HTMLImageElement).style.display = 'none')}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <div className="explore-card__menu">
                    <h2 className="explore-card__title">{space.name}</h2>
                  </div>
                  {space.external_link && !(space.vr_iframe || space.vr_model || space.video || space.carousel_images?.length) && (
                    <a
                      href={space.external_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="external-link-button">Learn More</button>
                    </a>
                  )}
                </div>
              </Popup>
            </Marker>
          ),
      )}
      {editedSpaceId === 'newSpace' && editedSpacePosition && (
        <Marker
          icon={iconBig}
          position={[editedSpacePosition.lat, editedSpacePosition.lng]}
          draggable={isEditing}
          eventHandlers={{
            drag: () => { isDragging.current = true; },
            dragend: (e) => {
              dispatch(setEditedSpacePosition({ ...editedSpacePosition, ...e.target._latlng }));
              mapRef.current?.stop();
              mapRef.current?.panTo(e.target._latlng);
              isDragging.current = false;
            },
          }}
        />
      )}
    </>
  );
}
