import { createPortal } from 'react-dom';
import { useMap } from 'react-leaflet';
import cx from 'classnames';
import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  PointerSensor as DndPointerSensor,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

import {
  useAppDispatch,
  isEditingSelector,
  enabledImagesSelector,
  activeImageSelector,
  setActiveImage,
  useAppSelector,
  reorderLayers,
  disableLayer,
  setEditedSpaceId,
} from '../../../store';

import { EditLayer } from './EditLayer';
import { AddableLayers } from './AddableLayers';

import './LayersControl.scss';
import { useState } from 'react';

export function LayersControl() {
  const dispatch = useAppDispatch();
  const map = useMap();

  const images = useAppSelector(enabledImagesSelector);
  const mainImages = images.filter((i) => !i.mainLayer);
  const activeImage = useAppSelector(activeImageSelector);
  const isEditing = useAppSelector(isEditingSelector);

  const [isVisible, setIsVisible] = useState(mainImages.length > 1);

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active?.data?.current || !over?.data?.current) return;
    if (active.id !== over.id) {
      const oldIndex = active.data.current.sortable.index;
      const newIndex = over.data.current.sortable.index;
      dispatch(reorderLayers(
        { source: oldIndex, destination: newIndex }
      ));
    }
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }

  // Custom Sensor: prevents dragging while selecting text on input elements
  class PointerSensor extends DndPointerSensor {
    static activators = [
      {
        eventName: 'onPointerDown' as const,
        handler: ({ nativeEvent:event }: { nativeEvent:MouseEvent }) => {
          const target = event.target as HTMLElement;
          if (!target) return false;
          if (target.tagName.toLowerCase() === 'input') return false;
          return true;
        },
      },
    ];
  }

  // To allow onClick events in Layer
  const sensors = useSensors(
    useSensor(
      PointerSensor,
      { activationConstraint: { distance: 10 } }
    )
  );

  return createPortal(
    <aside
      className="custom-layers-control leaflet-control leaflet-control-layers leaflet-control-layers-expanded"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <section
        className={cx('layers-display-button', { 'hidden': mainImages.length < 2 && !isEditing && !isVisible })}
        onClick={toggleVisibility}
      >
        <FontAwesomeIcon icon={faLayerGroup} size='3x'/>
      </section>
      <section
        className={cx('leaflet-control-layers-base', { 'hidden': !isVisible })}
      >
        <DndContext
          sensors={sensors}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={images}
          >
            {images.map((image) => (
              <EditLayer
                key={image.id}
                image={image}
                isActive={activeImage?.id === image.id}
                setActive={() => {
                  dispatch(setActiveImage(image.id));
                  // close Editor when changing Layer
                  dispatch(setEditedSpaceId(null));
                  map.getContainer()?.focus();
                }}
                disable={() => {
                  if (mainImages.length > 1 || image.mainLayer) {
                    dispatch(disableLayer(image.id));
                  }
                }}
              />))}
          </SortableContext>
        </DndContext>
        <AddableLayers />
      </section>
      <section className="leaflet-control-layers-overlays" />
    </aside>,
    // @ts-ignore
    map._controlCorners['bottomleft'],
  );
}
