import { useLoaderData } from "react-router-dom";

import './Loader.scss';
import { ReactComponent as VisrezLogo } from '../../media/logos/visrez-logo-text-down.svg';

const Loader = () => {

  const { splashImage } = useLoaderData() as {
    splashImage:string,
  };

  if (splashImage) {
    return (
      <div className="loader-container">
        <div className="loader-box">
          <img
            className="loader-image"
            src={splashImage}
            alt="logo"
            title="Loading..."
          />
          <VisrezLogo
            className="loader-image animated bottom"
            title="Loading..."
          />
        </div>
      </div>
    );
  }

  return (
    <div className="loader-container">
      <div className="loader-box">
        <VisrezLogo
          className="loader-image animated alone"
          title="Loading..."
        />
      </div>
    </div>
  );
};

export default Loader;
