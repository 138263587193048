import {
  useState,
  useRef,
  createContext,
  useContext,
  useCallback,
  ReactElement,
  RefObject,
  PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import L from 'leaflet';

import { HTMLMapOverlay } from '../../HTMLMapOverlay';

import './PieMenu.scss';

export interface PieMenuContextType {
  items: ReactElement[];
  options: Record<string, any>;
  active: string | null;
  labelPortalTargetsRef: RefObject<HTMLDivElement[]>;
}

const PieMenuContext = createContext({} as PieMenuContextType);

export interface PieMenuProps {
  children: ReactElement[];
  innerRadius: number;
  outterRadius: number;
  hoverGrowthFactor: number;
  menuRadius: number;
  gap: number;
  labelScale: number;
  leaveRadiusFactor: number;
  position: L.LatLngExpression;
  onLeave: () => void;
  onClick: (id: string) => void;
}

export const PieMenu = (props: PieMenuProps) => {
  const { innerRadius, outterRadius, hoverGrowthFactor, menuRadius, gap, labelScale, leaveRadiusFactor } = props;
  const options = {
    innerRadius,
    outterRadius,
    hoverGrowthFactor,
    menuRadius,
    gap,
    labelScale,
    leaveRadiusFactor,
  };

  const labelPortalTargetsRef = useRef<HTMLDivElement[]>([]);

  const [activeItem, setActiveItem] = useState<string | null>(null);

  const expansionFactor = Math.max(hoverGrowthFactor + gap, leaveRadiusFactor);
  const overallRadius = menuRadius * expansionFactor;

  const onMouseLeave = useCallback(() => {
    setActiveItem(null);
    props?.onLeave();
  }, [setActiveItem, props]);

  return (
    <HTMLMapOverlay latlng={props.position}>
      <PieMenuContext.Provider
        value={{
          items: props.children,
          options,
          active: activeItem,
          labelPortalTargetsRef,
        }}
      >
        <div
          style={{ position: 'relative' }}
          onMouseLeave={onMouseLeave}
        >
          <svg
            id="pie-menu"
            width={overallRadius * 2}
            height={overallRadius * 2}
            viewBox={`${-1 * expansionFactor} ${-1 * expansionFactor} ${2 * expansionFactor} ${2 * expansionFactor}`}
            xmlns="http://www.w3.org/2000/svg"
            onMouseOver={(e) => setActiveItem((e.target as HTMLElement).dataset.pathId!)}
            onClick={(e) => props?.onClick((e.target as HTMLElement).dataset.pathId!)}
          >
            <circle
              cx={0}
              cy={0}
              r={leaveRadiusFactor}
              fill="transparent"
              onMouseLeave={(e) => {
                if (
                  e.relatedTarget &&
                  (e.relatedTarget as HTMLElement).dataset &&
                  !(e.relatedTarget as HTMLElement).dataset.pathId
                ) {
                  onMouseLeave();
                }
              }}
            />
            {props.children}
          </svg>
          <div
            id="pie-menu-text-overlay"
            style={{
              position: 'absolute',
              inset: 0,
              pointerEvents: 'none',
            }}
          >
            {props.children.map((child: ReactElement, i: number) => (
              <div
                ref={(r) => {
                  labelPortalTargetsRef.current[i] = r!;
                }}
                key={child.props.id}
              ></div>
            ))}
          </div>
        </div>
      </PieMenuContext.Provider>
    </HTMLMapOverlay>
  );
};

export interface PieMenuItemProps {
  id: string;
}

export const PieMenuItem = (props: PropsWithChildren<PieMenuItemProps>) => {
  const { items, options, active, labelPortalTargetsRef } = useContext(PieMenuContext);
  const index = items?.findIndex((item) => item.props === props);
  const count = items?.length;

  if (index === -1 || !count) return null;

  const startAngle = 2 * Math.PI * (index / count) - Math.PI / count - Math.PI / 2;
  const endAngle = startAngle + (2 * Math.PI) / count;

  const middleAngle = startAngle + (endAngle - startAngle) / 2;
  const offsetX = Math.cos(middleAngle) * options.gap;
  const offsetY = Math.sin(middleAngle) * options.gap;

  const isHovered = active === props.id;
  const effectiveOutterRadius = isHovered ? options.outterRadius * options.hoverGrowthFactor : options.outterRadius;

  const bigArcStartX = Math.cos(startAngle) * effectiveOutterRadius + offsetX;
  const bigArcStartY = Math.sin(startAngle) * effectiveOutterRadius + offsetY;
  const bigArcEndX = Math.cos(endAngle) * effectiveOutterRadius + offsetX;
  const bigArcEndY = Math.sin(endAngle) * effectiveOutterRadius + offsetY;

  const smallArcStartX = Math.cos(startAngle) * options.innerRadius + offsetX;
  const smallArcStartY = Math.sin(startAngle) * options.innerRadius + offsetY;
  const smallArcEndX = Math.cos(endAngle) * options.innerRadius + offsetX;
  const smallArcEndY = Math.sin(endAngle) * options.innerRadius + offsetY;

  const userUnitsToCSSPixelConversionFactor = options.menuRadius;

  const effectiveLabelScale = (isHovered ? options.hoverGrowthFactor : 1) * options.labelScale;
  const effectiveLabelRadius =
    ((options.outterRadius + options.innerRadius) / 2) *
    0.95 *
    userUnitsToCSSPixelConversionFactor *
    (isHovered ? options.hoverGrowthFactor : 1);

  const pathCenterX = Math.cos(middleAngle) * effectiveLabelRadius;
  const pathCenterY = Math.sin(middleAngle) * effectiveLabelRadius;

  const labelOffsetX = `${pathCenterX}px`;
  const labelOffsetY = `${pathCenterY}px`;

  return (
    <>
      <path
        data-path-id={props.id}
        pointerEvents="painted"
        d={`
              M ${bigArcStartX} ${bigArcStartY}
              A ${effectiveOutterRadius} ${effectiveOutterRadius} 0 0 1 ${bigArcEndX} ${bigArcEndY}
              L ${smallArcEndX} ${smallArcEndY}
              A ${options.innerRadius} ${options.innerRadius} 0 0 0 ${smallArcStartX} ${smallArcStartY}
              Z
        `}
        className={cx({ hovered: isHovered })}
      />
      {labelPortalTargetsRef.current && labelPortalTargetsRef.current[index]
        ? createPortal(
            <div
              style={{
                position: 'absolute',
                top: `calc(50% - ${options.gap * 100}px)`,
                left: '50%',
                transform: `translate(${labelOffsetX}, ${labelOffsetY}) translate(-50%, -50%) scale(${effectiveLabelScale}, ${effectiveLabelScale})`,
              }}
            >
              {props.children}
            </div>,
            labelPortalTargetsRef.current[index],
          )
        : null}
    </>
  );
};
