import cx from 'classnames';
import {
  useRef,
  useEffect,
  useContext,
  type Dispatch,
  type SetStateAction,
} from 'react';

import {
  useAppDispatch,
  useAppSelector,
  mapSelector,
  isEditingSelector,
  isEditingSettingsSelector,
  editedSpaceIdSelector,
  setEditedSpaceId,
} from '../../store';
import { LeafletContext } from '../../App';

import { CategoriesList } from './CategoriesList';
import { LayersList } from './LayersList';
import Video from './Video';
import { EditSpace } from '../EditTools/EditMarker/EditSpace';
import { EditSettings } from './EditSettings';

import './Sidebar.scss';

export interface SidebarButtonProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export function SidebarButton({ isSidebarOpen, setIsSidebarOpen }: SidebarButtonProps) {
  return (
    <button
      className={'sidebar-button'}
      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
    />
  );
}

export interface SidebarProps {
  isSidebarOpen: boolean;
}

export function Sidebar({ isSidebarOpen }: SidebarProps) {
  const dispatch = useAppDispatch();
  const { mapRef } = useContext(LeafletContext);
  const iMap = useAppSelector(mapSelector);
  const isEditing = useAppSelector(isEditingSelector);
  const isEditingSettings = useAppSelector(isEditingSettingsSelector);
  const editedSpaceId = useAppSelector(editedSpaceIdSelector);

  const asideRef = useRef<HTMLElement>(null);

  // Pans the map when opening/closing Sidebar
  useEffect(() => {
    const aside = asideRef.current;
    if (!aside) return;

    const onTransitionStart = (e: TransitionEvent) => {
      if (!e.propertyName.startsWith('margin-')) return;

      const duration = parseFloat(getComputedStyle(e.target as HTMLElement).transitionDuration);
      const element = e.target! as HTMLElement;
      const sign = isSidebarOpen ? 1 : -1;
      const offset: [number, number] =
        e.propertyName === 'margin-bottom'
          ? [0, (sign * element.offsetHeight) / 2]
          : [(sign * element.offsetWidth) / 2, 0];

      mapRef.current!.panBy(offset, {
        animate: true,
        duration,
        easeLinearity: 0.5,
      });
    };

    aside.addEventListener('transitionstart', onTransitionStart);
    return () => {
      aside.removeEventListener('transitionstart', onTransitionStart);
    };
  }, [isSidebarOpen, mapRef]);

  const sidebarContent = () => {

    if (isEditingSettings) {
      return (
        <EditSettings />
      );

    } else if (editedSpaceId) {
      return (
        <EditSpace
          spaceId={editedSpaceId}
          onClose={() => dispatch(setEditedSpaceId(null))}
        />
      );

    } else {
      return (
        <>
          <section className="sidebar-video">
            <Video />
          </section>
          <section>
            { iMap.groupBy === 'categories' || isEditing
              ? <CategoriesList />
              : <LayersList />
            }
          </section>
        </>
      );
    }
  }

  return (
    <aside
      ref={asideRef}
      className={cx('sidebar', {
        'sidebar-closed': !isSidebarOpen,
        editing: isEditing,
      })}
    >
      <nav>
        { sidebarContent() }
      </nav>
    </aside>
  );
}
