import {
  useContext,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencil, faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  useAppDispatch,
  useAppSelector,
  activeImageSelector,
  setActiveImage,
  activeSpaceSelector,
  setActiveSpace,
  setActiveModel,
  isEditingSelector,
  spacePositionsInAllLinkedLayersSelector,
  updateSpace,
  removeSpace,
  type SpaceType,
  setEditedSpace,
} from '../../../store';
import { LeafletContext } from '../../../App';

import './SpaceListItem.scss';

export interface SpaceListItemProps {
  space: SpaceType;
  categoryIndex: number;
}

export function SpaceListItem({ space, categoryIndex }: SpaceListItemProps) {
  const { mapRef, activeMarkerRef } = useContext(LeafletContext);

  const dispatch = useAppDispatch();
  const activeImage = useAppSelector(activeImageSelector);
  const spacePositions = useAppSelector(spacePositionsInAllLinkedLayersSelector);
  const isEditing = useAppSelector(isEditingSelector);
  const position = useAppSelector(spacePositionsInAllLinkedLayersSelector)[space.id];
  const active = useAppSelector(activeSpaceSelector);
  const setActive = (s: SpaceType) => {
    dispatch(setActiveSpace(s.id));
    dispatch(setActiveModel(s.vr_model));
  };
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: space.id,
    data: {
      type: 'space',
      space: space,
      categoryIndex: categoryIndex,
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [renaming, setRenaming] = useState(false);
  const renameInputRef = useRef<HTMLInputElement | null>(null);

  const jumpToMarker = () => {
    const marker = activeMarkerRef.current[space.id];
    if (!marker) return;
    marker.openPopup();
    setActive(space);

    mapRef.current!.stop();
    mapRef.current!.flyTo([position.x, position.y], 19);
    const popup = marker.getPopup();
    if (popup) {
      // Center popup after flying to marker
      mapRef.current!.once(
        'zoomend',
        () => mapRef.current!.fire('popupopen', {popup})
      );
    }
    setActive(space);
  };

  if (isDragging) {
    return (
      <li
        ref={setNodeRef}
        style={style}
        className='space-placeholder'
      />
    );
  }

  return (
    <li
      className={cx('space', {
        active: active?.id === space.id,
        disabled: !spacePositions[space.id],
        'space-drag-handle': isEditing,
      })}
      key={space.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={() => {
        if (isEditing) {
          dispatch(setEditedSpace({
            id: space.id,
            position: { lat: +spacePositions[space.id].x, lng: +spacePositions[space.id].y, layerId: spacePositions[space.id].layerId },
          }));
          mapRef.current?.stop();
          mapRef.current?.panTo([+spacePositions[space.id].x, +spacePositions[space.id].y]);
          return;
        }
        if (spacePositions[space.id].layerId !== activeImage?.id) {
          dispatch(setActiveImage(spacePositions[space.id].layerId));
          // TODO: Find better way to change layers
          // this makes some visual glitches
          setTimeout(jumpToMarker, 300);
        } else {
          jumpToMarker();
        }
      }}
    >
      {space.visual || space.visual_uploaded?.base64 ? (
        <img
          className="thumbnail"
          src={space.visual || space.visual_uploaded.base64}
          alt={space.name}
        />
      ) : (
        <span className="no-image">
          <FontAwesomeIcon icon={faEye} />
        </span>
      )}
      <span className={cx({ 'hidden': renaming })}>
        {space.name}
      </span>
      {renaming && (
        <input
          ref={renameInputRef}
          className='rename-space'
          defaultValue={space.name}
          onClick={(e) => e.stopPropagation() }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              setRenaming(false);
              dispatch(updateSpace({
                ...space,
                name: renameInputRef.current!.value
              }));
            }
          }}
        />
      )}
      {isEditing && (
        <FontAwesomeIcon
          icon={faTrash}
          role="button"
          onClickCapture={() => {
            dispatch(removeSpace(space.id));
          }}
        />
      )}
      {isEditing && (
        <FontAwesomeIcon
          icon={renaming ? faCheck : faPencil}
          role="button"
          onClickCapture={(e) => {
            e.stopPropagation();
            if (renaming) {
              setRenaming(false);
              dispatch(updateSpace({
                ...space,
                name: renameInputRef.current!.value
              }));
            } else {
              setRenaming(true);
            }
          }}
        />
      )}
    </li>
  );
}
