import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useLoaderData } from "react-router-dom";
import cx from 'classnames';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { DeviceOrientationControls } from './DeviceOrientationControls.js';
import SpriteText from 'three-spritetext';
import { isMobile } from 'react-device-detect';
import tinycolor from "tinycolor2";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import {
  useAppSelector,
  useAppDispatch,
  updateModelsVr,
  mapSelector,
  activeModelSelector,
  canEditSelector,
  orderDescriptionSelector,
  activeSpaceSelector,
  isEmbeddedSelector,
  vrUrlSelector,
} from '../../store';

import ErrorPage from '../ErrorPage';
import { requestFullscreen, exitFullscreen } from '../../utils';

import { VrMenu } from './VrMenu';
import { LinkContextMenu } from './LinkContextMenu';
import { Share } from './Share';
import { Name } from './Name';
import './Vr.scss';

// Camera settings
const MAX_ZOOM = 100;
const MIN_ZOOM = 20;
const ZOOM_STEP = 5;

// Links (for changing Views)
const linkIcon = require('../../media/textures/locationtex.png');
const iconTexture = new THREE.TextureLoader().load(linkIcon);

const Vr = (props) => {

  const { viewId } = useLoaderData();

  // Loading status for fetching images
  const [loadingStatus, setLoadingStatus] = useState('loading');

  const iMap = useAppSelector(mapSelector);
  const primaryColor = tinycolor(iMap.primaryColor);
  const secondaryColor = tinycolor(iMap.secondaryColor);

  const isEmbedded = useAppSelector(isEmbeddedSelector);
  const orderDescription = useAppSelector(orderDescriptionSelector);

  const showAdmin = useAppSelector(canEditSelector) && !isMobile;
  const activeModel = useAppSelector(activeModelSelector);
  const currentVr = activeModel?.vr?.id ? activeModel.vr : null;
  const activeSpace = useAppSelector(activeSpaceSelector);

  // Links Icon materials
  const iconMaterial = useMemo(() => {
    return new THREE.SpriteMaterial({
      map: iconTexture,
      color: primaryColor.toString(),
      depthTest: false,
    });
  }, [primaryColor]);
  const iconMaterialHover = useMemo(() => {
    return new THREE.SpriteMaterial({
      map: iconTexture,
      color: primaryColor.clone().brighten().toString(),
      depthTest: false,
    });
  }, [primaryColor]);

  // Links for changing Views (THREE objects)
  const viewLinks = useRef([]);
  // Link under cursor
  const [hoveredLinkID, setHoveredLinkID] = useState(null);
  // Right clicked link (for context menu)
  const [rightClickedLink, setRightClickedLink] = useState(null);
  // Moved Link (to update store)
  const [movedLink, setMovedLink] = useState(null);
  // Mouse position (for context menu)
  const [mousePosition, setMousePosition] = useState(null);
  // Scene div
  const sceneRef = useRef(null);
  // Cubemap images
  const [cubemaps, setCubemaps] = useState([]);
  // Currently active cubemap id
  // Used to switch skybox
  const [activeCubemapId, setActiveCubemapId] = useState(null);
  // Currently active cubemap
  // Used to draw links and more, should be null while switching skybox
  const [activeCubemap, setActiveCubemap] = useState(null);
  // Box that holds cubemap images
  const skybox = useRef(null);
  // THREE Scene
  const scene = useRef(null);
  // Raycaster
  const raycaster = useRef(null);
  // Spinner to show when loading cubemap images
  const [visibleSpinner, setVisibleSpinner] = useState(false);

  // Current View Links, to change View (store data)
  const [links, setLinks] = useState([]);
  // Default View to load
  const [defaultView, setDefaultView] = useState(null);

  // Fade time when switching Skybox
  const [fadeTime, setFadeTime] = useState(10);
  // Stop switching Skybox while already doing
  const [isFading, setIsFading] = useState(false);

  // Display AdminMenu instead of Layout buttons
  // Changes actions performed on click/drag Links
  const adminMode = useRef(false);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const vrUrl = useAppSelector(vrUrlSelector);

  const [vrMenuIsOpen, setVrMenuIsOpen] = useState(false);
  const [shareIsOpen, setShareIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const setLinksInStore = useCallback(
    (newLinks) => {
      dispatch(
        updateModelsVr({
          view: activeCubemap.id,
          links: newLinks,
        }),
      );
    },
    [activeCubemap, dispatch],
  );
  const setDefaultViewInStore = useCallback(
    (newDefaultView) => {
      dispatch(
        updateModelsVr({
          defaultView: {
            id: newDefaultView,
            layout: activeCubemap.layout,
            light: activeCubemap.light,
            size: activeCubemap.size,
          },
        }),
      );
    },
    [activeCubemap, dispatch],
  );

  // Update variables for current VR
  useEffect(() => {
    if (currentVr) {
      if (viewId) {
        setActiveCubemapId(Number(viewId));
      } else {
        setActiveCubemapId(currentVr.default_view.id);
      }
      setDefaultView(currentVr.default_view.id);
      setCubemaps(currentVr.cubemaps);
      if ([...new Set(currentVr.cubemaps.map((c) => c.layout))].length === 1) {
        setVrMenuIsOpen(false);
      }
    }
  // Trigger only when currentVr changes its ID,
  // to prevent changing view when currentVr.links change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVr?.id]);

  // Keep changes in View Links after changing View
  useEffect(() => {
    if (currentVr) {
      setCubemaps(currentVr.cubemaps);
    }
  }, [currentVr]);

  // Initialize THREE
  useEffect(() => {
    if (!currentVr?.id) return;
    // Zoom in / out
    const handleScroll = (e) => {
      e.preventDefault();
      const zoom = camera.fov;
      if (e.deltaY > 0) {
        // zoom out
        if (zoom < MAX_ZOOM) camera.fov += ZOOM_STEP;
      } else {
        // zoom in
        if (zoom > MIN_ZOOM) camera.fov -= ZOOM_STEP;
      }
      camera.updateProjectionMatrix();
    };

    // Resize scene
    const resizeObserver = new ResizeObserver(() => {
      camera.aspect = sceneDiv.clientWidth / sceneDiv.clientHeight;
      // Zoom in/out camera depending on scene height
      camera.fov = sceneDiv.clientHeight * 0.08;
      camera.updateProjectionMatrix();
      renderer.setSize(sceneDiv.clientWidth, sceneDiv.clientHeight);
    });

    // Update raycaster direction from mouse position
    const updateRaycaster = (event) => {
      if (event.type === 'touchstart') {
        pointer.x = (event.touches[0].clientX / renderer.domElement.width) * 2 - 1;
        pointer.y = -(event.touches[0].clientY / renderer.domElement.height) * 2 + 1;
      } else {
        pointer.x = (event.offsetX / renderer.domElement.width) * 2 - 1;
        pointer.y = -(event.offsetY / renderer.domElement.height) * 2 + 1;
      }
      raycaster.current.setFromCamera(pointer, camera);
    };

    const onPointerMove = (event) => {
      updateRaycaster(event);
      if (adminMode.current && dragingLink) {
        // Move a Link
        const intersects = raycaster.current.intersectObject(skybox.current, false);
        if (intersects.length > 0) {
          dragingLink.position.copy(intersects[0].point);
        }
      } else {
        // Change color for Link under cursor
        const intersects = raycaster.current.intersectObjects(viewLinks.current, true);
        if (intersects.length > 0) {
          hoveredLink = intersects[0].object;
          setHoveredLinkID(hoveredLink.userData.linkID);
        } else if (hoveredLink) {
          hoveredLink = null;
          setHoveredLinkID(null);
        }
      }
    };

    const onDragover = (event) => {
      updateRaycaster(event);
    };

    const changeView = (viewID) => {
      viewLinks.current = [];
      setActiveCubemap(null)
      setActiveCubemapId(Number(viewID));
      hoveredLink = null;
      dragingLink = null;
      setMovedLink(null);
    };

    const onTouchStart = (event) => {
      onPointerMove(event);
      if (!hoveredLink) return;
      changeView(hoveredLink.userData.linkID);
    }

    const onClick = (event) => {
      if (!hoveredLink) {
        setRightClickedLink(null);
        return;
      }
      // left click
      if (event.buttons === 1) {
        if (adminMode.current) {
          // Store clicked Link
          clickedLink = hoveredLink;
        } else {
          // Navigate to hovered Link view
          changeView(hoveredLink.userData.linkID);
        }
      }
      // right click
      if (adminMode.current && event.buttons === 2) {
        setRightClickedLink(hoveredLink.parent);
        setMousePosition({ x: event.offsetX, y: event.offsetY });
      }
    };

    const onDblclick = (event) => {
      if (!hoveredLink) return;
      changeView(hoveredLink.userData.linkID);
    };

    const onMouseMove = (event) => {
      if (adminMode.current && event.buttons === 1 && clickedLink) {
        // We are moving a Link
        dragingLink = clickedLink.parent;
        // Disable camera rotation
        controls.enableRotate = false;
      }
    };

    const onMouseUp = (event) => {
      if (dragingLink) {
        hoveredLink = dragingLink;
        setHoveredLinkID(hoveredLink.userData.linkID);
      }
      setMovedLink(dragingLink);
      controls.enableRotate = true;
      clickedLink = null;
      dragingLink = null;
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    };
    const stop = () => {
      cancelAnimationFrame(frameId);
    };
    const animate = () => {
      renderScene();
      frameId = window.requestAnimationFrame(animate);
    };
    const renderScene = () => {
      controls.update();
      renderer.render(scene.current, camera);
    };

    const sceneDiv = sceneRef.current;
    const width = sceneDiv.clientWidth;
    const height = sceneDiv.clientHeight;
    let frameId = null;
    // Link under cursor
    let hoveredLink = null;
    // Link clicked
    let clickedLink = null;
    // Link we are moving (adminMode)
    let dragingLink = null;

    scene.current = new THREE.Scene();

    // Add Renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    sceneDiv.appendChild(renderer.domElement);

    // Add Camera
    const camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 10000);
    camera.position.z = 20;
    camera.position.y = 5;

    // Camera Controls
    const controls = isMobile ?
      new DeviceOrientationControls(camera, renderer.domElement)
      : new OrbitControls(camera, renderer.domElement);
    // Disable moving with mouse right button
    controls.enablePan = false;
    // Smoother camera movement
    controls.enableDamping = true;
    controls.dampingFactor = 0.07;
    controls.enableZoom = false;

    raycaster.current = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    renderScene();

    // Start animation
    start();

    // For resizing window
    resizeObserver.observe(sceneDiv);

    // Wheel Zoom
    sceneDiv.addEventListener('wheel', handleScroll);

    // Detect hovered Links and move Links
    sceneDiv.addEventListener('pointermove', onPointerMove);

    // Detect clicked links
    if (isMobile) {
      sceneDiv.addEventListener('touchstart', onTouchStart);
    } else {
      sceneDiv.addEventListener('mousedown', onClick);
      sceneDiv.addEventListener('dblclick', onDblclick);
    }

    // For moving links
    sceneDiv.addEventListener('mousemove', onMouseMove);

    // Links moving end
    sceneDiv.addEventListener('mouseup', onMouseUp);

    // For adding new links
    sceneDiv.addEventListener('dragover', onDragover);

    return () => {
      // componentWillUnmount
      stop();
      sceneDiv.removeChild(renderer.domElement);
      resizeObserver.unobserve(sceneDiv);
      sceneDiv.removeEventListener('wheel', handleScroll);
      sceneDiv.removeEventListener('pointermove', onPointerMove);
      if (isMobile) {
        sceneDiv.removeEventListener('touchstart', onTouchStart);
      } else {
        sceneDiv.removeEventListener('mousedown', onClick);
        sceneDiv.removeEventListener('dblclick', onDblclick);
      }
      sceneDiv.removeEventListener('mousemove', onMouseMove);
      sceneDiv.removeEventListener('mouseup', onMouseUp);
      sceneDiv.removeEventListener('dragover', onDragover);
      // Need to unset frameId here, for StrictMode
      frameId = undefined;
    };
  // Prevent black screen while changing Cubemap
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVr?.id]);

  // Draw Skybox
  useEffect(() => {
    if (!activeCubemapId) return;
    const switchSkybox = (newSkybox) => {
      if (skybox.current === null) {
        // First Load
        skybox.current = newSkybox;
        scene.current.add(newSkybox);
        setActiveCubemap(cubemaps.find((c) => c.id === activeCubemapId));
      } else {
        setIsFading(true);
        // Switch Skybox with a fading effect
        for (let i = 0; i <= fadeTime*5; i += 1) {
          setTimeout(() => {
            if (i === 0) {
              newSkybox.material.forEach((m) => (m.transparent = true));
              skybox.current.material.forEach((m) => (m.transparent = true));
              scene.current.add(newSkybox);
            }
            // increase newSkybox opacity
            const opacity = (i / (fadeTime*5));
            newSkybox.material.forEach((m) => m.opacity = opacity);
            // decrease current skybox opacity
            skybox.current.material.forEach((m) => m.opacity = 1 - opacity);
            if (i === fadeTime*5) {
              newSkybox.material.forEach((m) => {
                m.transparent = false;
              });
              scene.current.remove(skybox.current);
              skybox.current = newSkybox;
              setHoveredLinkID(null);
              // Restore fadeTime to default value
              setFadeTime(10);
              setIsFading(false);
              setActiveCubemap(cubemaps.find((c) => c.id === activeCubemapId));
            }
          }, i * fadeTime);
        }
      }
    };

    const manager = new THREE.LoadingManager();
    manager.onStart = () => {
      setVisibleSpinner(true);
    };
    manager.onLoad = () => {
      // All images loaded, switch Skybox
      setLoadingStatus('success');
      const newSkybox = new THREE.Mesh(new THREE.BoxGeometry(10000, 10000, 10000), materialArray);
      // Horizontally flip images in skybox
      newSkybox.scale.x = -1;
      switchSkybox(newSkybox);
      setLinks(cubemaps.find((c) => c.id === activeCubemapId).links);
      setVisibleSpinner(false);
      props.componentLoaded?.();
    };
    manager.onError = (url) => {
      setLoadingStatus('failed');
      console.error(`There was an error loading ${url}`);
    };
    const loader = new THREE.TextureLoader(manager);
    const { images } = cubemaps.find((c) => c.id === activeCubemapId);
    const materialArray = Object.values(images).map((image) => {
      let texture = loader.load(image);
      texture.colorSpace = THREE.SRGBColorSpace;
      return new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
      });
    });
  // Prevents links blink on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCubemapId]);

  // Draw Links for changing Views
  useEffect(() => {
    if (isFading || !activeCubemap) return;
    const linkObjects = [];

    Object.keys(links).forEach((id) => {
      let { name, x, y, z } = links[id];
      const link = new THREE.Group();
      link.userData.linkID = id;
      link.userData.linkName = name;
      link.userData.type = 'group';

      // Create person icon for the link
      const sprite = new THREE.Sprite(iconMaterial);
      sprite.userData.linkID = id;
      sprite.userData.type = 'icon';
      sprite.scale.set(400, 400, 400);
      link.add(sprite);

      // Create link label
      const label = new SpriteText(name);
      label.color = primaryColor;
      label.backgroundColor = secondaryColor.clone().setAlpha(.6);
      label.borderRadius = 3;
      label.padding = [6, 2];
      label.textHeight = 150;
      label.fontWeight = 'bold';
      label.fontSize = 40;
      label.material.depthTest = false;
      label.position.y = -340;
      label.userData.linkID = id;
      label.userData.type = 'label';
      link.add(label);

      link.position.set(x, y, z);

      linkObjects.push(link);
      scene.current.add(link);
    });

    viewLinks.current = linkObjects;

    return () => {
      linkObjects.forEach((l) => {
        scene.current.remove(l);
      });
    };
  // Prevents link highlight blinking when hovering them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCubemap, cubemaps]);

  // Change hovered Link color
  useEffect(() => {
    viewLinks.current.forEach((link) => {
      link.children.forEach((obj) => {
        if (obj.userData.linkID === hoveredLinkID) {
          if (obj.userData.type === 'icon') {
            obj.material = iconMaterialHover;
          } else {
            obj.color = primaryColor.clone().brighten();
            obj.backgroundColor = secondaryColor.clone().setAlpha(.6).brighten();
          }
        } else {
          if (obj.userData.type === 'icon') {
            obj.material = iconMaterial;
          } else {
            obj.color = primaryColor;
            obj.backgroundColor = secondaryColor.clone().setAlpha(.6);
          }
        }
      });
    });
  }, [hoveredLinkID, iconMaterial, iconMaterialHover, primaryColor, secondaryColor]);

  // Update moved Link position from THREE object
  useEffect(() => {
    if (movedLink) {
      const {
        [movedLink.userData.linkID]: { ...moved },
        ...rest
      } = links;
      moved.x = movedLink.position.x;
      moved.y = movedLink.position.y;
      moved.z = movedLink.position.z;
      const updatedLinks = {
        ...rest,
        [movedLink.userData.linkID]: moved,
      };
      setLinksInStore(updatedLinks);
      setLinks(updatedLinks);
      setMovedLink(null);
    }
  }, [movedLink, links, setLinksInStore]);

  // Find a cubemap with given name, layout, light and size
  // If can't find it, keeps trying removing attributes, to keep
  // view as similar to the current one as possible
  const findMatchingCubemap = (name, layout, light, size) => {
    const toMatch = { name, layout, light, size };
    let match = null;
    while(!match) {
      match = cubemaps.find((c) => {
        return Object.entries(toMatch).every(
          ([name, value]) => c[name] === value
        );
      });
      if (Object.keys(toMatch).length > 1) {
        delete(toMatch[Object.keys(toMatch).slice(-1)]);
      } else {
        // use the first cubemap as fallback
        console.error(`Missing view ${name} - ${layout} - ${light} - ${size}`);
        match = cubemaps[0];
      }
    }
    return match;
  }

  const onSelectLayout = (layout) => {
    if (isFading || !activeCubemap) return false;
    const newCubemap = findMatchingCubemap(
      activeCubemap.name,
      layout,
      activeCubemap.light,
      activeCubemap.size,
    );

    if (newCubemap.id !== activeCubemap.id) {
      setActiveCubemap(null)
      setActiveCubemapId(newCubemap.id);
    }
  };

  const onSelectLight = (light) => {
    if (isFading || !activeCubemap) return false;
    const newCubemap = findMatchingCubemap(
      activeCubemap.name,
      activeCubemap.layout,
      light,
      activeCubemap.size,
    );
    if (newCubemap.id !== activeCubemap.id) {
      setActiveCubemap(null)
      setActiveCubemapId(newCubemap.id);
    }
  };

  const onSelectSize = (size) => {
    if (isFading || !activeCubemap) return false;
    const newCubemap = findMatchingCubemap(
      activeCubemap.name,
      activeCubemap.layout,
      activeCubemap.light,
      size,
    );
    if (newCubemap.id !== activeCubemap.id) {
      setActiveCubemap(null);
      setActiveCubemapId(newCubemap.id);
    }
  };

  const toggleFullscreen = () => {
    if (isEmbedded) {
      window?.open(vrUrl, '_blank', 'noreferrer')?.focus();
      return;
    }
    const mainDiv = process.env.REACT_APP_USE_SHADOW_ROOT === 'true'
      ? document.getElementById('visrez-interactive').shadowRoot.getElementById('vr-main')
      : document.getElementById('vr-main');

    isFullscreen
      ? exitFullscreen().then(() => setIsFullscreen(!isFullscreen))
      : requestFullscreen(mainDiv).then(() => setIsFullscreen(!isFullscreen));
  };

  // Place a new Link to a View
  const onDragLink = (id, name) => {
    const intersects = raycaster.current.intersectObject(skybox.current, false);
    if (intersects.length > 0) {
      const updatedLinks = {
        ...links,
        [id]: {
          name: name,
          x: intersects[0].point.x,
          y: intersects[0].point.y,
          z: intersects[0].point.z,
        },
      };
      setLinksInStore(updatedLinks);
      setLinks(updatedLinks);
    }
  };

  // Delete a placed Link
  const deleteLink = (id) => {
    setRightClickedLink(null);
    const { [id]: removedLink, ...rest } = links;
    setLinksInStore(rest);
    setLinks(rest);
  };

  // Rename a placed Link
  const renameLink = (id, name) => {
    const {
      [id]: { ...renamedLink },
      ...rest
    } = links;
    renamedLink.name = name;
    const updatedLinks = { ...rest, [id]: renamedLink };
    setRightClickedLink(null);
    setLinksInStore(updatedLinks);
    setLinks(updatedLinks);
  };

  // Update default View
  const updateDefaultView = (id) => {
    setDefaultView(id);
    setDefaultViewInStore(id);
  };

  if (loadingStatus === 'failed') {
    return <ErrorPage />
  }

  if (!isEmbedded && orderDescription && (activeSpace || activeModel)) {
    document.title = `Visrez Interactive - ${orderDescription} - ${activeSpace?.name || activeModel?.name}`;
  }

  if (!currentVr) return null;

  return (
    <>
      <div
        id="vr-spinner"
        className={cx({ visible: visibleSpinner })}
      >
        <FontAwesomeIcon
          icon={faSpinner}
          spin
        />
      </div>
      <div id="vr-main" className={cx({'hidden': loadingStatus !== 'success'})}>
        <div
          style={{ width: '100%', height: '100%' }}
          ref={sceneRef}
        />
        {shareIsOpen && (
          <Share
            setShareIsOpen={setShareIsOpen}
            vrID={currentVr.id}
          ></Share>
        )}
        <Name name={currentVr.name} />
        <VrMenu
          isOpen={vrMenuIsOpen}
          setIsOpen={setVrMenuIsOpen}
          // Layouts
          layouts={[...new Set(cubemaps.map((c) => c.layout))]}
          activeLayout={activeCubemap?.layout}
          onSelectLayout={(name) => onSelectLayout(name)}
          // Lights
          lights={[...new Set(cubemaps.map((c) => c.light))]}
          activeLight={activeCubemap?.light}
          onSelectLight={(light) => onSelectLight(light)}
          // Sizes
          sizes={[...new Set(cubemaps.filter((c) => c.layout === activeCubemap?.layout).map((c) => c.size))]}
          activeSize={activeCubemap?.size}
          onSelectSize={(size) => onSelectSize(size)}
          // Stuff for adminMode
          showAdmin={showAdmin}
          adminMode={adminMode.current}
          toggleAdminMode={() => (adminMode.current = !adminMode.current)}
          views={cubemaps.filter((c) => {
            return c.layout === activeCubemap?.layout &&
              c.light === activeCubemap?.light &&
              c.size === activeCubemap?.size;
          })}
          activeView={activeCubemapId}
          setActiveView={(id) => {
            if (id === activeCubemapId) return;
            setActiveCubemap(null);
            setActiveCubemapId(id);
          }}
          defaultView={defaultView}
          setDefaultView={(id) => updateDefaultView(id)}
          links={links}
          setHoveredLinkID={(linkID) => setHoveredLinkID(linkID)}
          onDragLink={(viewID, name) => onDragLink(viewID, name)}
          modelID={currentVr.id}
          // Other
          isFullscreen={isFullscreen}
          onToggleFullscreen={toggleFullscreen}
          setShareIsOpen={setShareIsOpen}
        />
        {rightClickedLink && (
          <LinkContextMenu
            id={rightClickedLink.userData.linkID}
            name={rightClickedLink.userData.linkName}
            positionX={mousePosition.x}
            positionY={mousePosition.y}
            onDelete={(id) => deleteLink(id)}
            onRename={(id, name) => renameLink(id, name)}
          />
        )}
      </div>
    </>
  );
};

export default Vr;
