import { useMemo } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import {
  useAppSelector,
  isEditingSelector,
  SpaceType,
} from '../../../store';

import { SpaceListItem } from './SpaceListItem';

import './SpaceList.scss';

export interface SpaceListProps {
  spaces: SpaceType[];
  categoryId: string;
  categoryIndex: number;
}

export function SpaceList({ spaces, categoryId, categoryIndex }: SpaceListProps) {

  const isEditing = useAppSelector(isEditingSelector);
  const spacesId = useMemo(() => spaces.map((s) => s.id), [spaces]);

  const spaceListItems = (
    spaces.map((space) => (
      <SpaceListItem
        space={space}
        categoryIndex={categoryIndex}
        key={space.id}
      />
    ))
  );

  if (!isEditing) {
    return (
      <ol className="space-list">
        { spaceListItems }
      </ol>
    );
  }

  // isEditing
  return (
    <ol className="space-list">
      <SortableContext
        id={categoryId}
        items={spacesId}
        strategy={verticalListSortingStrategy}
      >
        { spaceListItems }
      </SortableContext>
    </ol>
  );
};
