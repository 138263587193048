import { useRef, useEffect, useLayoutEffect, useState, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

import './HTMLMapOverlay.scss';

const PANE = 'html-overlay';

class HTMLOverlay extends L.DivOverlay {
  constructor(map: L.Map, options: Record<string, any>) {
    if (!map.getPane(PANE)) {
      map.createPane(PANE);
    }

    super({ ...options, pane: PANE });

    this._initLayout();

    this.setLatLng(options.latlng);
  }

  _initLayout() {
    // @ts-ignore TS2339
    if (this._container) return;

    // @ts-ignore TS2339
    this._container = L.DomUtil.create('div', 'html-overlay-container');
    // @ts-ignore TS2339
    this._contentNode = L.DomUtil.create(
      'div',
      'html-overlay-content',
      // @ts-ignore TS2339
      this._container,
    );
  }

  _updateLayout() {}

  _adjustPan() {}

  getEvents() {
    return {};
  }
}

interface HTMLMapOverlayProps {
  onReady?: () => void;
  latlng: L.LatLngExpression;
  fitInBounds?: boolean;
  [key: string]: any;
}

export function HTMLMapOverlay(props: PropsWithChildren<HTMLMapOverlayProps>) {
  const map = useMap();
  const overlayRef = useRef<HTMLOverlay | undefined>(undefined);
  const [contentRect, setContentRect] = useState<DOMRect | undefined>(undefined);
  const [mapRect, setMapRect] = useState<DOMRect | undefined>(undefined);

  const { children, onReady, ...options } = props;

  if (overlayRef.current === undefined) overlayRef.current = new HTMLOverlay(map, options);
  useEffect(() => {
    if (!overlayRef.current) return;

    overlayRef.current.openOn(map);
    if (onReady) onReady();

    return () => {
      if (!overlayRef.current) return;

      overlayRef.current.remove();
    };
  }, [map, onReady]);

  useEffect(() => {
    if (!overlayRef.current) return;
    overlayRef.current.update();
  }, [props.children]);

  useLayoutEffect(() => {
    if (!props.fitInBounds) return;

    setContentRect(
      [
        // @ts-ignore TS2339
        overlayRef.current!._contentNode.getBoundingClientRect(),
        // @ts-ignore TS2339
        ...(Array.from(overlayRef.current!._contentNode.children) as HTMLElement[]).map((el) =>
          el.getBoundingClientRect(),
        ),
      ].reduce(
        (acc, rect) => {
          acc.x = Math.min(acc.x, rect.x);
          acc.y = Math.min(acc.y, rect.y);
          acc.width = Math.max(acc.width, rect.width);
          acc.height = Math.max(acc.height, rect.height);
          return acc;
        },
        new DOMRect(Infinity, Infinity, 0, 0),
      ),
    );

    // @ts-ignore TS2339
    setMapRect(map._container.getBoundingClientRect());
  }, [
    // @ts-ignore TS2339
    map._container,
    props.fitInBounds,
    props.children,
    // @ts-ignore TS2339
    overlayRef.current._contentNode,
  ]);

  useEffect(() => {
    if (!props.fitInBounds || !mapRect || !contentRect || contentRect.width === 0 || contentRect.height === 0) return;

    map.fitBounds(
      L.latLngBounds([
        map.containerPointToLatLng([contentRect.left - mapRect.left, contentRect.top - mapRect.top]),
        map.containerPointToLatLng([contentRect.right - mapRect.left, contentRect.bottom - mapRect.top]),
      ]),
      { maxZoom: map.getZoom() },
    );
  }, [contentRect, mapRect, map, props]);

  // @ts-ignore TS2339
  return overlayRef.current._contentNode && children
    ? // @ts-ignore TS2339
      createPortal(children, overlayRef.current._contentNode)
    : null;
}
