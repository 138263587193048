import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const editSlice = createSlice({
  name: 'edit',
  initialState: {
    isEditing: false,
    isEditingSettings: false,
    editedSpaceId: null as string | null,
    editedSpacePosition: null as { lat: number, lng: number, layerId: number } | null,
    livePreview: false,
  },
  reducers: {
    toggleEdit: (state) => {
      state.isEditing = !state.isEditing;
      if (!state.isEditing) {
        state.isEditingSettings = false;
        state.editedSpaceId = null;
      }
    },
    toggleEditingSettings: (state) => ({
      ...state,
      isEditingSettings: !state.isEditingSettings,
    }),
    setEditedSpace: (state, { payload }) => {
      state.editedSpaceId = payload?.id ?? null;
      state.editedSpacePosition = payload?.position ?? null;
    },
    setEditedSpaceId: (state, { payload }) => {
      state.editedSpaceId = payload;
    },
    setEditedSpacePosition: (state, { payload }) => {
      state.editedSpacePosition = payload;
    },
    setLivePreview: (state, { payload }) => {
      state.livePreview = payload;
    },
  },
});

export const isEditingSelector = (state: RootState) => state.edit.isEditing;
export const isEditingSettingsSelector = (state: RootState) => state.edit.isEditingSettings;
export const editedSpaceIdSelector = (state: RootState) => state.edit.editedSpaceId;
export const editedSpacePositionSelector = (state: RootState) => state.edit.editedSpacePosition;
export const livePreviewSelector = (state: RootState) => state.edit.livePreview;

export const {
  toggleEdit,
  toggleEditingSettings,
  setEditedSpaceId,
  setEditedSpacePosition,
  setLivePreview,
  setEditedSpace,
} = editSlice.actions;

export default editSlice.reducer;
