import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import {
  ImageType,
} from '../../store';

import './ImageCarousel.scss';

export function ImageCarousel({
  images,
  itemsOpts,
  imageGalleryOpts,
  video,
}: {
  images:ImageType[],
  itemsOpts?:any
  imageGalleryOpts?:any,
  video?:string,
}) {

  const items = images.map((image) => {
    if (image.base64) {
      // images not yet saved to API
      return {
        original: image.base64,
        thumbnail: image.base64,
        ...(itemsOpts || {})
      }
    } else {
      return {
        original: image.url,
        thumbnail: image.thumb,
        ...(itemsOpts || {})
      };
    }
  });

  const renderVideo = (item:{ original:string }) => {
    return (
      <iframe
        src={item.original}
        width="100%"
        height="100%"
        frameBorder="0"
        // @ts-ignore TS2322
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        title="guestroom video"
      />
    );
  }

  if (video) {
    items.unshift({
      thumbnail: "/icons/video.png",
      original: video,
      renderItem: renderVideo,
    });
  }

  const opts:ReactImageGalleryProps = {
    items,
    showThumbnails: items.length > 1,
    showPlayButton: items.length > 1,
    ...(imageGalleryOpts || {}),
  };

  return (
    <ImageGallery {...opts} />
  );

}
