import cx from 'classnames';

import './DropdownWithImage.scss';

// expects items on list to have name and thumbnail attrs
export function DropdownWithImage({ id, list, isOpen, setIsOpen, selected, onClick, itemExtraClass = (item) => '' }) {
  return (
    <div className="dropdown-with-image">
      <ul
        open={isOpen}
        onClick={(e) => {
          setIsOpen(isOpen ? null : id);
          e.preventDefault();
        }}
      >
        <li title={selected?.name}>
          {selected && (
            <img
              src={selected.thumbnail}
              alt={selected.name}
            />
          )}
          {selected ? selected.name : 'None'}
        </li>
      </ul>
      <ul className={cx("dropdown-content", { hidden: !isOpen })}>
        <li
          key="none"
          className={cx({ selected: selected === null })}
          onClick={(e) => {
            onClick(null);
            setIsOpen(null);
            e.stopPropagation();
          }}
        >
          None
        </li>
        {list.map((item) => (
          <li
            key={item.id}
            className={cx(itemExtraClass(item), { selected: item.id === selected?.id })}
            title={item.name}
            onClick={(e) => {
              onClick(item);
              setIsOpen(null);
              e.stopPropagation();
            }}
          >
            <img
              src={item.thumbnail}
              alt={item.name}
            />
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
