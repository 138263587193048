import { useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
  LabelType,
  addLabel,
  updateLabel,
  removeLabel,
  notPlacedLabelsSelector,
  labelsSelector,
} from '../../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

type SaveRefType = { current: { callback: (() => void) | null } };

export interface EditLabelProps {
  marker: LabelType;
  doSaveRef: SaveRefType;
  onSuccess: () => void;
  onClose: () => void;
}

export function EditLabel({ marker, doSaveRef, onSuccess, onClose }: EditLabelProps) {
  const dispatch = useAppDispatch();
  const allLabels = useAppSelector(labelsSelector);
  const notPlacedLabels = useAppSelector(notPlacedLabelsSelector);
  // not placed labels plus current one
  const labels = allLabels
    .filter((l) => l.id === marker.id)
    .concat(notPlacedLabels)
    .sort((a, b) => a.name.localeCompare(b.name));

  const [id, setId] = useState(marker.id);
  const [name, setName] = useState(marker.name || '');

  doSaveRef.current.callback = () => {
    if (id === marker.id) {
      if (name === '') {
        dispatch(removeLabel(marker.id));
        return;
      }
      // Update Label with new name
      dispatch(updateLabel({ id, name }));
    } else {
      // Update Label-position to point another Label
      dispatch(addLabel({ label: marker.id, newLabel: id }));
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={() => {
          dispatch(removeLabel(marker.id));
          onClose();
        }}
      />
      <form
        method="dialog"
        onSubmit={onSuccess}
      >
        <fieldset>
          <legend>Label</legend>
          {notPlacedLabels.length > 0 && (
            <label>
              Action:
              <select
                id="label-id"
                onChange={(e) => setId(e.target.value)}
              >
                {!allLabels.find((l) => l.id === marker.id) && (
                  <option
                    value=""
                    onClick={() => setId(marker.id)}
                  >
                    Create new label
                  </option>
                )}
                <optgroup label="Use existing">
                  {labels.map((label) => {
                    return (
                      <option
                        value={label.id}
                        key={label.id}
                        onClick={() => setId(label.id)}
                        selected={label.id === id}
                      >
                        {label.name}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
            </label>
          )}
          {id === marker.id && (
            <label>
              Name
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          )}
        </fieldset>
      </form>
    </>
  );
}
