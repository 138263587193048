import {
  combineReducers,
  configureStore,
  PayloadAction,
  ThunkAction,
  Middleware,
  createAction,
  AnyAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { thunk } from 'redux-thunk';
import undoable, { includeAction } from 'redux-undo';

import apiReducer from './modules/api';
import { imagesSlice } from './modules/images';
import { mapSlice } from './modules/map';
import { labelsSlice } from './modules/labels';
import { spacesSlice } from './modules/spaces';
import editReducer from './modules/edit';
import { modelsSlice } from './modules/models';

import { saveState, loadState } from './autoSaver';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
library.add(fas);
//@ts-ignore
export const allIcons = Object.keys(library.definitions.fas);

export type RootState = ReturnType<typeof rootReducer>;

const undoableActions = [
  // Layers
  'images/disable',
  'images/enable',
  'images/rename',
  'images/reorder',
  'images/saveCurrentView',
  // Labels
  'images/addLabel',
  'images/moveLabel',
  'labels/update',
  'images/removeLabel',
  // Spaces
  'images/addSpace',
  'images/moveSpace',
  'spaces/update',
  'images/removeSpace',
  'spaces/reorder',
  // Space Categories
  'spaces/updateCategory',
  'spaces/reorderCategories',
];

const rootReducer = combineReducers({
  undoable: undoable(
    combineReducers({
      api: apiReducer,
      images: imagesSlice.reducer,
      labels: labelsSlice.reducer,
      spaces: spacesSlice.reducer,
    }),
    {
      // limit: 40, // limit history size
      filter: includeAction(undoableActions),
      syncFilter: true,
    },
  ),
  edit: editReducer,
  map: mapSlice.reducer,
  models: modelsSlice.reducer,
});

// Automatically save to localStorage to recover state between sessions.
const autoSaverMiddleware:Middleware = storeAPI => next => (action:any) => {

  // Call next, so getState() returns the updated state.
  const result = next(action);

  // Store changes when dispatching undoable actions and after undo/redo
  if (undoableActions.includes(action.type)
      || action.type === '@@redux-undo/UNDO'
      || action.type === '@@redux-undo/REDO') {
    const { undoable, map } = storeAPI.getState();
    saveState(undoable, `${map.order_id}-${map.id}`);

  } else if (action.type === 'api/saveAPI/fulfilled') {
    // After saving, delete localStorage
    const { map } = storeAPI.getState();
    saveState(undefined, `${map.order_id}-${map.id}`);
  }

  return result;
};

// To replace store from localStorage
const replaceStore = createAction<any>('store/replace');
// See https://stackoverflow.com/questions/59424523#73372455
const reducerProxy = (state: any, action: AnyAction) => {
  if(action.type === 'store/replace') {
    // override undoable with the one provided in payload
    return rootReducer({...state, undoable: action.payload }, action);
  }
  return rootReducer(state, action);
}

const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(thunk).concat(autoSaverMiddleware);
  },
});

// Infer the `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type AppThunk<Payload = any, ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  PayloadAction<Payload>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const canUndoSelector = (state: RootState) => state.undoable.past.length > 0;
export const canRedoSelector = (state: RootState) => state.undoable.future.length > 0;

export { saveState, loadState, replaceStore };

export {
  fetchOrderInfo,
  fetchAPIData,
  fetchAPIVrData,
  orderIdSelector,
  orderDescriptionSelector,
  apiErrorSelector,
  apiLoadingStatusSelector,
  apiSavingStatusSelector,
  saveAPI,
  mapNeedsSaveSelector,
  hasMapResponse,
  type MapResponseAction,
} from './modules/api';

export {
  setIsEmbedded,
  mapSelector,
  loggedInSelector,
  canEditSelector,
  isEmbeddedSelector,
  mapUrlSelector,
  setMapSettings,
} from './modules/map';

export {
  setActive as setActiveModel,
  updateModelsVr,
  saveModelsVR,
  modelsSelector,
  modelsWithVRSelector,
  modelsWithoutVRSelector,
  activeModelSelector,
  vrNeedsSaveSelector,
  vrUrlSelector,
} from './modules/models';

export {
  setActive as setActiveImage,
  enable as enableLayer,
  disable as disableLayer,
  rename as renameLayer,
  reorder as reorderLayers,
  toggle as toggleLayer,
  imageBounds,
  imageBoundsBig,
  externalView,
  activeImageSelector,
  enabledImagesSelector,
  disabledImagesSelector,
  linkedLayersSelector,
  spacePositionsSelector,
  spacePositionsInAllLinkedLayersSelector,
  labelPositionsSelector,
  addSpace,
  moveSpace,
  removeSpace,
  addLabel,
  moveLabel,
  removeLabel,
  saveCurrentView,
  latLngFromDisplayValue,
} from './modules/images';

export {
  labelsSelector,
  notPlacedLabelsSelector,
  update as updateLabel,
} from './modules/labels';

export {
  setActive as setActiveSpace,
  update as updateSpace,
  reorder as reorderSpaces,
  activeSpaceSelector,
  spacesSelector,
  allPlacedSpacesSelector,
  placedSpacesSelector,
  notPlacedSpacesSelector,
  categoriesSelector,
  visibleSpacesSelector,
  updateCategory,
  toggleCategory,
  reorderCategories,
  setLivePreview as setLivePreviewSpace,
} from './modules/spaces';

export {
  toggleEdit,
  isEditingSelector,
  toggleEditingSettings,
  isEditingSettingsSelector,
  setEditedSpaceId,
  editedSpaceIdSelector,
  setEditedSpacePosition,
  editedSpacePositionSelector,
  setLivePreview,
  livePreviewSelector,
  setEditedSpace,
} from './modules/edit';

export type {
  InteractiveMapType,
  LayerType,
  ImageLayerType,
  ExternalLayerType,
  SpaceType,
  LabelType,
  ModelType,
  CategoryType,
  VrType,
  FileType,
  ImageType,
  VisualType,
  Link,
} from './types';
