import { useAppSelector, apiErrorSelector } from '../store';

import { ReactComponent as VisrezLogo } from '../media/logos/visrez-logo-text-down.svg';

import './ErrorPage.scss';

const ErrorPage = () => {
  const error = useAppSelector(apiErrorSelector)!;

  return (
    <div id="error-page">
      <a href="https://www.visrez.com/">
        <VisrezLogo title="Visrez Logo" />
      </a>
      <p>{error}</p>
    </div>
  );
};

export default ErrorPage;
