import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useMap } from 'react-leaflet';
import {
  isFullscreenAvailable,
  requestFullscreen,
  exitFullscreen,
} from '../../utils';

import {
  useAppSelector,
  isEmbeddedSelector,
  mapUrlSelector,
} from '../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsMinimize,
  faArrowsMaximize,
} from '@fortawesome/pro-regular-svg-icons';

export function Maximize() {

  const map = useMap();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isEmbedded = useAppSelector(isEmbeddedSelector)
    || window.location !== window.parent.location;
  const mapUrl = useAppSelector(mapUrlSelector);

  const maximize = () => {
    if (isEmbedded) {
      window?.open(mapUrl, '_blank', 'noreferrer')?.focus();
      return;
    }
    const wrapperDiv = process.env.REACT_APP_USE_SHADOW_ROOT === 'true'
      ? document.getElementById('visrez-interactive')?.shadowRoot?.getElementById('wrapper')
      : document.getElementById('wrapper');

    isFullscreen
      ? exitFullscreen().then(() => setIsFullscreen(!isFullscreen))
      : requestFullscreen(wrapperDiv!).then(() => setIsFullscreen(!isFullscreen));
  }

  if (!isEmbedded && !isFullscreenAvailable()) {
    return null;
  }

  return createPortal(
    <div
      className="leaflet-bar leaflet-control"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}

    >
      <div className="map-controls">
        <a
          href="#0"
          role="button"
          className="button maximize"
          onClick={ (e) => {
            maximize()
            e.preventDefault()
          }}
        >
          <FontAwesomeIcon icon={ isFullscreen ? faArrowsMinimize : faArrowsMaximize } />
        </a>
      </div>
    </div>,
    // @ts-ignore
    map._controlCorners['topright']
  );
}
