import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import {
  CategoryType,
  useAppDispatch,
  updateCategory,
  allIcons,
} from '../../../store';

import './ChooseIcon.scss';

export function ChooseIcon(
  { category, closeMenu }: { category: CategoryType, closeMenu: ()=>void }
) {

  const dispatch = useAppDispatch();

  const commonIcons = [
    'bed',
    'bell-concierge',
    'briefcase',
    'champagne-glasses',
    'child',
    'dumbbell',
    'family',
    'house',
    'map-pin',
    'person-swimming',
    'person-walking',
    'plate-utensils',
    'square-chevron-down',
    'square-parking',
  ];

  const [customIcon, setCustomIcon] = useState<IconName|null>(
    commonIcons.includes(category.icon) ? null : category.icon as IconName
  );

  const changeCategoryIcon = (icon:string) => {
    if (category.icon !== icon) {
      dispatch(updateCategory({
        ...category,
        icon: icon,
      }));
    }
    closeMenu();
  }

  return (
    <div
      className='choose-icon-menu'
      onClick={(e) => e.stopPropagation()}
    >
      <p>Choose an icon</p>

      <div className='icons'>

        {
          commonIcons.map((name) => {
            return (
              <div
                key={name}
                className={ cx('icon', { 'selected': category.icon === name}) }
                onClick={ () => changeCategoryIcon(name) }
              >
                <FontAwesomeIcon
                  icon={['fas', name as IconName]}
                />
              </div>
            );
          })
        }
      </div>
      <div className='custom-icon'>
        <p>Or use a custom one</p>
        <div
          className={ cx('icon', { 'selected': category.icon === customIcon }) }
          onClick={ () => customIcon && changeCategoryIcon(customIcon) }
        >
          { customIcon && (
            <FontAwesomeIcon
              icon={['fas', customIcon]}
            />
          )}
        </div>
        <p>
          <input
            onChange={(e) => {
              if (!allIcons.includes(e.target.value)) {
                setCustomIcon(null)
              } else {
                setCustomIcon(e.target.value as IconName)
              }
            }}
            list='allIcons'
          />
          <datalist id='allIcons'>
            {
              allIcons.map((name) => (
                <option key={name} value={name}>{name}</option>
              ))
            }
          </datalist>
        </p>
      </div>
    </div>
  );

}
