import { v4 as uuidv4 } from 'uuid';

import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';

import {
  SortableContext,
  arrayMove,
} from '@dnd-kit/sortable';

import {
  FileType,
  ImageType,
} from '../../../store';

import { FileUploader } from './FileUploader';
import { ImagePreview } from './ImagePreview';

import './ImageUploader.scss';

const defaultAcceptedTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
]

export function ImageUploader({
  images,
  setImages,
  label,
  multiple,
  removable,
  imagePreviewStyles,
  maxSize,
  acceptedTypes,
} : {
  images:ImageType[],
  setImages:any,
  label:string,
  multiple:boolean,
  removable?: boolean,
  imagePreviewStyles?:React.CSSProperties,
  maxSize?:number,
  acceptedTypes?:string[],
}) {

  // To allow onClick events in Images
  const sensors = useSensors(
    useSensor(
      PointerSensor,
      { activationConstraint: { distance: 10 } }
    )
  );

  // Handle preview images drag & drop
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;

    if (active.id !== over.id) {
      const oldIndex = images.findIndex((image) => image.id === active.id);
      const newIndex = images.findIndex((image) => image.id === over.id);
      setImages(arrayMove(
        images, oldIndex, newIndex,
      ).map((img, i) => {
        return { ...img, position_order: i };
      }));
    }
  }

  const imagePreviews = images.map((image, index) => {
    return (
      <ImagePreview
        id={image.id}
        key={image.id}
        image={image}
        removable={removable}
        removeImage={() => {
          setImages(images.toSpliced(index, 1));
        }}
        sortable={multiple}
        imagePreviewStyles={imagePreviewStyles}
      />
    );
  });

  const previews = () => {
    if (multiple) {
      return (
        <DndContext
          sensors={sensors}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={images}
          >
            { imagePreviews }
          </SortableContext>
        </DndContext>
      );

    } else {
      return imagePreviews;
    }
  }

  return (
    <div className='image-uploader'>
      <FileUploader
        onFilesUpload={(files:FileType[]) => {
          // Assign random id to new uploaded images
          // so we can sort them
          const asImages = files.map((f) => {
            return {...f, id: uuidv4()} as ImageType;
          });
          if (multiple) {
            setImages(images.concat(asImages));
          } else {
            setImages(asImages);
          }
        }}
        label={label}
        multiple={multiple}
        maxSize={maxSize}
        acceptedTypes={acceptedTypes ? acceptedTypes : defaultAcceptedTypes}
      >
        <div className="images-container">
          { previews() }
        </div>
      </FileUploader>
    </div>
  );
}
