import { useState, useRef, createContext, useEffect } from 'react';
import L from 'leaflet';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';

import {
  useAppSelector,
  useAppDispatch,
  canEditSelector,
  orderDescriptionSelector,
  isEmbeddedSelector,
  mapSelector,
  saveState,
  loadState,
  replaceStore,
} from './store';

import eventBus from './eventBus';
import { Map } from './components/Map';
import { Modal } from './components/Modal';
import { Sidebar } from './components/Sidebar';
import { EditTools } from './components/EditTools';
import { Flash } from './components/Flash';

import './App.scss';
import './css/tooltip.scss';

export const apiBaseURL = process.env.REACT_APP_API_BASE_URL ?? 'https://clients.visrez.com';
export const interactiveURL = process.env.REACT_APP_INTERACTIVE_URL ?? 'https://interactive.visrez.com';

export interface LeafletContextType {
  mapRef: React.MutableRefObject<L.Map | null>;
  activeMarkerRef: React.MutableRefObject<Record<string, L.Marker>>;
  activeLayerRef: React.MutableRefObject<{
    object: L.TileLayer | L.ImageOverlay | undefined;
    id: number | null;
  }>;
}

export const LeafletContext = createContext<LeafletContextType>({} as LeafletContextType);

export default function App({
  componentLoaded,
}: {
  componentLoaded:()=>void,
}) {
  const dispatch = useAppDispatch();
  const iMap = useAppSelector(mapSelector);
  const showAdmin = useAppSelector(canEditSelector) && !isMobile;
  const activeMarkerRef = useRef({});
  const mapRef = useRef(null);
  const activeLayerRef = useRef({ object: undefined, id: null });

  const isEmbedded = useAppSelector(isEmbeddedSelector);
  const orderDescription = useAppSelector(orderDescriptionSelector);

  const [isSidebarOpen, setIsSidebarOpen] = useState(
    !isMobile && iMap.sidebarOpen
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!isEmbedded && orderDescription) {
    document.title = `Visrez Interactive - ${orderDescription}`;
  }

  // Draft recovery/discard functionality
  useEffect(() => {
    const stateID = `${iMap.order_id}-${iMap.id}`;
    const unsavedState = loadState(stateID);
    if (unsavedState) {
      const onConfirm = () => {
        dispatch(replaceStore(unsavedState));
        eventBus.emit('flash', 'success', 'Draft recovered');
      };
      const onCancel = () => {
        saveState(undefined, stateID);
        eventBus.emit('flash', 'success', 'Draft discarded');
      };
      eventBus.emit(
        'ask',
        'A draft has been found for this map, '
        + 'would you like to continue working on it?',
        'Recover',
        'Discard',
        onConfirm,
        onCancel,
      );
    }
    // Run on first load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Custom CSS from platform
  useEffect(() => {
    const customCssEl = process.env.REACT_APP_USE_SHADOW_ROOT === 'true'
      ? document.getElementById('visrez-interactive')!.shadowRoot!.getElementById('custom-css')
      : document.getElementById('custom-css');
      if (!customCssEl) {
        const styleTag = document.createElement('style');
        styleTag.id = 'custom-css';
        styleTag.textContent = `:host, #visrez-interactive { --brand: ${iMap.primaryColor}; --secondary-color: ${iMap.secondaryColor}; --background-color: ${iMap.bgColor}}`;
        if (iMap.customCss) {
          styleTag.textContent += iMap.customCss;
        }
        if (process.env.REACT_APP_USE_SHADOW_ROOT === 'true') {
          document.getElementById('visrez-interactive')!.shadowRoot!.appendChild(styleTag);
        } else {
          document.getElementById('visrez-interactive')!.appendChild(styleTag);
        }
      }
    // Run on first load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LeafletContext.Provider
      value={{
        mapRef,
        activeMarkerRef,
        activeLayerRef,
      }}
    >
      <div id="wrapper">
        { showAdmin && <EditTools setIsSidebarOpen={setIsSidebarOpen} /> }
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div
          id="content-wrapper"
          className={cx({'mobile': isMobile})}
        >
          <Flash />
          <Map
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            editTool=""
            componentLoaded={componentLoaded}
          />
          <Modal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
      </div>
    </LeafletContext.Provider>
  );
}
