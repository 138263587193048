import { useRef, useEffect } from 'react';
import L from 'leaflet';

import { HTMLMapOverlay } from '../../HTMLMapOverlay';

import { LabelType, SpaceType } from '../../../store';

import './EditMarker.scss';
import { EditLabel } from './EditLabel';
import { EditSpace } from './EditSpace';

type SaveRefType = { current: { callback: (() => void) | null } };

interface LabelMarker {
  type: 'label';
  value: LabelType;
}
interface SpaceMarker {
  type: 'space';
  value: SpaceType;
}

export interface EditMarkerProps {
  position: L.LatLngExpression;
  onSuccess: () => void;
  onClose: () => void;
  doSaveRef: SaveRefType;
  marker: SpaceMarker | LabelMarker;
}

export function EditMarker({ position, onSuccess, onClose, marker, doSaveRef }: EditMarkerProps) {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      L.DomEvent.disableClickPropagation(modalRef.current);
      L.DomEvent.disableScrollPropagation(modalRef.current);
    }
  }, []);

  return (
    <HTMLMapOverlay
      latlng={position}
      fitInBounds={true}
      onReady={() => {
        if (!modalRef.current) return;
        modalRef.current.show();
        setTimeout(() => {
          if (!modalRef.current) return;
          modalRef.current.focus();
        });
      }}
    >
      <dialog
        id="edit-marker"
        ref={modalRef}
        onKeyUp={(e) => {
          if (e.key === 'Escape' && modalRef.current) {
            modalRef.current.close();
            onClose();
          }
        }}
      >
        {marker.type === 'label' && (
          <EditLabel
            marker={marker.value}
            doSaveRef={doSaveRef}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        )}
        {marker.type === 'space' && (
          <EditSpace
            spaceId={marker.value.id}
            onClose={onClose}
          />
        )}
      </dialog>
    </HTMLMapOverlay>
  );
}
