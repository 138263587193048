import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import authFetch from '../../axios';

import {
  InteractiveMapType,
  RootState,
  ModelType,
  ImageType,
} from '../../store';

import eventBus from '../../eventBus';

// For using in builder.addMatcher
// Returns true if action returns API data for InteractiveMap
// so we can update redux on all this actions
export function hasMapResponse(action:PayloadAction) {
  return (
    action.type === 'api/fetchAPIData/fulfilled'
      || action.type === 'api/saveAPI/fulfilled'
  );
}
export type MapResponseAction = PayloadAction<InteractiveMapType>;

// Fetch order info
// Used to display property logo in Loader
export const fetchOrderInfo = createAsyncThunk(
  'api/fetchOrderInfo',
  async (payload: { orderId:string }, { rejectWithValue }:any): Promise<any> => {
    try {
      const { orderId } = payload;
      const response = await authFetch(`/api/orders/${orderId}/interactive_maps/info`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  },
);

export const fetchAPIData = createAsyncThunk(
  'api/fetchAPIData',
  async (payload: { orderId:string, mapId:string|undefined }, { rejectWithValue }: any): Promise<InteractiveMapType> => {
    try {
      const { orderId, mapId } = payload;
      const baseUrl = `/api/orders/${orderId}/interactive_maps`;
      if (mapId) {
        const response = await authFetch(`${baseUrl}/${mapId}`);
        return response.data;
      } else {
        // Use the first map for this order
        const response = await authFetch(baseUrl);
        return response.data[0];
      }
    } catch (error: any) {
      if (error.message === 'Request failed with status code 402') {
        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  },
);

export const fetchAPIVrData = createAsyncThunk(
  'api/fetchAPIVrData',
  async (payload: { orderId: string, vrId: string }, { rejectWithValue }: any): Promise<ModelType> => {
    try {
      const { orderId, vrId } = payload;
      const response = await authFetch(`/api/orders/${orderId}/vrs/${vrId}`);
      return response.data;
    } catch (error: any) {
      if (error.message === 'Request failed with status code 402') {
        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  },
);

export const saveAPI = createAsyncThunk(
  'api/saveAPI',
  async (_arg: void, { dispatch, getState }): Promise<InteractiveMapType> => {
    const state = getState() as RootState;

    const {
      undoable: {
        present: {
          api: { orderID },
          images: { list: images },
          spaces: { list: spaces, categories },
          labels: { list: labels },
        },
      },
      map,
    } = state;

    const response = await authFetch.put(`/api/orders/${orderID}/interactive_maps/${map.id}`, {
      interactive_map: {
        map: map,
        images: images,
        spaces: spaces,
        categories: categories,
        labels: labels,
      }
    });
    return response.data;
  },
);

export interface APIState {
  orderID: string | null;
  orderDescription: string | null;
  data: InteractiveMapType | null;
  loadStatus: 'idle' | 'loading' | 'success' | 'failed';
  saveStatus: 'idle' | 'unsaved' | 'saving' | 'success' | 'failed';
  error: string | null;
  savedAt: number;
}

const initialState: APIState = {
  orderID: null,
  orderDescription: null,
  data: null,
  loadStatus: 'idle',
  saveStatus: 'idle',
  error: null,
  savedAt: 0,
};

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAPIData.pending, (state) => ({ ...state, loadStatus: 'loading' }))
      .addCase(fetchAPIData.fulfilled, (state, { payload }) => ({
        ...state,
        loadStatus: 'success',
        data: payload,
        orderID: payload.order_id,
        orderDescription: payload.order_description,
        savedAt: 0,
      }))
      .addCase(fetchAPIData.rejected, (state, { payload, error }) => ({
        ...state,
        loadStatus: 'failed',
        error: (payload as string) || error.message || null,
      }));
    builder
      .addCase(fetchAPIVrData.pending, (state) => ({ ...state, loadStatus: 'loading' }))
      .addCase(fetchAPIVrData.fulfilled, (state, { payload }) => {
        state.data = {
          id: 0,
          order_id: '',
          order_description: '',
          markers: {} as ImageType,
          defaultMarkers: {} as ImageType,
          markersSize: '',
          default_image: 0,
          name: '',
          featured_video: '',
          groupBy: 'categories',
          sidebarOpen: true,
          firstItemOpen: true,
          allowLabels: true,
          categories: [],
          spaces: [],
          labels: [],
          images: [],
          models: [],
          current_user: payload.current_user,
          customCss: '',
          primaryColor: '',
          secondaryColor: '',
          bgColor: '',
        };
        state.orderID = payload.vr.order_id;
        state.orderDescription = payload.vr.order_description;
        state.loadStatus = 'success';
      })
      .addCase(fetchAPIVrData.rejected, (state, { payload, error }) => ({
        ...state,
        loadStatus: 'failed',
        error: (payload as string) || error.message || null,
      }));

    builder
      .addCase(saveAPI.pending, (state) => {
        state.saveStatus = 'saving';
      })
      .addCase(saveAPI.fulfilled, (state) => {
        state.saveStatus = 'success';
        state.savedAt = Date.now();
        eventBus.emit('flash', 'success', 'Changes saved.');
      })
      .addCase(saveAPI.rejected, (state, { error }) => {
        state.saveStatus = 'failed';
        state.error = error.message || null;
        eventBus.emit('flash', 'error', error.message || 'Could not save map.');
      });
  },
});

export const mapNeedsSaveSelector = (state: RootState) => {
  return (
    // We did undo some already saved changes
    state.undoable.future.filter((s) => s.api.savedAt > state.undoable.present.api.savedAt).length > 0 ||
    // or we made some changes not yet saved
    state.undoable.past.filter((s) => s.api.savedAt >= state.undoable.present.api.savedAt).length > 0 ||
    state.map.needsSave
  );
};

export const orderIdSelector = (state: RootState) => state.undoable.present.api.orderID;
export const orderDescriptionSelector = (state: RootState) => state.undoable.present.api.orderDescription;
export const apiErrorSelector = (state: RootState) => state.undoable.present.api.error;

export const apiLoadingStatusSelector = (state: RootState) => state.undoable.present.api.loadStatus;
export const apiSavingStatusSelector = (state: RootState) => state.undoable.present.api.saveStatus;

export default apiSlice.reducer;
