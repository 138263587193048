import { interactiveURL } from '../../../App';
import './LayoutButton.scss';
import cx from 'classnames';

export function LayoutButton({ name, active, onSelectLayout }) {
  return (
    <button
      className={cx('layout-button', { active: active })}
      onClick={() => onSelectLayout(name)}
      title={name}
    >
      <img
        src={`${interactiveURL}/icons/layouts/${name.toLowerCase()}.svg`}
        alt={name}
      />
    </button>
  );
}
