import { useRef } from 'react';

import {
  useAppSelector,
  mapSelector,
  orderIdSelector,
  activeImageSelector,
} from '../../../store';

import eventBus from '../../../eventBus';
import { interactiveURL } from '../../../App';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

import './DirectLink.scss';

export function DirectLink({ spaceId }: { spaceId:string }) {

  const iMap = useAppSelector(mapSelector);
  const orderID = useAppSelector(orderIdSelector);
  const activeImage = useAppSelector(activeImageSelector);
  const directLinkRef = useRef<HTMLPreElement>(null);
  const directPageLinkRef = useRef<HTMLPreElement>(null);
  const embedInstructionsRef = useRef<HTMLPreElement>(null);

  if (isNaN(parseInt(spaceId))) {
    return(
    <label className="direct-link">
      Share link
      <p>Save first, to get Share Link</p>
    </label>
    );
  }

  return (
    <>
      <label className="direct-link">
        Share marker
        <div className="copy">
          <FontAwesomeIcon
            icon={ faLink }
            onClick={() => {
              navigator.clipboard.writeText(directLinkRef.current!.textContent!);
              eventBus.emit('flash', 'success', 'Copied URL to clipboard!');
            }}
            title="Copy to clipboard"
          />
        </div>

        <pre ref={directLinkRef}>
          {`${interactiveURL}/${orderID}/map/${iMap.id}/layer/${activeImage?.id}/space/${spaceId}`}
        </pre>
      </label>
      <label className="direct-link">
        Share page
        <div className="copy">
          <FontAwesomeIcon
            icon={ faLink }
            onClick={() => {
              navigator.clipboard.writeText(directPageLinkRef.current!.textContent!);
              eventBus.emit('flash', 'success', 'Copied URL to clipboard!');
            }}
            title="Copy to clipboard"
          />
        </div>
        <pre ref={directPageLinkRef} >
          {`${interactiveURL}/${orderID}/map/${iMap.id}/layer/${activeImage?.id}/space/${spaceId}/open`}
        </pre>
      </label>
      <label className="embed-instructions">
        Embed Code
        <div className="copy">
          <FontAwesomeIcon
            icon={ faLink }
            onClick={(e) => {
              navigator.clipboard.writeText(embedInstructionsRef.current!.textContent!);
              eventBus.emit('flash', 'success', 'Copied Code to clipboard!');
              e.stopPropagation();
            }}
            title="Copy to clipboard"
          />
        </div>
        <pre
          ref={embedInstructionsRef}
          onClick={(e) => e.stopPropagation() }
          title=""
        >
          {
`<div
  id="visrez-interactive"
  data-client="${orderID}"
  data-map="${iMap.id}"
  data-layer="${activeImage?.id}"
  data-space="${spaceId}"
>
</div>`
          }
        </pre>
      </label>
    </>
  );
}
