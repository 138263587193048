import { useCallback, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faFloppyDisk,
  faRotateLeft,
  faRotateRight,
  IconDefinition,
  faCog,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { ActionCreators } from 'redux-undo';

import {
  toggleEdit,
  isEditingSelector,
  isEditingSettingsSelector,
  toggleEditingSettings,
  saveAPI,
  apiSavingStatusSelector,
  useAppDispatch,
  useAppSelector,
  canUndoSelector,
  canRedoSelector,
  mapNeedsSaveSelector,
  setEditedSpaceId,
} from '../../store';

import { LeafletContext } from '../../App';

import './EditTools.scss';

interface IconProps {
  icon: IconDefinition;
  id?: string;
  tooltip?: string;
  active?: boolean;
  pulse?: boolean;
  success?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

function Icon({ icon, id, tooltip, active, pulse, success, onClick, disabled }: IconProps) {
  return (
    <div
      id={id}
      onClick={() => !disabled && onClick && onClick()}
      className={cx('icon', { active, success, disabled })}
      {...(tooltip ? { 'data-tooltip': 'right' } : {})}
    >
      <FontAwesomeIcon
        icon={icon}
        size="xl"
        className={cx({ pulse })}
      />
      {tooltip && <span role="tooltip">{tooltip}</span>}
    </div>
  );
}

export function EditTools({ setIsSidebarOpen }: { setIsSidebarOpen:Function }) {
  const dispatch = useAppDispatch();
  const isEditing = useAppSelector(isEditingSelector);
  const isEditingSettings = useAppSelector(isEditingSettingsSelector);
  const savingStatus = useAppSelector(apiSavingStatusSelector);
  const canUndo = useAppSelector(canUndoSelector);
  const canRedo = useAppSelector(canRedoSelector);
  const needsSave = useAppSelector(mapNeedsSaveSelector);
  const canSave = needsSave && savingStatus !== 'saving';

  const { mapRef } = useContext(LeafletContext);

  const undo = useCallback(() => {
    dispatch(ActionCreators.undo());
    dispatch(setEditedSpaceId(null));
  }, [dispatch]);
  const redo = useCallback(() => {
    dispatch(ActionCreators.redo());
    dispatch(setEditedSpaceId(null));
  }, [dispatch]);

  useEffect(() => {
    const keyDown = (evt: Event) => {
      const { target } = evt;
      if (target instanceof HTMLElement) {
        if (target.className === 'rsw-ce') return;
        if (target instanceof HTMLInputElement) return;
        if (target instanceof HTMLTextAreaElement) return;
      }
      const e = evt as KeyboardEvent;
      if (e.ctrlKey) {
        if (e.key === 'z') {
          undo();
        }

        if (e.key === 'y' || e.key === 'Z') {
          redo();
        }

        if (e.key === 's') {
          e.preventDefault();
          if (canSave) dispatch(saveAPI());
        }
      }
    };

    if (process.env.REACT_APP_USE_SHADOW_ROOT === 'true') {
      document.getElementById('visrez-interactive')!.shadowRoot!
        .addEventListener('keydown', keyDown);
    } else {
      window.addEventListener('keydown', keyDown);
    }

    return () => {
    if (process.env.REACT_APP_USE_SHADOW_ROOT === 'true') {
      document.getElementById('visrez-interactive')!.shadowRoot!
        .removeEventListener('keydown', keyDown);
    } else {
      window.removeEventListener('keydown', keyDown);
    }

    };
  }, [dispatch, undo, redo, canSave]);

  return (
    <div
      id="edit-tools"
      className="edit-bar"
    >
      <nav>
        <ul>
          <li>
            <Icon
              icon={faPenToSquare}
              tooltip="Edit Mode"
              active={isEditing}
              onClick={() => {
                // Open sidebar when activating Edit mode
                if (!isEditing) setIsSidebarOpen(true);
                dispatch(toggleEdit());
                mapRef.current!.getContainer().focus();
              }}
            />
          </li>
          {isEditing && (
            <>
              <li>
                <Icon
                  icon={faRotateLeft}
                  tooltip="Undo [Ctrl+Z]"
                  active={false}
                  onClick={undo}
                  disabled={!canUndo || savingStatus === 'saving'}
                />
              </li>
              <li>
                <Icon
                  icon={faRotateRight}
                  tooltip="Redo [Ctrl+Y]"
                  active={false}
                  onClick={redo}
                  disabled={!canRedo || savingStatus === 'saving'}
                />
              </li>
              <li>
                <Icon
                  icon={faFloppyDisk}
                  tooltip={
                    savingStatus === 'saving'
                      ? 'Saving changes...'
                      : canSave
                        ? 'Save changes [Ctrl+S]'
                        : 'All changes saved'
                  }
                  active={false}
                  pulse={savingStatus === 'saving'}
                  success={savingStatus === 'success'}
                  onClick={() => dispatch(saveAPI())}
                  disabled={!canSave}
                />
              </li>
              <li className="edit-tools-settings">
                <Icon
                  icon={faCog}
                  tooltip="Settings"
                  active={isEditingSettings}
                  onClick={() => {
                    // Open sidebar when opening map settings
                    if (!isEditingSettings) setIsSidebarOpen(true);
                    dispatch(toggleEditingSettings());
                  }}
                  disabled={false}
                />
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}
