import cx from 'classnames';

import './ToggleButton.scss';

export function ToggleButton({
  onClick,
  selected,
  title,
}: {
  onClick:()=>void,
  selected:boolean,
  title?:string,
}) {

  return (
    <div
      className="toggle-container"
      onClick={ onClick }
      title={ title }
    >
      <div
        className={
          cx('dialog-button', { off: !selected  })
        }
      >
      </div>
    </div>
  );

}
