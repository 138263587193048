import { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/pro-solid-svg-icons';

import './SizesMenu.scss';

export function SizesMenu({ sizes, activeSize, onSelectSize }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <div id="sizes-menu">
          {
            // Sort sizes: Small first, Large last
            [...sizes]
              .sort((a, b) => {
                if (a === 'Small') return -1;
                if (b === 'Small') return 1;
                if (a === 'Large') return 1;
                if (b === 'Large') return -1;
                return a.localeCompare(b);
              })
              .map((size) => {
                return (
                  <div
                    key={size}
                    onClick={() => {
                      onSelectSize(size);
                      setIsOpen(false);
                    }}
                    className={cx({ active: activeSize === size })}
                  >
                    {size}
                  </div>
                );
              })
          }
        </div>
      )}
      <div
        className="vr-menu-button"
        onClick={() => setIsOpen(!isOpen)}
        data-tooltip="top"
      >
        <FontAwesomeIcon
          icon={faUserGroup}
          className="fa-xl"
        />
        <span role="tooltip">Capacity</span>
      </div>
    </>
  );
}
