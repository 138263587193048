export const loadState = (id: string) => {
  try {
    const serialState = localStorage.getItem(id);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any, id: string) => {
  try {
    if (!state) {
      localStorage.removeItem(id);
    } else {
      const serialState = JSON.stringify(state);
      localStorage.setItem(id, serialState);
    }
  } catch(err:any) {
    if (
      err.message === "The quota has been exceeded."
      && state
      && (state.past.length > 0 || state.future.length > 0)
    ) {
      // Try again discarding past & future states
      saveState({
        ...state,
        past: [],
        future: [],
      }, id);
    } else {
      console.error(err.message);
    }
  }
};
