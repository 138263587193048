import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import {
  useAppDispatch,
  activeSpaceSelector,
  livePreviewSelector,
  setLivePreview,
} from '../../store';

import { SidebarButton } from '../Sidebar';
import { ImageCarousel } from '../ImageCarousel';

import Vr from '../Vr';

import './Modal.scss';

export interface ModalProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export function Modal({ isSidebarOpen, setIsSidebarOpen, isModalVisible, setIsModalVisible }: ModalProps) {
  const dispatch = useAppDispatch();
  const closeModal = () => {
    setIsModalVisible(false);
    dispatch(setLivePreview(false));
  };
  const space = useSelector(activeSpaceSelector);
  const livePreview = useSelector(livePreviewSelector);
  const showModal = isModalVisible || livePreview;

  const [activeTab, setActiveTab] = useState('');
  const [buttons, setButtons] = useState<any>({});

  useEffect(() => {
    if (!space) {
      setIsModalVisible(false);
      return;
    }
    const tabs:any = {};
    if (space.vr_model || space.vr_iframe) {
      tabs.vr = 'Virtual Tour';
    }
    if (space.video || (space.carousel_images && space.carousel_images.length > 0)) {
      tabs.images = 'Media Gallery'
    }
    if (Object.keys(tabs)[0]) {
      if (!Object.keys(tabs).includes(activeTab)) {
        setActiveTab(Object.keys(tabs)[0]);
      }
      if (Object.keys(tabs).length > 1) {
        setButtons(tabs);
      } else {
        setButtons({});
      }
    } else {
      setIsModalVisible(false);
    }
  }, [space, setIsModalVisible, activeTab]);

  if (!space || !showModal) {
    return null;
  }

  return (
    <div
      className="modal"
      style={{ display: showModal ? 'block' : 'none' }}
    >
      <SidebarButton
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div className="modal__content">
        <div className="modal__info">
          <h1>{space.name}</h1>
          <p dangerouslySetInnerHTML={{ __html: space.description }} />
          {space.external_link ? (
            <a
              href={space.external_link}
              target="_blank"
              rel="noreferrer"
            >
              <button className="external-link-button">Learn More</button>
            </a>
          ) : (
            <span></span>
          )}
          { space.pdf && (
            <p>
              <a
                href={space.pdf.url || space.pdf.base64}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFilePdf} className="fa-xl" />
                { space.pdf.filename }
              </a>
            </p>
          )}
          <div className="tab-buttons">
            { Object.keys(buttons).map((button) => {
              return (
                <button
                  key={button}
                  className={cx({ active: activeTab === button })}
                  onClick={() => setActiveTab(button)}
                >
                  { buttons[button] }
                </button>
              );
            })}
          </div>
          <div className="close__modal">
            <span
              className="modal__back"
              onClick={closeModal}
            >
              <button>
                <FontAwesomeIcon icon={faMap} />
                Return to Map
              </button>
            </span>
          </div>
        </div>
        <div className="modal__media">
          {activeTab === 'vr' &&
            (space.vr_iframe ? (
              <iframe
                src={space.vr_iframe}
                width="100%"
                // @ts-ignore TS2322
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                title="iframe-vr"
              />
          ) : (
            <Vr />
          ))}
          {activeTab === 'images' && (
            <ImageCarousel
              images={space.carousel_images}
              video={space.video}
            />
          )}
        </div>
      </div>
      {space.pageFooter && (
        <div dangerouslySetInnerHTML={ { __html: space.pageFooter } } />
      )}
    </div>
  );
}
