import { useState, useEffect } from 'react';
import eventBus from '../eventBus';

import {
} from '../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import './Flash.scss';

export function Flash() {

  const [message, setMessage] = useState('');
  const [level, setLevel] = useState('');
  const [confirm, setConfirm] = useState<string>();
  const [onConfirm, setOnConfirm] = useState<()=>void>();
  const [cancel, setCancel] = useState<string>();
  const [onCancel, setOnCancel] = useState<()=>void>();

  const showMessage = (lev:string, txt:string) => {
    setMessage(txt);
    setLevel(lev);
    setConfirm(undefined);
    setCancel(undefined);
    setOnConfirm(undefined);
    setOnCancel(undefined);
    setTimeout(() => {
      setMessage('');
      setLevel('');
    }, 3000);
  };

  const askUser = (
    txt:string,
    confirm:string,
    cancel:string,
    onConfirm:()=>void,
    onCancel:()=>void,
  ) => {
    setMessage(txt);
    setLevel('ask');
    setConfirm(confirm);
    setCancel(cancel);
    setOnConfirm(() => onConfirm);
    setOnCancel(() => onCancel);
  };

  useEffect(() => {
    eventBus.on('flash', showMessage);
    eventBus.on('ask', askUser);

    return () => {
      eventBus.off('flash', showMessage);
      eventBus.off('ask', askUser);
    };
  }, []);

  if (!message || !level) {
    return null;
  }

  return (
    <div
      className={ `flash flash-${level}` }
    >
      <div
        className="close"
        onClick={() => {
          setMessage('');
          setLevel('');
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <p>{message}</p>
      { confirm && cancel && onConfirm && onCancel && (
        <p className="actions">
          <button
            className="cancel"
            onClick={onCancel}
          >
            {cancel}
          </button>
          <button
            className="confirm"
            onClick={onConfirm}
          >
            {confirm}
          </button>
        </p>
      )}
    </div>
  );
};
