import {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Editor from 'react-simple-wysiwyg';
import './react-simple-wysiwyg.scss';

import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import {
  activeSpaceSelector,
  addSpace,
  categoriesSelector,
  editedSpacePositionSelector,
  FileType,
  ImageType,
  livePreviewSelector,
  modelsSelector,
  notPlacedSpacesSelector,
  setActiveModel,
  setActiveSpace,
  setEditedSpacePosition,
  setLivePreview,
  setLivePreviewSpace,
  spacesSelector,
  SpaceType,
  toggleCategory,
  updateSpace,
  useAppDispatch,
  useAppSelector,
  VisualType,
} from '../../../store';

import { ToggleButton } from '../ToggleButton';

import { DropdownWithImage } from './DropdownWithImage';
import { ImageUploader } from './ImageUploader';
import { FileUploader } from './FileUploader';
import { ImagePreview } from './ImagePreview';
import { CoordinatesField } from "./CoordinatesField";
import { DirectLink } from "./DirectLink";

import './EditSpace.scss';

export interface EditSpaceProps {
  spaceId: string;
  onClose: () => void;
}

export function EditSpace({ spaceId, onClose }: EditSpaceProps) {
  const dispatch = useAppDispatch();

  const categories = useAppSelector(categoriesSelector);
  const models = useAppSelector(modelsSelector);
  const allSpaces = useAppSelector(spacesSelector);
  const activeSpace = useAppSelector(activeSpaceSelector);
  const form = useRef(null);
  const visualUrlRef = useRef<HTMLInputElement>(null);

  // Position for the space edited (used only for creating new ones)
  const editedSpacePosition = useAppSelector(editedSpacePositionSelector);

  // Spaces not placed in current image
  const notPlacedSpaces = useAppSelector(notPlacedSpacesSelector);

  // Empty new space with a temporary id
  const newSpace = useMemo(() => (
    {
      id: uuidv4(),
      carousel_images: [] as ImageType[],
    } as SpaceType
  ), []);

  // Space the edited marker is originally pointing to, if any
  const existingSpace = allSpaces.find((s) => s.id === spaceId);

  // Currently edited Space
  const [marker, setMarker] = useState(
    allSpaces.find((s) => s.id === spaceId) || newSpace
  );
  const setMarkerAndPreview = (id:string) => {
    const space = newSpace.id === id
      ? newSpace
      : allSpaces.find((s) => s.id === id);
    if (space) {
      setMarker(space);
      if (livePreview) dispatch(setLivePreviewSpace(space));
    }
  }

  // Description
  const [description, setDescription] = useState(marker.description);

  // Category for currently edited Space
  const [categoryName, setCategoryName] = useState(marker.category);

  // Model for currently edited Space
  const [model, setModel] = useState(
    models.find((m) => m.id === marker.model) || null
  );

  // Visuals for currently selected Model
  const [modelVisuals, setModelVisuals] = useState(model?.visuals || []);

  // Currently selected Visual, from model
  const [modelVisual, setModelVisual] = useState(
    modelVisuals.find((v) => v.id === marker.visual_id) || null
  );
  // Visual URL, only used to preview it
  const [visualURL, setVisualURL] = useState(marker.visual_url);

  // Visual Image, uploaded
  const [visualUploaded, setVisualUploaded] = useState<ImageType|null>(marker.visual_uploaded);

  // New Carousel Images, to be uploaded
  const [carouselImages, setCarouselImages] = useState<ImageType[]>(marker.carousel_images);

  const [pdf, setPdf] = useState<FileType|null>(marker.pdf);

  // Track which dropdown is open
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  // Get space, updated with form changes
  // (changes not yet commited to redux)
  const getUpdatedSpace = useCallback(
    () => {
      if (!form.current) return newSpace;
      const formData = new FormData(form.current);

      const formValues = {
        id: marker.id,
        model: model?.id,
        vr_model: model?.vr?.id,
        visual_id: modelVisual?.id,
        visual_uploaded: visualUploaded,
        carousel_images: carouselImages,
        pdf: pdf,
        category: categoryName,
        description: description,
        ...Object.fromEntries(formData.entries()),
      } as SpaceType;

      if (modelVisual) {
        formValues.visual = modelVisual.thumbnail;
      } else if (visualUploaded) {
        formValues.visual = visualUploaded.thumb;
      } else {
        formValues.visual = formValues.visual_url;
      }
      return formValues;
    },
    [visualUploaded, carouselImages, pdf, model, marker, modelVisual, newSpace, categoryName, description]
  );

  // Live Preview
  const livePreview = useAppSelector(livePreviewSelector);
  const toggleLivePreview = () => {
    if (livePreview) {
      dispatch(setActiveSpace(null));
    } else {
      dispatch(setLivePreviewSpace(getUpdatedSpace()));
    }
    dispatch(setLivePreview(!livePreview));
  }
  // On form change, update livePreview
  const onFormChange = useCallback(
    () => {
      if (livePreview) {
        dispatch(setLivePreviewSpace(getUpdatedSpace()));
      }
    },
    [dispatch, getUpdatedSpace, livePreview]
  );
  // Update activeModel for live preview
  useEffect(() => {
    if (livePreview) dispatch(setActiveModel(model?.id));
  }, [dispatch, livePreview, model]);
  // Update carouselImages for live preview
  useEffect(() => {
    onFormChange();
  }, [carouselImages, onFormChange]);

  // Update marker when spaceId changes (clicked on another marker)
  useEffect(() => {
    setMarker(allSpaces.find((s) => s.id === spaceId) || newSpace);
  }, [spaceId, allSpaces, newSpace]);

  // Update selected model and visual when edited space changes
  useEffect(() => {
    const spaceModel = models.find((m) => m.id === marker.model) || null;
    setModel(spaceModel);
    setCategoryName(marker.category);
    setCarouselImages(marker.carousel_images);
  }, [marker, models]);

  // Update visuals and selected visual when selected model changes
  useEffect(() => {
    if (model && model.visuals.length > 0) {
      // Update visuals for selected model
      setModelVisuals(model.visuals);
      // Find selected visual by id
      const visualById = model.visuals.find(
        (v) => v.id === marker.visual_id
      );
      if (visualById) {
        setModelVisual(visualById);
        setVisualURL(visualById.thumbnail);
      } else {
        // Find matching visual by URL
        if (marker.visual_url) {
          const visualByUrl = model.visuals.find(
            (v) => v.thumbnail === marker.visual_url
          );
          setModelVisual(visualByUrl || null);
          setVisualURL(visualByUrl?.thumbnail || marker.visual_url);
        } else {
          setModelVisual(null);
          setVisualURL('');
        }
      }
    } else {
      setModelVisuals([]);
      setModelVisual(null);
      setVisualURL(marker.visual_url);
    }
  }, [model, marker.visual_url, marker.visual_id]);

   // Update Space in redux store
  const save = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formValues = getUpdatedSpace();

    // Update (or create) the Space
    dispatch(updateSpace(formValues));

    if (existingSpace && existingSpace.id !== marker.id) {
      // Change existing marker to point another Space
      dispatch(addSpace({
        space: existingSpace.id,
        newSpace: marker.id,
      }));
    } else if (spaceId === 'newSpace') {
      // Add a new marker
      dispatch(addSpace({ space: marker.id, lat: editedSpacePosition?.lat, lng: editedSpacePosition?.lng }));
    }

    const category = categories.find((c) => c.name === categoryName);
    if (category && !category.opened) {
      // Open category, so new space shows on the map
      dispatch(toggleCategory(category));
    }

    if (activeSpace?.id === marker.id) {
      // Editing opened space, make Vr work
      dispatch(setActiveModel(model?.id));
    }

    dispatch(setLivePreviewSpace(null));
    dispatch(setEditedSpacePosition(null));
    onClose();
  };

  const onCancel = () => {
    dispatch(setLivePreviewSpace(null));
    dispatch(setEditedSpacePosition(null));
    onClose();
  }

  return (
    <form
      id='edit-space'
      key={marker.id}
      ref={form}
      method="dialog"
      onSubmit={save}
      onChange={onFormChange}
    >
      <label id="live-preview">
        Live preview
        <ToggleButton
          onClick={toggleLivePreview}
          selected={livePreview}
          title="Live Preview"
        />
      </label>
      {notPlacedSpaces.length > 0 && (
        <label>
          Action:
          <select
            id="space-id"
            defaultValue={marker.id}
            onChange={(e) => setMarkerAndPreview(e.target.value)}
          >
            <option
              value={newSpace.id}
              key={newSpace.id}
            >
              Create new space
            </option>

            <optgroup label="Use existing">
              {existingSpace && (
                <option
                  value={existingSpace.id}
                  key={existingSpace.id}
                >
                  Use {existingSpace.name}
                </option>
              )}
              {notPlacedSpaces.map((s) => {
                return (
                  <option
                    value={s.id}
                    key={s.id}
                  >
                    Use {s.name}
                  </option>
                );
              })}
            </optgroup>
          </select>
        </label>
      )}

      <Tabs forceRenderTabPanel={true}>

        <TabList>
          <Tab>General</Tab>
          <Tab>Media</Tab>
          <Tab>Sharing</Tab>
        </TabList>

        <TabPanel>
          <label>
            <span className="required" title="Required field">
              Name
            </span>
            <input
              name='name'
              defaultValue={marker.name}
              required={true}
            />
          </label>

          <label id="edit-space-categories-combobox">
            <span className="required" title="Required field">
              Category
            </span>
            <input
              defaultValue={marker.category}
              list='categoriesList'
              required={true}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <datalist id='categoriesList'>
              {categories.map((c) => (
                <option key={c.name} value={c.name}>{c.name}</option>
              ))}
            </datalist>
          </label>

          <CoordinatesField />

          <label>
            Description
          </label>
          <Editor
            containerProps={{ style: { resize: 'vertical' } }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <label>
            External Link
            <input
              name='external_link'
              defaultValue={marker.external_link}
            />
          </label>
        </TabPanel>

        <TabPanel>
          {models.length > 0 && (
            <label>
              Model
              <DropdownWithImage
                id='models'
                list={models}
                isOpen={openDropdown === 'models'}
                setIsOpen={setOpenDropdown}
                selected={model}
                onClick={setModel}
                itemExtraClass={(m: any) => (m.vr.id ? 'with-vr' : '')}
              />
            </label>
          )}

          <label>
            Menu Image
            {modelVisuals.length > 0 && (
              <DropdownWithImage
                id='visuals'
                list={modelVisuals}
                isOpen={openDropdown === 'visuals'}
                setIsOpen={setOpenDropdown}
                selected={modelVisual}
                onClick={(e: VisualType) => {
                  setModelVisual(e);
                  setVisualURL(e?.thumbnail || marker.visual_url);
                }}
              />
            )}
            {!modelVisual && (
              <input
                ref={visualUrlRef}
                name='visual_url'
                defaultValue={marker.visual_url}
                onBlur={(e) => setVisualURL(e.target.value)}
                placeholder="Enter image URL or upload an image below"
                className={cx({ 'with-top-margin': modelVisuals.length > 0 })}
              />
            )}
          </label>

          {!modelVisual && (
            <>
              {visualURL && (
                <div className="image-uploader">
                  <div className="images-container">
                    <ImagePreview
                      id="visual"
                      key="visual"
                      image={{ thumb: visualURL, filename: 'Menu image' } as ImageType}
                      removeImage={() => {
                        if (visualUrlRef.current) {
                          visualUrlRef.current.value = '';
                        }
                        setVisualURL('');
                        setVisualUploaded(null);
                      }}
                      sortable={false}
                    />
                  </div>
                </div>
              )}
              {!visualURL && (
                <ImageUploader
                  label=""
                  images={visualUploaded ? [visualUploaded] : []}
                  setImages={(images: ImageType[]) => setVisualUploaded(images[0])}
                  multiple={false}
                />
              )}
            </>
          )}

          <ImageUploader
            images={carouselImages}
            setImages={setCarouselImages}
            label="Image carousel"
            multiple={true}
          />

          <FileUploader
            onFilesUpload={
              (pdfs:FileType[]) => setPdf(pdfs[0])
            }
            label="PDF"
            multiple={false}
            acceptedTypes={['application/pdf']}
          >
            { pdf && (
              <div className="file-list">
                {pdf.filename}
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => setPdf(null)}
                />
              </div>
            )}
          </FileUploader>

          <label>
            Video
            <input
              name='video'
              defaultValue={marker.video}
            />
          </label>

          <label>
            Virtual Tour URL
            <input
              name='vr_iframe'
              defaultValue={marker.vr_iframe}
            />
          </label>
        </TabPanel>

        <TabPanel>
          <DirectLink
            spaceId={ spaceId }
          />
        </TabPanel>
      </Tabs>

      <div className="form-buttons">
        <input
          type="button"
          value="Cancel"
          onClick={onCancel}
        />
        <input
          type="submit"
          value="Ok"
          // Handled on form: onSubmit={save}
        />
      </div>
    </form>
  );
}
