import { useSortable } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import {
  ImageType,
} from '../../../store';

export function ImagePreview({
  id,
  image,
  removeImage,
  sortable,
  removable,
  imagePreviewStyles,
}: {
  id: string;
  image: ImageType;
  removeImage: ()=>void;
  sortable: boolean;
  removable?: boolean;
  imagePreviewStyles?: React.CSSProperties,
}) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  if (!sortable) {
    return (
    <div
      className="image-preview"
    >
      {removable !== false &&
        <FontAwesomeIcon
          icon={faTrash}
          onClickCapture={(e) => {
            e.preventDefault();
            removeImage();
          }}
        />}
      <img
        src={ image.base64 ? image.base64 : image.thumb }
        alt={ image.filename }
        style={imagePreviewStyles}
      />
    </div>
    );
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="image-preview sortable"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <FontAwesomeIcon
        icon={faTrash}
        onClickCapture={(e) => {
          e.preventDefault();
          removeImage();
        }}
      />
      <img
        src={ image.base64 ? image.base64 : image.thumb }
        alt={ image.filename }
      />
    </div>
  );
}
