import { useContext, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L, {CRS} from 'leaflet';
import cx from 'classnames';

import {
  imageBounds,
  imageBoundsBig,
  ImageLayerType,
} from '../../../store';

import { LeafletContext } from '../../../App';
import { minZoom, maxZoom } from '../../Map';

import './Layer.scss';

type LayerProps = {
  image: ImageLayerType;
  isActive: boolean;
  setActive: () => void;
  children?: JSX.Element,
};

export function ImageLayer(props: LayerProps) {
  const map = useMap();
  const { image, isActive, setActive, children } = props;

  const { activeLayerRef } = useContext(LeafletContext);

  useEffect(() => {
    if (!map || !isActive) return;
    // do not replace layer if it's the same
    if (activeLayerRef.current.id === image.id) return;

    // If Map has another Layer loaded
    if (activeLayerRef.current.object) {
      // remove it from Map
      map.removeLayer(activeLayerRef.current.object);
      activeLayerRef.current.object = undefined;
      activeLayerRef.current.id = null;
    }

    // Add layer for active image to Map
    map.options.crs = CRS.Simple;
    map.setMaxZoom(maxZoom);
    map.setMinZoom(minZoom);
    map.setZoom(minZoom);

    const bounds = imageBounds(map, image);

    if (image.tiles) {
      // Image, tiled
      activeLayerRef.current.object = L.tileLayer(
        image.tiles,
        { minZoom, maxZoom, bounds }
      );
    } else {
      // Image, not tiled
      if (isActive) {
        activeLayerRef.current.object = L.imageOverlay(
          image.fullImage.original,
          bounds,
        )
      }
    }
    activeLayerRef.current.id = image.id;
    map.addLayer(activeLayerRef.current.object!);
    map.setMaxBounds(imageBoundsBig(map,image));
    setTimeout(() => {
      // map.setView(bounds.getCenter(), minZoom);
      map.fitBounds(bounds);
    }, 100);
  }, [map, image, activeLayerRef, isActive]);

  return (
    <label
      className="image"
      onClick={() => {
        if (!isActive) {
          setActive();
        } else {
          // Used when clicking on already active layer
          // Center map on its original position
          // map.setView(imageBounds(map, image).getCenter(), minZoom);
          map.fitBounds(imageBounds(map, image));
        }
      }}
    >
      <figure className={cx({ checked: isActive })}>
        <img
          src={image.fullImage.thumb}
          alt={image.name}
        />
        <figcaption>{image.name}</figcaption>
        {children}
      </figure>
    </label>
  );
}

