import {
  useRef,
  useState,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';

import {
  useAppDispatch,
  useAppSelector,
  toggleEditingSettings,
  mapSelector,
  linkedLayersSelector,
  setMapSettings,
  ImageType,
} from '../../../store';

import { EditMarkersIcon } from './EditMarkersIcon';

import './EditSettings.scss';

export function EditSettings() {
  const dispatch = useAppDispatch();
  const iMap = useAppSelector(mapSelector);
  const form = useRef(null);
  const linkedLayers = useAppSelector(linkedLayersSelector);

  // Name for firstItemOpen label and title
  const [groupByName, setGroupByName] = useState(
    iMap.groupBy === 'categories' ? 'Category' : 'Floor or Level'
  );

  // Space markers icon and size
  const [markers, setMarkers] = useState<ImageType>(iMap.markers);
  const [markersSize, setMarkersSize] = useState(iMap.markersSize);

  const save = (e:React.FormEvent<HTMLFormElement>) => {
    if (!form.current) return;
    const formData = new FormData(form.current);
    const formValues = Object.fromEntries(formData.entries());
    const mapSettings = {
      sidebarOpen: formValues.sidebarOpen === 'true',
      firstItemOpen: formValues.firstItemOpen === 'true',
      allowLabels: formValues.allowLabels === 'true',
      groupBy: formValues.groupBy || "categories",
      featured_video: formValues.featured_video,
      markers: markers,
      markersSize: markersSize,
    }
    dispatch(setMapSettings(mapSettings));
    dispatch(toggleEditingSettings());
  };

  return (
    <form
      id="edit-map-settings"
      ref={form}
      method="dialog"
      onSubmit={save}
    >
      <section className="edit-settings">
        <header>
          <button
            onClick={() => dispatch(toggleEditingSettings())}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="xl"
            />
          </button>
          <h3>Settings</h3>
        </header>
        <ul>
          { linkedLayers.length > 1 && (
            <li
              title="Show Spaces by Category or by Floors / Levels"
            >
              <label>
                <input
                  name="groupBy"
                  type="radio"
                  value="categories"
                  defaultChecked={iMap.groupBy === 'categories'}
                  onChange={() => setGroupByName('Category')}
                />
                Categories
              </label>
              <label>
                <input
                  name="groupBy"
                  type="radio"
                  value="layers"
                  defaultChecked={iMap.groupBy === 'layers'}
                  onChange={() => setGroupByName('Floor')}
                />
                Floors or Levels
              </label>
            </li>
          )}
          <li
            title="Load Map with sidebar open by default"
          >
            <label>
              <input
                name="sidebarOpen"
                type="checkbox"
                value="true"
                defaultChecked={iMap.sidebarOpen}
              />
              <span>Sidebar open</span>
            </label>
          </li>
          <li
            title={ `Load Map with the first ${groupByName} open by default` }
          >
            <label>
              <input
                name="firstItemOpen"
                type="checkbox"
                value="true"
                defaultChecked={iMap.firstItemOpen}
              />
              <span>First {groupByName} open</span>
            </label>
          </li>
          <li
            title="Locations are map markers with a name only"
          >
            <label>
              <input
                name="allowLabels"
                type="checkbox"
                value="true"
                defaultChecked={iMap.allowLabels}
              />
              <span>Enable Locations</span>
            </label>
          </li>
          <li
            className="video"
            title="This video will be displayed at the top of the sidebar."
          >
            <label>
              <span>Featured Video URL</span>
              <input
                name="featured_video"
                type="text"
                defaultValue={iMap.featured_video}
              />
            </label>
          </li>
          <EditMarkersIcon
            markers={markers}
            markersSize={markersSize}
            setMarkers={setMarkers}
            setMarkersSize={setMarkersSize}
          />
        </ul>
        <footer>
          <input
            type="button"
            value="Cancel"
            onClick={() => {
              dispatch(toggleEditingSettings());
            }}
          />
          <input
            type="submit"
            value="Ok"
            // Handled on form: onSubmit={save}
          />
        </footer>
      </section>
    </form>
  );
}
