import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSunBright } from '@fortawesome/pro-solid-svg-icons';

export function LightsMenu({ lights, activeLight, onSelectLight }) {

  return (
    <div
      className="vr-menu-button"
      data-tooltip="top"
      onClick={() => {
        onSelectLight(lights.find((l) => l !== activeLight));
      }}
    >
      <FontAwesomeIcon
        icon={activeLight === 'Day' ? faMoon : faSunBright}
        transform={{ rotate: -20 }}
        className="fa-xl"
      />
      <span role="tooltip">
        {activeLight === 'Day' ? 'Night view' : 'Day view'}
      </span>
    </div>
  );
}
