import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import {
  useAppDispatch,
  isEditingSelector,
  useAppSelector,
  enableLayer,
  disabledImagesSelector,
} from '../../../store';

import { EditLayer } from './EditLayer';

export function AddableLayers() {
  const dispatch = useAppDispatch();

  const isEditing = useAppSelector(isEditingSelector);
  const disabledImages = useAppSelector(disabledImagesSelector);

  return isEditing && disabledImages.map((image) => (
    <span
      key={image.id}
      className="add-layer"
      onClickCapture={(e) => {
        dispatch(enableLayer(image.id));
        e.stopPropagation();
      }}
    >
      <FontAwesomeIcon
        icon={faPlus}
      />
      <EditLayer
        image={image}
        ghost={true}
        setActive={() => {}}
        isActive={false}
        disable={() => {}}
      />
    </span>
  ));
}
