import { useSelector } from 'react-redux';
import {
  orderIdSelector,
  useAppDispatch,
  saveModelsVR,
  vrNeedsSaveSelector,
} from '../../../store';

import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

import { apiBaseURL } from '../../../App';
import { LayoutButton } from './LayoutButton';
import './AdminMenu.scss';

export function AdminMenu({
  layouts,
  activeLayout,
  onSelectLayout,
  views,
  activeView,
  setActiveView,
  defaultView,
  setDefaultView,
  links,
  setHoveredLinkID,
  onDragLink,
  modelID,
}) {
  const dispatch = useAppDispatch();
  const orderID = useSelector(orderIdSelector);
  const needsSave = useSelector(vrNeedsSaveSelector);

  const isActiveView = (viewId) => {
    return activeView === viewId;
  };

  const linkIsPlaced = (viewID) => {
    return !!links[viewID];
  };

  return (
    <div id="vr-admin">
      {layouts.length > 1 && (
        <div id="vr-admin-layouts">
          {layouts.map((name) => {
            return (
              <LayoutButton
                key={name}
                name={name}
                active={name === activeLayout}
                onSelectLayout={onSelectLayout}
              />
            );
          })}
        </div>
      )}
      <div id="vr-admin-title">
        <a
          href={`${apiBaseURL}/${orderID}/models/${modelID}`}
          title="Open in platform"
          target="_blank"
          rel="noreferrer"
        >
          {views.find((v) => v.id === activeView)?.title}
          <sup>
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </sup>
        </a>
      </div>
      <div id="vr-admin-main">
        { views.length > 1 && views.map((view) => {
          return (
            <div
              key={view.id}
              className="vr-admin-button"
              title={`${view.title} (${view.id})`}
            >
              <button
                className={cx({ placed: linkIsPlaced(view.id), active: isActiveView(view.id) })}
                // highlight this link on click
                onClick={() => linkIsPlaced(view.id) && setHoveredLinkID(view.id)}
                onDoubleClick={() => setActiveView(view.id) }
              >
                <img
                  src="/icons/link.png"
                  alt="link"
                  draggable={!linkIsPlaced(view.id) && !isActiveView(view.id)}
                  onDragEnd={() => onDragLink(view.id, view.title)}
                />
              </button>
              <p>{view.name}</p>
            </div>
          );
        })}
      </div>
      <div id="vr-admin-bottom">
        {defaultView !== activeView && <button onClick={() => setDefaultView(activeView)}>Set as default view</button>}
        {needsSave && (
          <button
            onClick={() => dispatch(saveModelsVR())}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
