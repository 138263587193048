import { useState, useRef } from 'react';
import L from 'leaflet';
import { CircleMarker, Tooltip, useMap } from 'react-leaflet';

import {
  useAppSelector,
  useAppDispatch,
  labelsSelector,
  labelPositionsSelector,
  isEditingSelector,
  moveLabel,
} from '../../store';

export function Labels({
  setEditedMarker,
  editedMarker,
}: {
  setEditedMarker: Function;
  editedMarker: { type: string; id: string } | null;
}) {
  const dispatch = useAppDispatch();
  const isEditing = useAppSelector(isEditingSelector);
  const labels = useAppSelector(labelsSelector);
  const labelPositions = useAppSelector(labelPositionsSelector);

  const [hoveredMarker, setHoveredMarker] = useState('');
  const draggingLabel = useRef<L.CircleMarker | null>(null);
  const map = useMap();
  const moveCircleMarker = (e: L.LeafletMouseEvent) => {
    if (draggingLabel.current) {
      // This makes movement chunky
      // draggingLabel.current.setLatLng(e.latlng);
      // This is smoother
      draggingLabel.current.setLatLng(map.mouseEventToLatLng(e.originalEvent));
    }
  };

  return (
    <>
      {labels.map(
        (label) =>
          labelPositions[label.id] && (
            <CircleMarker
              key={label.id}
              center={[labelPositions[label.id].x, labelPositions[label.id].y]}
              pathOptions={{ color: 'red' }}
              radius={hoveredMarker === label.id ? 5 : 3}
              bubblingMouseEvents={false}
              eventHandlers={{
                click: (e) => {
                  // Seems setting bubblingMouseEvents to false is not enough
                  L.DomEvent.stopPropagation(e);
                },
                mouseup: (e) => {
                  if (!isEditing) return;
                  if (draggingLabel.current) {
                    dispatch(moveLabel({ id: label.id, x: e.latlng.lat, y: e.latlng.lng }));
                    draggingLabel.current = null;
                    map.off('mousemove', moveCircleMarker);
                    map.dragging.enable();
                  } else {
                    if (editedMarker) {
                      setEditedMarker(null);
                    } else {
                      setEditedMarker({ type: 'label', id: label.id });
                    }
                  }
                },
                mousemove: (e) => {
                  if (!isEditing || e.originalEvent.buttons !== 1) return;
                  if (!draggingLabel.current) draggingLabel.current = e.target;
                  map.dragging.disable();
                  map.on('mousemove', moveCircleMarker);
                },
                mouseover: (e) => !draggingLabel.current && setHoveredMarker(label.id),
                mouseout: (e) => !draggingLabel.current && setHoveredMarker(''),
              }}
            >
              <Tooltip permanent>{label.name}</Tooltip>
            </CircleMarker>
          ),
      )}
    </>
  );
}
