import {
  useAppSelector,
  mapSelector,
} from '../../../store';

import './Video.scss';

export default function Video() {
  const iMap = useAppSelector(mapSelector);

  if (iMap.featured_video === '') {
    return null;
  }

  return (
    <iframe
      src={iMap.featured_video}
      frameBorder="0"
      // @ts-ignore TS2322
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      allow="fullscreen"
      title="featured video"
    />
  );
}
