import { useLoaderData } from "react-router-dom";
import {
  useState,
  useMemo,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import {
  useAppSelector,
  useAppDispatch,
  mapSelector,
  activeImageSelector,
  categoriesSelector,
  isEditingSelector,
  spacesSelector,
  spacePositionsSelector,
  spacePositionsInAllLinkedLayersSelector,
  reorderCategories,
  reorderSpaces,
  CategoryType,
  SpaceType,
  toggleCategory,
} from '../../../store';

import {
  DndContext,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  MouseSensor as DndMouseSensor,
  DragOverlay,
  pointerWithin,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers';

import { CategoriesListItem } from './CategoriesListItem';
import { SpaceListItem } from '../SpaceList/SpaceListItem';

import './CategoriesList.scss';

export function CategoriesList() {
  const {
    spaceId,
    openCategory,
  } = useLoaderData() as {
    spaceId:string,
    openCategory:string,
  };
  const dispatch = useAppDispatch();
  const iMap = useAppSelector(mapSelector);
  const isEditing = useAppSelector(isEditingSelector);
  const categories = useAppSelector(categoriesSelector);

  const allSpaces = useAppSelector(spacesSelector);

  // dup allSpaces so we can reorder them without dispatching to redux
  const [spaces, setSpaces] = useState([...allSpaces]);

  // track changes to spaces order
  // so we know if we need to dispatch to redux
  const spacePositions = useAppSelector(spacePositionsSelector);
  useEffect(() => {
    setSpaces([...allSpaces]);
  }, [allSpaces]);

  const spacePositionsInAllLinkedLayers = useAppSelector(spacePositionsInAllLinkedLayersSelector);

  // Spaces that have been positioned and grouped by category
  const positionedSpaces = useMemo(
    () => {
      return categories.reduce((res, category) => {
        res[category.id] = spaces.filter((s) => {
          // On edit mode, show spaces for the current active image
          // If we show spaces for all linked layers, by clicking the
          // trash icon, we don't know which image to remove the space from.
          const positionedSpacesIds = Object.keys(
            isEditing ? spacePositions : spacePositionsInAllLinkedLayers
          );
          // spaces for this category
          return s.category_id === category.id
          // and positioned in current image
          && positionedSpacesIds.includes(s.id.toString());
        });
        return res;
      }, {} as { [categoryId: string]: SpaceType[] });
    },
    [categories, spaces, spacePositions, spacePositionsInAllLinkedLayers, isEditing]
  );

  // Categories with at least one positioned Space
  const categoriesWithSpace = useMemo(() => {
    return categories.filter((cat) => positionedSpaces[cat.id].length > 0);
  }, [categories, positionedSpaces]);

  const activeImage = useAppSelector(activeImageSelector);
  useEffect(() => {
    if (categoriesWithSpace.length === 0) return;
    if (spaceId) {
      // open the category for spaceId
      const catId = spaces.find((s) => s.id === spaceId)?.category_id;
      const cat = categoriesWithSpace.find((c) => c.id === catId);
      if (cat) {
        if (!cat.opened) dispatch(toggleCategory(cat));
        return;
      }
    }
    if (iMap.firstItemOpen || openCategory) {
      // open category passed in params (or first) when all are closed
      if (categoriesWithSpace.filter((c) => c.opened).length === 0) {
        if (openCategory) {
          const cat = categories.find((c) => c.id === openCategory);
          if (cat) dispatch(toggleCategory(cat));
        } else {
          dispatch(toggleCategory(categoriesWithSpace[0]));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage]);

  // category we are dragging
  const [dragCategory, setDragCategory] = useState<CategoryType | null>(null);
  // space we are dragging
  const [dragSpace, setDragSpace] = useState<SpaceType | null>(null);

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'category') {
      setDragCategory(event.active.data.current.category);
      return;
    }
    if (event.active.data.current?.type === 'space') {
      setDragSpace(event.active.data.current.space);
      return;
    }
  }

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (dragSpace) {
      // Detect if we really changed spaces
      // we may have dropped it in the start position
      spaces.every((space, index) => {
        const spacesAreEqual = (
          space.category === allSpaces[index].category
            && space.id === allSpaces[index].id
        );
        if (spacesAreEqual) {
          return true;
        } else {
          dispatch(reorderSpaces(spaces));
          return false; // stops every() loop
        }
      });
    }
    setDragCategory(null);
    setDragSpace(null);

    if (!over) return;
    if (!active.data.current || !over.data.current) return;

    // Dragged a space
    if (active.data.current.type === 'space') {
      // Over a category
      if (over.data.current.type === 'category') {
        const overCategory = categories.find((cat) => cat.id === over.id);
        // dropped space on a closed category
        if (overCategory && !overCategory.opened) {
          dispatch(toggleCategory(overCategory));
        }
      }
    }
    if (active.data.current.type !== 'category') return;
    if (over.data.current.type !== 'category') return;
    if (active.id === over.id) return;

    dispatch(reorderCategories({
      source: categories.findIndex((c) => c.id === active.id),
      destination: categories.findIndex((c) => c.id === over.id)
    }));
  }

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id === over.id) return;
    if (!active.data.current || !over.data.current) return;
    if (active.data.current.type === 'category') return;

    const activeIndex = spaces.findIndex((s) => s.id === active.id);

    // Dragging a space over another space
    if (over.data.current.type === 'space') {
      const overCategoryId = over.data.current.space.category_id;
      const overIndex = spaces.findIndex((s) => s.id === over.id);
      // Update category if it changed
      if (spaces[activeIndex].category_id !== overCategoryId) {
        spaces[activeIndex] = { ...spaces[activeIndex], category_id: overCategoryId };
      }
      setSpaces(
        arrayMove(spaces, activeIndex, overIndex)
      );

    } else { // Dragging a space over a category
      const overCategory = over.data.current.category;
      if (spaces[activeIndex].category_id !== overCategory.id) {
        const overIndex = spaces.findIndex((s) => s.category_id === overCategory.id);
        spaces[activeIndex] = { ...spaces[activeIndex], category_id: overCategory.id, category: overCategory.name }
        setSpaces(
          arrayMove(spaces, activeIndex, overIndex > 0 ? overIndex : 0)
        );
      }
    }
  };

  // Custom Sensor: prevents dragging while selecting text on input elements
  class MouseSensor extends DndMouseSensor {
    static activators = [
      {
        eventName: 'onMouseDown' as const,
        handler: ({ nativeEvent:event }: { nativeEvent:MouseEvent }) => {
          const target = event.target as HTMLElement;
          if (!target) return false;
          if (target.tagName.toLowerCase() === 'input') return false;
          return true;
        },
      },
    ];
  }

  // To allow onClick events in Categories
  const sensors = useSensors(
    useSensor(
      MouseSensor,
      { activationConstraint: { distance: 10 } }
    )
  );

  const categoriesId = useMemo(() => categories.map((cat) => cat.id), [categories]);

  if (!isEditing) {
    return(
      <ol id="categorized-spaces">
        { categories.map((category, index) => (
          <CategoriesListItem
            key={category.id}
            category={category}
            spaces={positionedSpaces[category.id]}
            index={index}
          />
        ))}
      </ol>
    );
  }

  // isEditing
  return (
    <ol id="categorized-spaces">
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <SortableContext
          id="categories"
          items={categoriesId}
          strategy={verticalListSortingStrategy}
        >
          { categories.map((category, index) => (
            <CategoriesListItem
              key={category.id}
              category={category}
              spaces={positionedSpaces[category.id]}
              index={index}
            />
          ))}
          {createPortal(
            <DragOverlay
              // snapCenterToCursor to fix category dragging
              // https://github.com/clauderic/dnd-kit/issues/122
              modifiers={[snapCenterToCursor]}
            >
              {dragCategory && (
                <CategoriesListItem
                  category={dragCategory}
                  spaces={positionedSpaces[dragCategory.id]}
                  index={0}
                />
              )}
              {dragSpace && (
                <SpaceListItem
                  space={dragSpace}
                  categoryIndex={0}
                />
              )}
            </DragOverlay>,
            document.getElementById('visrez-interactive')!.shadowRoot?.getElementById('wrapper') || document.getElementById('wrapper') || document.body
          )}
        </SortableContext>
      </DndContext>
    </ol>
  );
}
