import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  hasMapResponse,
  MapResponseAction,
  RootState,
  LabelType,
  activeImageSelector,
  mapSelector,
} from '../../store';

export interface LabelsState {
  list: LabelType[];
}

export const initialState: LabelsState = { list: [] };

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    // TODO: not used, do we want to allow to remove a Label? (not a label position)
    remove: (state, { payload }) => {
      return {
        ...state,
        list: state.list.filter((label) => label.id !== payload),
      };
    },

    update: (state, { payload }) => {
      const label = state.list.find((l) => l.id === payload.id);
      if (label) {
        // Update existing label
        label.name = payload.name;
      } else {
        // Create new label
        state.list.push(payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is MapResponseAction => hasMapResponse(action),
      (state, { payload }) => {
        state.list = payload.labels;
      }
    );
  },
});

export const labelsSelector = createSelector(
  (state: RootState) => state.undoable.present.labels.list,
  mapSelector,
  (labels, iMap) => {
    return iMap.allowLabels ? labels : [];
  }
);

export const { update, remove } = labelsSlice.actions;

// Labels not placed in current image
export const notPlacedLabelsSelector = createSelector(labelsSelector, activeImageSelector, (labels, activeImage) => {
  if (!activeImage) return [];
  return labels.filter((l) => !activeImage.labels.map((lPos) => lPos.label).includes(l.id));
});
