import { createSlice } from '@reduxjs/toolkit';

import {
  RootState,
  fetchAPIData,
  fetchAPIVrData,
  saveAPI,
  ImageType,
} from '../../store';

import { interactiveURL } from '../../App';

// Create models slice, with models data from API
export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    id: 0,
    order_id: '',
    order_description: '',
    markers: {} as ImageType,
    defaultMarkers: {} as ImageType,
    markersSize: '',
    default_image: 0,
    name: '',
    featured_video: '',
    groupBy: 'categories',
    sidebarOpen: true,
    firstItemOpen: true,
    allowLabels: true,
    categories: [],
    spaces: [],
    labels: [],
    images: [],
    models: [],
    current_user: {
      id: null as number|null,
      can_edit: false,
    },
    isEmbedded: false,
    needsSave: false,
    customCss: '',
    primaryColor: '',
    secondaryColor: '',
    bgColor: '',
  },
  reducers: {
    setIsEmbedded: (state, { payload }) => {
      state.isEmbedded = payload;
    },
    setMapSettings: (state, { payload }) => {
      state.sidebarOpen = payload.sidebarOpen;
      state.firstItemOpen = payload.firstItemOpen;
      state.allowLabels = payload.allowLabels;
      state.groupBy = payload.groupBy;
      state.featured_video = payload.featured_video;
      state.markers = payload.markers;
      state.markersSize = payload.markersSize;
      state.needsSave = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAPIVrData.fulfilled, (state, { payload }) => {
      state.order_id = payload.vr.order_id;
      state.primaryColor = payload.vr.primaryColor;
      state.secondaryColor = payload.vr.secondaryColor;
      state.bgColor = payload.vr.bgColor;
      state.current_user = payload.current_user;
    });
    builder.addCase(fetchAPIData.fulfilled, (state, { payload }) => {
      state.id = payload.id;
      state.order_id = payload.order_id;
      state.order_description = payload.order_description;
      state.markers = payload.markers;
      state.defaultMarkers = payload.defaultMarkers;
      state.markersSize = payload.markersSize;
      state.default_image = payload.default_image;
      state.name = payload.name;
      state.featured_video = payload.featured_video || '';
      state.groupBy = payload.groupBy;
      state.sidebarOpen = payload.sidebarOpen;
      state.firstItemOpen = payload.firstItemOpen;
      state.allowLabels = payload.allowLabels;
      state.current_user = payload.current_user;
      state.customCss = payload.customCss;
      state.primaryColor = payload.primaryColor;
      state.secondaryColor = payload.secondaryColor;
      state.bgColor = payload.bgColor;
    });
    builder.addCase(saveAPI.fulfilled, (state, { payload }) => {
      state.needsSave = false;
      state.markers = payload.markers;
    });
  },
});

export const { setIsEmbedded, setMapSettings } = mapSlice.actions;

export const mapSelector = (state: RootState) => state.map;
export const loggedInSelector = (state: RootState) => !!state.map.current_user.id;
export const canEditSelector = (state: RootState) => !!state.map.current_user.can_edit;
export const isEmbeddedSelector = (state: RootState) => state.map.isEmbedded;
export const mapUrlSelector = (state: RootState) => `${interactiveURL}/${state.map.order_id}/map/${state.map.id}`;
