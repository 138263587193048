import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/pro-solid-svg-icons';
import {
  useAppSelector,
  mapSelector,
  labelsSelector,
} from '../../../store';

import './DisplayOptions.scss';

export interface OptionProps {
  showLabels: boolean;
  handleLabels: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function DisplayOptions({ showLabels, handleLabels }: OptionProps) {
  const iMap = useAppSelector(mapSelector);
  const labels = useAppSelector(labelsSelector);
  const labelsActive = iMap.allowLabels && labels.length > 0;
  if (labelsActive) {
    return (
      <div className={`map-display-option ${showLabels ? 'visible' : 'hidden'}`}>
        <label htmlFor="labels">
          <FontAwesomeIcon icon={faMapPin} />
          <span className="display-option-name">Locations</span>
        </label>
        <input
          className="options-checkbox"
          id="labels"
          type="checkbox"
          checked={showLabels}
          onChange={handleLabels}
        />
      </div>
    );
  }
}
