import { useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";

import Loader from './components/Loader';
import ErrorPage from './components/ErrorPage';
import Vr from './components/Vr';
import App from './App';

import {
  useAppDispatch,
  useAppSelector,
  apiLoadingStatusSelector,
  activeImageSelector,
  setActiveImage,
  mapNeedsSaveSelector,
  vrNeedsSaveSelector,
} from './store';

import cx from 'classnames';
import { UnsavedChangesPrompt } from './components/EditTools/UnsavedChangesPrompt';
import './AppLoader.scss';

export default function AppLoader() {

  const { isVr, layerId } = useLoaderData() as {
    isVr:boolean,
    layerId:string,
  };
  const dispatch = useAppDispatch();
  const apiResponse = useAppSelector(
    apiLoadingStatusSelector
  );
  // Allows to process API response and set up store before loading App
  const [apiResponseProcessed, setApiResponseProcessed] = useState(false);
  const mapNeedsSave = useAppSelector(mapNeedsSaveSelector);
  const vrNeedsSave = useAppSelector(vrNeedsSaveSelector);
  const activeImage = useAppSelector(activeImageSelector);
  // When it's false, Loader is not rendered
  const [showLoader, setShowLoader] = useState(true);
  // When it's true, shows Loader even if Map/Vr is loaded
  const [forceLoader, setForceLoader] = useState(true);
  // When it's true, Map/Vr is ready
  const [componentLoaded, setComponentLoaded] = useState(false);

  useEffect(() => {
    // Used to delay hidding Loader if Map/Vr loads (too) fast
    const timer = setTimeout(() => setForceLoader(false), 1600);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (apiResponse !== 'success') return;
    const layerIdNum = parseInt(layerId);
    if (activeImage && !isNaN(layerIdNum) && activeImage.id !== layerIdNum) {
      dispatch(setActiveImage(layerIdNum));
    }
    setApiResponseProcessed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse]);

  if (apiResponse === 'failed') {
    return <ErrorPage />;
  }

  return (
    <>
      {showLoader && (
        <div
          className={cx('loader', { 'fading': !forceLoader && componentLoaded })}
          onTransitionEnd={() => setShowLoader(false)}
        >
          <Loader />
        </div>
      )}
      {isVr && apiResponseProcessed && (
        <Vr
          componentLoaded={() => setComponentLoaded(true)}
        />
      )}
      {!isVr && apiResponseProcessed && (
        <App
          componentLoaded={() => setComponentLoaded(true)}
        />
      )}
      <UnsavedChangesPrompt hasUnsavedChanges={mapNeedsSave || vrNeedsSave} />

    </>
  );
}
