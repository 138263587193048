import {
  useAppSelector,
  useAppDispatch,
  toggleLayer,
  activeImageSelector,
  setActiveImage,
  LayerType,
  SpaceType,
} from '../../../store';

import cx from 'classnames';

import { SpaceList } from '../SpaceList';
import { Accordion } from '../../Accordion';
import './LayersListItem.scss';

interface LayersListItemProps {
  layer: LayerType;
  spaces: SpaceType[];
  index: number;
  open: boolean;
}

export function LayersListItem({ layer, spaces, index, open }: LayersListItemProps) {

  const activeImage = useAppSelector(activeImageSelector)!;
  const dispatch = useAppDispatch();

  return (
    <li
      className={ cx("layer", { 'active-layer': activeImage.id === layer.id }) }
    >
      <Accordion
        open={open}
        setOpen={() => {
          dispatch(toggleLayer(layer));
          dispatch(setActiveImage(layer.id));
        }}
        summary={
          <span className='layer-name'>{layer.name}</span>
        }
      >
        <SpaceList
          spaces={spaces}
          categoryId={layer.id.toString()}
          categoryIndex={index}
        />
      </Accordion>
    </li>
  );
}
