import { faBullseye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control } from "leaflet";
import { useState } from "react";
import { createPortal } from "react-dom";
import { ZoomControl as RLZoomControl, useMap } from "react-leaflet";

export function ZoomControl(props: React.ComponentProps<typeof RLZoomControl>) {
  // Use state because we want the portal to get re-rendered when it changes
  const [ref, setRef] = useState<Control.Zoom | null>(null);
  const map = useMap();
  return (
    <>
      <RLZoomControl
        ref={(r) => setRef(r)}
        {...props}
      />
      {ref && ref.getContainer() && createPortal(
        <a
          href="#0"
          title="Reset zoom"
          onClick={(e) => {
            if (map.options.center && map.options.zoom) {
              // External layers
              map.setView(map.options.center, map.options.zoom);
            } else if (map.options.maxBounds) {
              // Our regular layers
              map.fitBounds(map.options.maxBounds);
            }
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon={faBullseye} />
        </a>,
        ref.getContainer()!,
      )}
    </>
  );
}
