import { useContext, useEffect, useRef, useState } from "react";
import { LeafletContext } from "../../../App";
import {
  activeImageSelector,
  editedSpacePositionSelector,
  setEditedSpacePosition,
  useAppDispatch,
  useAppSelector,
  latLngFromDisplayValue,
} from "../../../store";

export function CoordinatesField() {
  const { mapRef } = useContext(LeafletContext);
  const dispatch = useAppDispatch();

  const activeImage = useAppSelector(activeImageSelector);
  const editedSpacePosition = useAppSelector(editedSpacePositionSelector);

  const coordinatesInputRef = useRef<HTMLInputElement>(null);

  const [displayCoordinatesValue, setDisplayCoordinatesValue] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!displayCoordinatesValue) {
      coordinatesInputRef.current?.setCustomValidity('');
      return;
    }

    const [newLat, newLng] = latLngFromDisplayValue(displayCoordinatesValue);

    if (newLat && newLng && newLat.toString() === editedSpacePosition?.lat.toFixed(6) && newLng.toString() === editedSpacePosition?.lng.toFixed(6)) {
      return;
    }

    if (newLat === undefined || newLng === undefined
      || Number.isNaN(newLat) || Number.isNaN(newLng)
      || newLat < -90 || newLat > 90
      || newLng < -180 || newLng > 180
    ) {
      coordinatesInputRef.current?.setCustomValidity('Invalid coordinates, expected "latitude, longitude" (without the quotes)');
    } else {
      coordinatesInputRef.current?.setCustomValidity('');
      dispatch(setEditedSpacePosition({ layerId: editedSpacePosition?.layerId, lat: newLat, lng: newLng }));
      const centerPx = mapRef.current?.project(mapRef.current?.getCenter(), mapRef.current?.getZoom())!;
      const newPx = mapRef.current?.project([newLat, newLng], mapRef.current?.getZoom())!;
      if (Math.abs(centerPx.x - newPx.x) > 30 || Math.abs(centerPx.y - newPx.y) > 30) {
        mapRef.current?.stop();
        mapRef.current?.panTo([newLat, newLng]);
      }
    }
  }, [displayCoordinatesValue, dispatch, mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFocused && editedSpacePosition && editedSpacePosition.lat && editedSpacePosition.lng) {
      setDisplayCoordinatesValue(`${editedSpacePosition.lat.toFixed(6)}, ${editedSpacePosition.lng.toFixed(6)}`);
    }
  }, [editedSpacePosition]); // eslint-disable-line react-hooks/exhaustive-deps

  return activeImage?.type === 'External' && editedSpacePosition?.lat !== undefined && editedSpacePosition?.lng !== undefined && (
    <label id="edit-space-coordinates">
      <span>
        Coordinates
      </span>
      <input
        ref={coordinatesInputRef}
        name='coordinates'
        value={displayCoordinatesValue ?? ''}
        onChange={(e) => setDisplayCoordinatesValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </label>
  );
}
