import { useState } from 'react';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import './Name.scss';

export function Name({ name }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      id="vr-name"
      onClick={() => setIsOpen(!isOpen)}
      className={cx({ open: isOpen })}
    >
      <p>{isOpen ? name : ''}</p>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
