import {
  useAppSelector,
  linkedLayersSelector,
  allPlacedSpacesSelector,
  ImageLayerType,
} from '../../../store';

import { LayersListItem } from './LayersListItem';

import '../CategoriesList/CategoriesList.scss';

export function LayersList() {
  const allPlacedSpaces = useAppSelector(allPlacedSpacesSelector);
  const linkedLayers = useAppSelector(linkedLayersSelector);

  const groupedSpaces = () => {
    return linkedLayers.map((layer, index) => (
      // Group spaces by Layer
      <LayersListItem
        key={layer.id}
        layer={layer as ImageLayerType}
        spaces={allPlacedSpaces[layer.id]}
        index={index}
        open={layer.opened}
      />
    ));
  }

  return (
    <ol id="categorized-spaces">
      { groupedSpaces() }
    </ol>
  );
}
