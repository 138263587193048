import { useCallback, useRef, useContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
  MapContainer,
  AttributionControl,
  useMapEvents,
} from 'react-leaflet';
import L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationPin,
  faBookmark,
} from '@fortawesome/pro-regular-svg-icons';

import 'leaflet/dist/leaflet.css';

import {
  useAppDispatch,
  useAppSelector,
  activeImageSelector,
  mapSelector,
  isEditingSelector,
  spacesSelector,
  spacePositionsSelector,
  labelsSelector,
  labelPositionsSelector,
  addLabel,
  InteractiveMapType,
  LayerType,
  SpaceType,
  LabelType,
  setEditedSpaceId,
  setEditedSpacePosition,
} from '../../store';

import { LeafletContext } from '../../App';

import { Labels } from './Labels';
import { LayersControl } from './LayersControl';
import { Spaces } from './Spaces';
import { DisplayOptions } from './DisplayOptions';

import { SidebarButton } from '../Sidebar';
import { Maximize } from './Maximize';
import { ZoomControl } from './ZoomControl';

import { EditMarker } from '../EditTools/EditMarker';
import { PieMenu, PieMenuItem } from '../EditTools/PieMenu';

import './Map.scss';

// see https://github.com/Leaflet/Leaflet/issues/4968
// FIX leaflet's default icon path problems with webpack
// @ts-ignore TS2339
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const minZoom = 17;
export const maxZoom = 20;

const EventHandler = ({
  dispatch,
  iMap,
  isEditing,
  activeImage,
  editedMarker,
  saveEditForm,
  closeEditForm,
  showPieMenuAt,
  setShowPieMenuAt,
}: {
  dispatch: Function;
  iMap: InteractiveMapType;
  isEditing: boolean;
  activeImage: LayerType;
  editedMarker: SpaceType | LabelType | null;
  saveEditForm: Function;
  closeEditForm: Function;
  showPieMenuAt: L.LatLngExpression | null;
  setShowPieMenuAt: Function;
}) => {
  const [clickState, setClickState] = useState<{ originalPosition: { x: number, y: number } | null }>({ originalPosition: null });
  useMapEvents({
    mousedown: (e) => {
      setClickState({ originalPosition: { x: e.originalEvent.clientX, y: e.originalEvent.clientY } });
    },
    click: (e) => {
      if (!clickState.originalPosition || clickState.originalPosition.x !== e.originalEvent.clientX || clickState.originalPosition.y !== e.originalEvent.clientY) {
        setClickState({ originalPosition: null });
      } else {
        if (!isEditing) return;
        if (editedMarker) {
          saveEditForm();
          closeEditForm();
        } else {
          if (showPieMenuAt) {
            setShowPieMenuAt(null);
          } else {
            if (iMap.allowLabels) {
              setShowPieMenuAt(e.latlng);
            } else {
              dispatch(setEditedSpaceId('newSpace'));
              const { lat, lng } = e.latlng;
              dispatch(setEditedSpacePosition({ lat, lng, layerId: activeImage?.id }));
            }
          }
        }
      }
    },
  });

  return null;
};

interface MapProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  editTool: string;
  componentLoaded: ()=>void;

}

const Map = ({ isModalVisible, setIsModalVisible, isSidebarOpen, setIsSidebarOpen, editTool, componentLoaded }: MapProps) => {
  const dispatch = useAppDispatch();
  const { mapRef } = useContext(LeafletContext);

  const [showLabels, setShowLabels] = useState(true);
  const handleLabels = () => {
    setShowLabels(!showLabels);
  };

  const activeImage = useAppSelector(activeImageSelector);
  const iMap = useAppSelector(mapSelector);
  const isEditing = useAppSelector(isEditingSelector);
  const [showPieMenuAt, setShowPieMenuAt] = useState(null);

  const spaces = useAppSelector(spacesSelector);
  const spacePositions = useAppSelector(spacePositionsSelector);
  const labels = useAppSelector(labelsSelector);
  const labelPositions = useAppSelector(labelPositionsSelector);
  const [editedMarker, setEditedMarker] = useState<{ type: string; id: string } | null>(null);
  const [editedSpace, setEditedSpace] = useState<SpaceType | null>(null);
  const [editedLabel, setEditedLabel] = useState<LabelType | null>(null);

  useEffect(() => {
    if (editedMarker?.type === 'space') {
      setEditedLabel(null);
      setEditedSpace(spaces.find((s) => s.id === editedMarker.id) || ({ id: editedMarker.id } as SpaceType));
    } else if (editedMarker?.type === 'label') {
      setEditedSpace(null);
      setEditedLabel(labels.find((s) => s.id === editedMarker.id) || ({ id: editedMarker.id } as LabelType));
    } else {
      setEditedSpace(null);
      setEditedLabel(null);
    }
  }, [spaces, labels, editedMarker]);

  const editMenuDoSaveRef = useRef<{ callback: (() => void) | null }>({ callback: null });
  const editMenuSave = useCallback(() => {
    if (editMenuDoSaveRef.current.callback) {
      editMenuDoSaveRef.current.callback();
    }
    setEditedMarker(null);
  }, []);

  return (
    <main className="map">
      <DisplayOptions
        handleLabels={handleLabels}
        showLabels={showLabels}
      />
      <SidebarButton
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <MapContainer
        ref={mapRef}
        center={[0, 0]}
        maxZoom={maxZoom}
        minZoom={minZoom}
        zoomSnap={0.25} // makes map.fitBounds() more accurate
        zoomControl={false}
        attributionControl={false}
        whenReady={() => componentLoaded()}
      >
        <Maximize />
        <AttributionControl
          prefix={false}
          position="bottomright"
        />
        <ZoomControl position="bottomright" />
        <EventHandler
          dispatch={dispatch}
          iMap={iMap}
          isEditing={isEditing}
          activeImage={activeImage!}
          editedMarker={editedSpace || editedLabel}
          closeEditForm={() => setEditedMarker(null)}
          saveEditForm={editMenuSave}
          showPieMenuAt={showPieMenuAt}
          setShowPieMenuAt={setShowPieMenuAt}
        />
        {isEditing && editedSpace && spacePositions[editedSpace.id] && (
          <EditMarker
            position={{
              lat: spacePositions[editedSpace.id].x,
              lng: spacePositions[editedSpace.id].y,
            }}
            onSuccess={editMenuSave}
            onClose={() => setEditedMarker(null)}
            marker={{ type: 'space', value: editedSpace }}
            doSaveRef={editMenuDoSaveRef}
          />
        )}
        {isEditing && editedLabel && labelPositions[editedLabel.id] && (
          <EditMarker
            position={{
              lat: labelPositions[editedLabel.id].x,
              lng: labelPositions[editedLabel.id].y,
            }}
            onSuccess={editMenuSave}
            onClose={() => setEditedMarker(null)}
            marker={{ type: 'label', value: editedLabel }}
            doSaveRef={editMenuDoSaveRef}
          />
        )}
        {isEditing && showPieMenuAt && (
          <PieMenu
            position={showPieMenuAt}
            menuRadius={100}
            innerRadius={0.2}
            outterRadius={1}
            hoverGrowthFactor={1.2}
            gap={0.0275}
            labelScale={1.2}
            leaveRadiusFactor={1.5}
            onClick={(id) => {
              const { lat, lng } = showPieMenuAt;
              const uuid = uuidv4();
              switch (id) {
                case 'space':
                  dispatch(setEditedSpaceId('newSpace'));
                  dispatch(setEditedSpacePosition({ lat, lng, layerId: activeImage?.id }));
                  break;
                case 'label':
                  dispatch(addLabel({ label: uuid, lat, lng }));
                  setEditedMarker({ type: 'label', id: uuid });
                  break;
                  default:
                    console.error('Unkown menu item', id);
                }
              }}
              onLeave={ () => setShowPieMenuAt(null) }
            >
              <PieMenuItem
                id="space"
              >
                <FontAwesomeIcon icon={faLocationPin} />
                &nbsp;
                <span>Space</span>
              </PieMenuItem>
              <PieMenuItem
                id="label"
              >
                <FontAwesomeIcon icon={faBookmark} />
                &nbsp;
                <span>Label</span>
              </PieMenuItem>
            </PieMenu>
        )}
        <LayersControl />
        <Spaces
          openModal={() => setIsModalVisible(true)}
        />
        {showLabels && (
          <Labels
            setEditedMarker={setEditedMarker}
            editedMarker={editedMarker}
          />
        )}
      </MapContainer>
    </main>
  );
};

export { Map };
