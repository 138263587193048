import { useRef, useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';

import {
  useAppDispatch,
  renameLayer,
  useAppSelector,
  isEditingSelector,
  saveCurrentView,
  type LayerType,
} from '../../../store';
import { Layer } from './Layer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faCheck,
  faTrash,
  faFloppyDisk,
} from '@fortawesome/pro-regular-svg-icons';

import './EditLayer.scss';

type EditLayerProps = {
  image: LayerType;
  isActive: boolean;
  setActive: () => void;
  disable: () => void;
  ghost?: boolean;
};

export function EditLayer(props: EditLayerProps) {
  const dispatch = useAppDispatch();
  const { image, isActive, setActive, disable, ghost } = props;

  const isEditing = useAppSelector(isEditingSelector);
  const map = useMap();
  const [currentView, setCurrentView] = useState<string|null>(null);

  useEffect(() => {
    if (!isActive || !isEditing || image.type !== 'External') return;

    const onMapMove = () => {
      const center = map.getCenter();
      setCurrentView(`${center.lat.toFixed(4)},${center.lng.toFixed(4)},${map.getZoom()}`);
    };

    map.on('moveend', onMapMove);
    return () => {
      map.off('moveend', onMapMove);
      setCurrentView(null);
    }
  }, [map, image, isActive, isEditing]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: image.id, disabled: !isEditing });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [renaming, setRenaming] = useState(false);
  const renameInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <span
      className={ cx({ 'layer-edit': !ghost && isEditing, 'hidden': image.mainLayer && !isEditing }) }
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={image.id}
    >
      <Layer
        image={image}
        isActive={isActive}
        setActive={setActive}
      >
        <div className="layer-edit-controls">
          {isEditing && (
            <>
              {renaming && (
                <input
                  ref={renameInputRef}
                  className='rename-layer'
                  defaultValue={image.name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      setRenaming(false);
                      dispatch(renameLayer({ id: props.image.id, name: renameInputRef.current!.value }));
                    }
                  }}
                  onClick={(e) => e.stopPropagation() }
                />
              )}
              {!ghost && (
                <>
                  { currentView && image.savedView !== currentView && (
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      title="Save current map position"
                      onClickCapture={(e) => {
                        dispatch(saveCurrentView(currentView));
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClickCapture={(e) => {
                      disable();
                      e.stopPropagation();
                    }}
                  />
                  <div className="layer-drag-icon" />
                  <FontAwesomeIcon
                    icon={renaming ? faCheck : faPencil}
                    onClickCapture={() => {
                      if (renaming) {
                        setRenaming(false);
                        dispatch(renameLayer({ id: props.image.id, name: renameInputRef.current!.value }));
                      } else {
                        setRenaming(true);
                      }
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Layer>
    </span>
  );
}

