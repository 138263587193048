import { useContext, useEffect, useRef } from 'react';
import { useMap, MapContainer, TileLayer } from 'react-leaflet';
import L, {CRS} from 'leaflet';
import cx from 'classnames';

import {
  externalView,
  ExternalLayerType,
} from '../../../store';

import { LeafletContext } from '../../../App';

import './Layer.scss';

type LayerProps = {
  image: ExternalLayerType;
  isActive: boolean;
  setActive: () => void;
  children?: JSX.Element,
};

export function ExternalLayer(props: LayerProps) {
  const map = useMap();
  const thumbMapRef = useRef<L.Map|null>(null);
  const { image, isActive, setActive, children } = props;

  const { activeLayerRef } = useContext(LeafletContext);

  useEffect(() => {
    // When the layer changes, remove the options that were specific to an
    // external layer, so that resetting the zoom in the zoom controls uses the
    // `maxBounds` for that layer, instead of the `center` and `zoom` for the
    // previous active external layer
    delete map.options.center;
    delete map.options.zoom;

    if (!map || !isActive) return;
    // do not replace layer if it's the same
    if (activeLayerRef.current.id === image.id) return;

    // If Map has another Layer loaded
    if (activeLayerRef.current.object) {
      // remove it from Map
      map.removeLayer(activeLayerRef.current.object);
      activeLayerRef.current.object = undefined;
      activeLayerRef.current.id = null;
    }

    // Add layer for active image to Map
    map.options.crs = CRS.EPSG3857;
    map.setMaxZoom(19);
    map.setMinZoom(3);
    const [center, zoom] = externalView(image.savedView) as [L.LatLng, number];
    map.options.center = center;
    map.options.zoom = zoom;
    map.setZoom(zoom);
    activeLayerRef.current.object = L.tileLayer(
      image.tiles,
      {
        minZoom: 3,
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      },
    );
    // Full World bounds
    activeLayerRef.current.id = image.id;
    map.addLayer(activeLayerRef.current.object!);
    map.setMaxBounds([[-90,-180], [90,180]]);
    setTimeout(() => {
      map.setView(center, zoom);
    }, 100);
  }, [map, image, activeLayerRef, isActive]);

  const setupThumbnailMap = (thumbMap:L.Map) => {
    if (!thumbMap) return;
    if (thumbMapRef.current) return; // already setup

    // disable Events
    thumbMap.dragging.disable();
    thumbMap.touchZoom.disable();
    thumbMap.doubleClickZoom.disable();
    thumbMap.scrollWheelZoom.disable();
    thumbMap.boxZoom.disable();
    thumbMap.keyboard.disable();
    if (thumbMap.tap) thumbMap.tap.disable();

    // initial map position
    const [center, zoom] = externalView(image.savedView) as [L.LatLng, number];
    thumbMap.setView(center, zoom - 4);

    // update ref
    thumbMapRef.current = thumbMap;
  }

  return (
    <label
      className="external"
      onClick={() => {
        if (!isActive) {
          setActive();
        } else {
          // Used when clicking on already active layer
          // Center map on its original position
          const [center, zoom] = externalView(image.savedView) as [L.LatLng, number];
          map.setView(center, zoom);
        }
      }}
    >
      <figure className={cx({ checked: isActive })}>
        <MapContainer
          ref={setupThumbnailMap}
          center={[0,0]}
          zoom={0}
          zoomControl={false}
          crs={CRS.EPSG3857}
          attributionControl={false}
        >
          <TileLayer
            url={image.tiles}
          />
        </MapContainer>
        <figcaption>{image.name}</figcaption>
        {children}
      </figure>
    </label>
  );
}

