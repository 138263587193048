import {
  useEffect,
  useState,
} from "react";

import {
  FileType,
} from '../../../store';

import { humanFileSize } from '../../../utils';

import './FileUploader.scss';

const defaultMaxSize = 10e6; // 10 MB
const defaultAcceptedTypes = ['application/pdf'];

export function FileUploader({
  onFilesUpload,
  label,
  multiple,
  maxSize,
  acceptedTypes,
  children,
} : {
  onFilesUpload:any,
  label:string,
  multiple:boolean,
  maxSize?:number,
  acceptedTypes?:string[],
  children: React.ReactNode, // File list / preview
}) {

  // Files choosen for upload, type & size validated
  const [validFiles, setValidFiles] = useState<File[]>([]);

  // Error message to show
  const [error, setError] = useState('');

  // Accepted file types
  const types = acceptedTypes ? acceptedTypes : defaultAcceptedTypes;
  // Max file size
  const size = maxSize ? maxSize : defaultMaxSize;

  // When user choose the files to upload, validate its content type
  const changeHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;
    const uploadedFiles = [...e.target.files];

    // Validate all files type
    if (uploadedFiles.some((f) => !types.includes(f.type))) {
      setError("Some files are not of valid type.");
      return;
    }

    // Validate all files size
    if (uploadedFiles.some((f) => f.size > size)) {
      const filesStr = multiple ? 'Some files are' : 'File is';
      setError(`${filesStr} grater than ${humanFileSize(size)}.`);
      return;
    }

    setError("");
    setValidFiles(uploadedFiles);
  };

  // Use FileReader to preview files
  useEffect(() => {
    const newFiles:FileType[] = [];
    const fileReaders:FileReader[] = [];
    let isCancel = false;
    if (validFiles.length) {
      validFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target!;
          if (result) {
            newFiles.push({
              url: '',
              thumb: '',
              base64: result as string,
              filename: file.name,
            })
          }
          if (newFiles.length === validFiles.length && !isCancel) {
            onFilesUpload(newFiles);
            setValidFiles([]);
          }
        }
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      });
    }
  }, [onFilesUpload, validFiles]);

  return (
    <div className="file-uploader">
      { label && <label>{label}</label> }
      { children }
      <input
        type="file"
        id="file"
        onChange={changeHandler}
        accept={types.join(', ')}
        multiple={multiple}
        style={{ cursor: 'pointer' }}
      />
      <p className='error'>
        { error }
      </p>
    </div>
  );
}
