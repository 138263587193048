import { useState } from 'react';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faMaximize, faMinimize, faShareNodes, faGear } from '@fortawesome/pro-solid-svg-icons';
import { faCube } from '@fortawesome/pro-regular-svg-icons';

import { useAppSelector, isEmbeddedSelector } from '../../../store';
import { isFullscreenAvailable } from '../../../utils';
import { LayoutButton } from './LayoutButton';
import { LightsMenu } from './LightsMenu';
import { SizesMenu } from './SizesMenu';
import { AdminMenu } from './AdminMenu';

import './VrMenu.scss';

export function VrMenu({
  isOpen,
  setIsOpen,
  layouts,
  activeLayout,
  onSelectLayout,
  lights,
  activeLight,
  onSelectLight,
  sizes,
  activeSize,
  onSelectSize,
  adminMode,
  toggleAdminMode,
  modelID,
  views,
  activeView,
  setActiveView,
  defaultView,
  setDefaultView,
  links,
  setHoveredLinkID,
  onDragLink,
  isFullscreen,
  onToggleFullscreen,
  showAdmin,
  setShareIsOpen,
}) {
  const isEmbedded = useAppSelector(isEmbeddedSelector)
    || window.location !== window.parent.location;

  const hasLayouts = layouts.length > 1;
  const hasLights = lights.length > 1;
  const hasSizes = sizes.length > 1;
  const hasEmptyLayout = layouts.includes('Empty');

  // VrMenu is open or collapsed
  const [adminIsOpen, setAdminIsOpen] = useState(adminMode);

  const toggleAdminMenu = () => {
    if (!isOpen && adminIsOpen) {
      // Menu minimized but admin open, expand menu
      setIsOpen(true);
    } else {
      if (!hasLayouts || !isOpen) {
        setIsOpen(!adminIsOpen);
      }
      setTimeout(
        () => {
          setAdminIsOpen(!adminIsOpen);
          toggleAdminMode();
        },
        adminIsOpen && !hasLayouts ? '1000' : '0',
      );
    }
  };

  // Sort Layouts, Empty first
  const sortedLayouts = [...layouts].sort((a, b) => {
    if (a === 'Empty') return -1;
    if (b === 'Empty') return 1;
    return a.localeCompare(b);
  });

  return (
    <div
      id="vr-menu"
      className={cx({ open: isOpen })}
    >
      <div id="vr-menu-top-bar">
        <div id="left-side-buttons">
          {(hasLayouts || adminIsOpen) && (
            <div
              id="toggle-vr-menu"
              className="vr-menu-button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <FontAwesomeIcon icon={faAngleDown} className="fa-xl" />
            </div>
          )}
        </div>

        <div id="right-side-buttons">
          {hasSizes && (
            <SizesMenu
              sizes={sizes}
              activeSize={activeSize}
              onSelectSize={onSelectSize}
            />
          )}
          {hasLayouts && hasEmptyLayout && (
            <div
              className="vr-menu-button"
              data-tooltip="top"
              onClick={() => onSelectLayout("Empty")}
            >
              <FontAwesomeIcon
                icon={faCube}
                className="fa-xl"
              />
              <span role="tooltip">
                Empty
              </span>
            </div>
          )}
          {hasLights && (
            <LightsMenu
              lights={lights}
              activeLight={activeLight}
              onSelectLight={onSelectLight}
            />
          )}
          <div
            className="vr-menu-button"
            onClick={() => setShareIsOpen(true)}
            data-tooltip="top"
          >
            <FontAwesomeIcon icon={faShareNodes} className='fa-xl' />
            <span role="tooltip">Share</span>
          </div>
          { (isFullscreenAvailable() || isEmbedded) && (
            <div
              className="vr-menu-button"
              onClick={onToggleFullscreen}
              data-tooltip="top"
              style={showAdmin ? {} : { '--tooltip-offset': '-2em' }}
            >
              <FontAwesomeIcon
                icon={isFullscreen ? faMinimize : faMaximize}
                className='fa-xl'
              />
              <span role="tooltip">Fullscreen</span>
            </div>
          )}
          {showAdmin && (
            <div
              className="vr-menu-button"
              onClick={toggleAdminMenu}
              data-tooltip="top"
              style={{ '--tooltip-offset': '-1em' }}
            >
              <FontAwesomeIcon
                icon={faGear}
                className={cx('fa-xl', { open: adminIsOpen })}
              />
              <span role="tooltip">Settings</span>
            </div>
          )}
        </div>
      </div>

      <div id="vr-menu-main">
        {adminIsOpen && (
          <AdminMenu
            layouts={sortedLayouts}
            activeLayout={activeLayout}
            onSelectLayout={onSelectLayout}
            views={views}
            activeView={activeView}
            setActiveView={setActiveView}
            defaultView={defaultView}
            setDefaultView={setDefaultView}
            links={links}
            setHoveredLinkID={(linkID) => setHoveredLinkID(linkID)}
            onDragLink={(viewId, name) => onDragLink(viewId, name)}
            modelID={modelID}
          />
        )}
        {!adminIsOpen &&
          hasLayouts &&
          sortedLayouts.map((name) => {
            return (
              <LayoutButton
                key={name}
                name={name}
                active={name === activeLayout}
                onSelectLayout={onSelectLayout}
              />
            );
          })}
      </div>
    </div>
  );
}
