import { useEffect } from 'react';

export function UnsavedChangesPrompt({ hasUnsavedChanges }: { hasUnsavedChanges:boolean }) {

  useEffect(() => {
    const onBeforeUnload = (e:Event) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return null;
};
