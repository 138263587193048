import {
  useState,
  useEffect,
} from 'react';

import {
  useAppSelector,
  mapSelector,
  ImageType,
} from '../../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';

import { ImageUploader } from '../../EditTools/EditMarker/ImageUploader';

export function EditMarkersIcon({
  markers,
  markersSize,
  setMarkers,
  setMarkersSize,
} : {
  markers:ImageType;
  markersSize:string;
  setMarkers:(markers:ImageType)=>void;
  setMarkersSize:(size:string)=>void;
}) {

  const iMap = useAppSelector(mapSelector);
  const [markersWidth, setMarkersWidth] = useState(Number(markersSize.split('x')[0]));
  const [markersHeight, setMarkersHeight] = useState(Number(markersSize.split('x')[1]));
  const [aspectRatio, setAspectRatio] = useState(markersWidth / markersHeight);

  useEffect(() => {
    const w = Number(markersSize.split('x')[0]);
    const h = Number(markersSize.split('x')[1]);
    setMarkersWidth(w);
    setMarkersHeight(h);
    setAspectRatio(w / h)
  }, [markersSize]);

  return (
    <>
      <hr />
      <li className="markers">
        <label>
          <span>Markers</span>
          <ImageUploader
            images={[markers]}
            setImages={(images: ImageType[]) => {
              const image = new Image();
              image.onload = (i: any) => {
                if (!i.target) return;
                // Calculate new image aspect ratio
                const newAspectRatio = Number(i.target.width) / Number(i.target.height);
                if (newAspectRatio) {
                  // Keep height but change width for aspect ratio
                  const w = Math.round(markersHeight! * newAspectRatio);
                  setMarkersSize(`${w}x${markersHeight}`);
                } else {
                  // This doesn't work for svg, but setting height seems enough
                  // and it keeps the aspect ratio
                  setMarkersSize(`${markersHeight}x${markersHeight}`);
                }
              }
              if (images[0] && images[0].base64) {
                // New uploaded image
                image.src = images[0].base64;
                setMarkers(images[0]);
              } else {
                // Use the default image
                image.src = iMap.defaultMarkers.url;
                setMarkers(iMap.defaultMarkers);
              }
            }}
            label=""
            multiple={false}
            removable={markers.url !== iMap.defaultMarkers.url}
            imagePreviewStyles={{
              height: markersHeight,
              width: markersWidth,
            }}
            maxSize={500_000} // 500 KB
            acceptedTypes={[
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/webp',
              'image/svg+xml',
            ]}
          />
        </label>
        <label>
          <span className="marker-size">
            <small>
              Marker Size
            </small>
            {markersHeight !== 57 && (
              <FontAwesomeIcon
                icon={faRotateLeft}
                onClick={() => {
                  const w = Math.round(57 * aspectRatio);
                  setMarkersSize(`${w}x57`);
                }}
              />
            )}
          </span>
          <input
            type="range"
            name="markersHeight"
            value={markersHeight}
            onChange={(e) => {
              const h = Number(e.target.value);
              const w = Math.round(h * aspectRatio);
              setMarkersSize(`${w}x${h}`);
            }}
            min="40"
            max="100"
          />
        </label>
      </li>
    </>
  );
}
