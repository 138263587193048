import {
  LayerType,
  ExternalLayerType,
  ImageLayerType,
} from '../../../store';

import { ExternalLayer } from './ExternalLayer';
import { ImageLayer } from './ImageLayer';
import './Layer.scss';

type LayerProps = {
  image: LayerType;
  isActive: boolean;
  setActive: () => void;
  children?: JSX.Element,
};

export function Layer({image, isActive, setActive, children}: LayerProps) {

  if (image.type === 'External') {
    return (
      <ExternalLayer
        image={image as ExternalLayerType}
        isActive={isActive}
        setActive={setActive}
        children={children}
      />
    );
  } else {
    return (
      <ImageLayer
        image={image as ImageLayerType}
        isActive={isActive}
        setActive={setActive}
        children={children}
      />
    );
  }
}
